import React, { Component } from "react";
import SubscriptionPlan from "components/SubscriptionPlan";
import userAuth from "utils/userAuth";

class SubscriptionPlanPage extends Component {
  componentWillMount() {
    userAuth.clearAuth();
  }

  render() {
    const { code } = this.props.match.params
    return (
      <div className="SubscriptionPlanPage">
        <SubscriptionPlan code={code} />
      </div>
    )
  }
}

export default SubscriptionPlanPage;
