import React from 'react';
import './CollapseBox.scss';

class CollapseBox extends React.Component {
  state = {
    show: this.props.show
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.show != undefined) {
      this.setState({ show: nextProps.show });
    }
  }

  renderDisplayComponent() {
    const { show } = this.state;
    const {
      expandIcon = 'fa fa-minus',
      collapseIcon = 'fa fa-plus',
      title,
      expandText = 'Hide',
      collapseText = 'Show',
      activeBackgroundColor = '#15254c',
      inactiveBackgroundColor = '#a6a8ac',
      colorText,
      id,
      hasContent = true
    } = this.props;
    return (
      <div
        className="display-button"
        id={id}
        style={
          this.props.active === false
            ? { backgroundColor: inactiveBackgroundColor }
            : { backgroundColor: activeBackgroundColor }
        }>
        {hasContent && (
          <div
            className="toggle-icon"
            style={colorText ? { color: colorText } : {}}>
            <i className={show ? expandIcon : collapseIcon} />
          </div>
        )}

        <div
          className="toggle-text"
          style={colorText ? { color: colorText } : {}}>
          {title || (show ? expandText : collapseText)}
        </div>
      </div>
    );
  }

  render() {
    const {
      children,
      onToggle = () => {},
      level,
      style,
      hasContent = true
    } = this.props;
    const { show } = this.state;
    return (
      <div className={level ? `level-${level}` : ''}>
        <div className="CollapseBox" style={style}>
          <div
            className="display"
            onClick={event => {
              if (hasContent) {
                this.setState(
                  {
                    show: !this.state.show
                  },
                  () => onToggle(this.state.show)
                );
              }
            }}>
            {this.renderDisplayComponent()}
          </div>
          <div className={`box-container${show ? ' show' : ' hide'}`}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default CollapseBox;
