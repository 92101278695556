import React, { Component } from "react";
import "./CheckBox.scss";

class CheckBox extends Component {
  static defaultProps = {
    label: "checkbox",
    onChange: value => {
      return value;
    },
    radio: false,
    disabled: false
  };

  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.checked != nextProps.checked) {
      this.setState({
        checked: nextProps.checked
      });
    }
  }

  handleCheckBoxClick(event) {
    event.preventDefault();
    this.toggleCheckbox();
  }

  toggleCheckbox() {
    if (this.props.disabled) return;
    if (this.props.radio) {
      if (!this.state.checked) {
        this.setState(
          {
            checked: true
          },
          () => this.props.onChange(this.state.checed)
        );
      }
    } else {
      this.setState(
        {
          checked: !this.state.checked
        },
        () => this.props.onChange(this.state.checked)
      );
    }
  }

  getCheckedClass() {
    return this.state.checked ? "checked" : "";
  }

  randomInputId() {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    );
  }

  render() {
    const {className, label, tabIndex, disabled } = this.props;
    const inputId = this.randomInputId();
    return (
      <div
        className={["CheckBox", className, disabled ? " disabled" : null]
          .filter(e => !!e)
          .join(" ")}
        tabIndex={tabIndex}
        onKeyDown={event => {
          switch (event.keyCode) {
            case 32:
              this.toggleCheckbox();
              break;
          }
        }}
      >
        <label
          htmlFor={inputId}
          className={`checkbox-box ${this.getCheckedClass()}`}
          onClick={event => this.handleCheckBoxClick(event)}
        >
        <input value={this.state.checked} type='checkbox' id={inputId} style={{display: 'none'}} />
      </label>
        <label htmlFor={inputId} className="checkbox-label">{label}</label>
      </div>
    );
  }
}

export default CheckBox;
