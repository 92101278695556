import React, { useState } from "react"
import { withApollo } from "react-apollo"
import gql from "graphql-tag"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { toast } from "react-toastify"

export const CREATE_FOLDER_GQL = gql`
  mutation ($input: CreateFolderInput!) {
    createFolder(input: $input) {
      success
      errors
    }
  }
`

export function NewFolderButton(props) {
   const { folders = [], propertyId, containerType, photoType, assetId, onCreated = () => {} } = props

  function handleClick() {
    withReactContent(Swal).fire({
      title: "New Folder",
      input: "text",
      inputValue: "",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      confirmButtonText: "Create",
      preConfirm: () => {
        handleAddFolder((Swal.getInput()?.value || "").trim())
      },
    })
  }

  async function handleAddFolder(name) {
    // validate name
    const folderNames = folders.map((f) => String(f.title).toLowerCase())
    if (folderNames.includes(name.toLowerCase())) {
      return toast.error("Folder name already exists")
    }
    props.client.mutate({
      mutation: CREATE_FOLDER_GQL,
      variables: {
        input: {
          property_id: propertyId,
          container_type: containerType,
          asset_id: assetId,
          photo_type: photoType,
          title: name,
        },
      },
      update: (store, { data: { createFolder } }) => {
        if (createFolder.success) {
          // handle success
          onCreated()
        } else {
          // handle error
          toast.error(createFolder.errors["base"])
        }
      },
    })

    // upload
    // refresh
  }

  return (
    <div className="tab new-folder-button" onClick={handleClick}>
      <i className="fa fa-plus" />
      <div className="text">New Folder</div>
    </div>
  )
}

export default withApollo(NewFolderButton)