import React from 'react';
import Button from 'components/commons/Button';
import InviteUserBox from './InviteUserBox';
import userAuth from 'utils/userAuth';
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import SweetAlert from 'sweetalert2-react';
import { Mutation, Query, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import PermissionOption from './PermissionOption';
import { toast } from 'react-toastify';
import { GET_CURRENT_SUBSCRIPTION_GQL } from "queries/subscription"
import { withRouter } from 'react-router-dom';

const INVITE_USERS_GQL = gql`
  mutation($input: InviteCustomerInput!) {
    inviteCustomer(input: $input) {
      success
      errors
      messages
    }
  }
`;

const REMOVE_USER_FROM_PROPERTY_GQL = gql`
  mutation($input: RemoveInvitationInput!) {
    remove_invitation(input: $input) {
      success
      errors
    }
  }
`;

const UPDATE_USER_RIGHT_GQL = gql`
  mutation($input: UpdateInvitationInput!) {
    updateInvitation(input: $input) {
      success
      errors
    }
  }
`;

const GET_USER_ACCESS_GQL = gql`
  query ($id: ID!) {
    property(id: $id) {
      id
      permissions
      total_attachments_byte_size
      customer_properties(exclude_creator: true) {
        invitee {
          email
          invite_status
          customer_profile {
            avatar
            first_name
            last_name
          }
        }
        permissions
      }
      pending_invitations {
        email
        permissions
      }
    }
  }
`

class UserAccessList extends React.Component {
  state = {
    users: [],
    currentPermissions: "can_view",
    propertyId: this.props.propertyId,
    isOpenedPopup: false,
    showInvitePopup: false,
    inviteUser: false,
  }

  static refetch = () => {}

  canEdit() {
    if (!this.hasValid()) return true
    return ["can_edit", "full_control"].indexOf(this.state.currentPermissions) >= 0
  }

  hasValid() {
    return !!this.state.propertyId
  }

  renderUser(user, index) {
    const { permission, email, avatar, first_name, last_name, invite_status = "pending" } = user
    const name = [(first_name || "").trim(), (last_name || "").trim()].join(" ").trim()
    return (
      <div key={`user-access-${index}`}>
        <div className="user-access" key={index}>
          <div className="who-has-access">
            <div className="avatar" style={avatar ? { backgroundImage: `url('${avatar}')` } : {}} />
            <div className="name">{name}</div>
            <div className="email">
              {email}
              {invite_status == "pending" ? " (Pending)" : null}
            </div>
          </div>
          <Mutation mutation={UPDATE_USER_RIGHT_GQL}>
            {(updateMutate) => {
              return (
                <PermissionOption
                  permission={permission}
                  canEdit={this.canEdit()}
                  onChange={(value) => {
                    if (!this.hasValid) {
                      this.updateUserPermission(email, value)
                    } else {
                      this.sendUpdateUserPermissionRequest(updateMutate, {
                        email,
                        permission: value,
                      })
                    }
                  }}
                />
              )
            }}
          </Mutation>
          <div className="remove">
            <div
              className="remove-button"
              onClick={() => {
                if (this.hasValid()) {
                  this.openConfirmRemovePopup({
                    user: user,
                  })
                } else {
                  this.removeUser(email)
                }
              }}
            >
              [remove this user]
            </div>
          </div>
        </div>
      </div>
    )
  }

  openConfirmRemovePopup({ user }) {
    this.setState({
      toggleBox: true,
      editingUser: user,
    })
  }

  renderWarningBox() {
    return (
      <Mutation mutation={REMOVE_USER_FROM_PROPERTY_GQL}>
        {(mutate) => (
          <SweetAlert
            show={this.state.toggleBox}
            title="Warning"
            type="warning"
            text="Are you sure you want to remove this user?"
            confirmButtonText="Yes"
            showCancelButton
            cancelButtonText="No"
            onConfirm={() => this.sendRemoveUserRequest(mutate)}
            onCancel={() => this.setState({ toggleBox: false, editingUser: null })}
          />
        )}
      </Mutation>
    )
  }

  sendUpdateUserPermissionRequest(updateMutate, user) {
    if (!this.hasValid()) return
    this.state.inviteUser = false
    updateMutate({
      variables: {
        input: {
          email: user.email,
          permissions: user.permission,
          property_id: this.state.propertyId,
        },
      },
      update: () => {
        this.refetch()
      },
    })
  }

  updateUserPermission(email, permission) {
    let { users } = this.state
    const userIndex = users.findIndex((user) => user["email"] == email)
    if (userIndex >= 0) {
      users[userIndex]["permission"] = permission
      this.setState(
        {
          users: [...users],
        },
        () => this.props.onListChange(this.state.users),
      )
    }
  }

  sendRemoveUserRequest(mutate) {
    if (this.hasValid() && this.state.editingUser) {
      const { email } = this.state.editingUser
      mutate({
        variables: {
          input: {
            email: email,
            property_id: this.state.propertyId,
          },
        },
        update: () => {
          this.setState(
            {
              editingUser: null,
              toggleBox: false,
            },
            () => this.refetch(),
          )
        },
      })
    }
  }

  removeUser(email) {
    const userIndex = this.state.users.findIndex((user) => user["email"] == email)
    if (userIndex >= 0) {
      this.state.users.splice(userIndex, 1)
      this.setState(
        {
          users: [...this.state.users],
        },
        () => this.props.onListChange(this.state.users),
      )
    }
  }

  mergeUserFromListEmail(emails, permission) {
    let newUsers = []
    emails.forEach((value) => {
      let userIndex = this.state.users.findIndex((e) => e["email"] == value)
      if (userIndex >= 0) {
        this.state.users[userIndex]["permission"] = permission
      } else {
        newUsers.push({ email: value, permission: permission })
      }
    })
    return [...this.state.users, ...newUsers]
  }

  handleExceedPlan() {
    this.setState({
      showPopup: true,
      popupMessage:
        "Your subscription plan has exceeded the number of invited users. Would you like to upgrade your plan? ",
    })
  }

  showInvitePopup(inviteMutate, permissions) {
    const content = (
      <InviteUserBox
        emails={[]}
        addedEmails={this.state.users}
        yourself={userAuth.getEmail()}
        propertyId={this.props.propertyId}
        permissions={permissions}
        onExceedPlan={this.handleExceedPlan.bind(this)}
        onInviteDone={(emails, permission) => {
          PopupboxManager.close()
          if (this.hasValid()) {
            this.state.inviteUser = true
            inviteMutate({
              variables: {
                input: {
                  property_id: this.state.propertyId,
                  emails: emails,
                  permissions: permission,
                  invitation_url: `${window.location.origin}/invitations`,
                },
              },
              update: (cache, { data: { inviteCustomer } }) => {
                const messages = inviteCustomer.messages
                const errors = inviteCustomer.errors
                if (messages) this.toastMessages(messages)
                if (errors) this.toastMessages(errors)
                this.refetch()
              },
            })
          } else {
            this.setState(
              {
                users: this.mergeUserFromListEmail(emails, permission),
              },
              () => this.props.onListChange(this.state.users),
            )
          }
        }}
      />
    )

    this.setState({ showInvitePopup: true }, () => {
      let title = "Invite users to view this property"
      PopupboxManager.open({
        content,
        config: {
          titleBar: {
            enable: true,
            text: title,
          },
          onClosed: () => setTimeout(() => this.setState({ showInvitePopup: false }), 200),
        },
      })
    })
  }

  toastMessages(messages) {
    Object.keys(messages).forEach((key) => {
      ;[messages[key]].flatten().forEach((message) => toast.error(message))
    })
  }

  renderNewPropertyAccessList(users = [], permissions) {
    let accessRight = "can_edit"
    if (this.hasValid()) accessRight = permissions
    return (
      <Mutation mutation={INVITE_USERS_GQL}>
        {(inviteMutate, { loading }) => {
          return (
            <div className="UserAccessList">
              <div className={`access-list-container${loading && this.state.inviteUser ? " loading" : ""}`}>
                <div className="list-header">
                  <div className="who-has-access" style={{ fontWeight: "bold" }}>
                    Share With Other Users
                  </div>
                  <div
                    className={`PermissionOption${users.length == 0 ? " hidden" : ""}`}
                    style={{ marginBottom: "-25px" }}
                  >
                    <div className={`can-edit${accessRight == " can_view" ? " invisible" : ""}`}>Edit & View</div>
                    <div className="can-view">View Only</div>
                  </div>
                  <div className="remove" style={{ flexBasis: "86px" }} />
                </div>
                <div className="list-content">{users.map((user, index) => this.renderUser(user, index))}</div>
              </div>
              <div className="add-user-button-container">
                {this.state.showInvitePopup && <PopupboxContainer />}
                {this.renderWarningBox()}
                <Button
                  className="btn btn-dark-blue"
                  preIconClass="mdi mdi-plus-circle mdi-24px"
                  onClick={() => this.showInvitePopup(inviteMutate, accessRight)}
                >
                  Add User
                </Button>
              </div>
              <SweetAlert
                show={this.state.showPopup}
                title="Warning"
                type="warning"
                text={this.state.popupMessage}
                confirmButtonText="OK"
                showCancelButton
                cancelButtonText="Cancel"
                onConfirm={() => {
                  this.setState({ showPopup: false }, () => {
                    this.props.history.push("/subscriptions");
                  });
                }}
                onCancel={() => this.setState({ showPopup: false })}
              />
            </div>
          );
        }}
      </Mutation>
    )
  }
  render() {
    if (this.state.propertyId) {
      return (
        <Query query={GET_USER_ACCESS_GQL} variables={{ id: this.state.propertyId }}>
          {({ loading, error, data, refetch }) => {
            if (loading) return null
            if (error) return `Error ${error.message}`
            this.refetch = refetch
            const { property = {} } = data
            const { customer_properties, permissions, pending_invitations } = property
            this.state.currentPermissions = permissions
            let users = customer_properties.map((e) => ({
              email: e.invitee.email,
              avatar: e.invitee.customer_profile.avatar,
              first_name: e.invitee.customer_profile.first_name,
              last_name: e.invitee.customer_profile.last_name,
              invite_status: e.invitee.invite_status,
              permission: e.permissions,
            }))
            users = users.concat(
              pending_invitations.map((e) => ({
                email: e.email,
                invite_status: "pending",
                permission: e.permissions,
              })),
            )
            this.state.users = users

            return this.renderNewPropertyAccessList(users, permissions)
          }}
        </Query>
      )
    } else {
      return this.renderNewPropertyAccessList(this.state.users, null)
    }
  }
}

export default withRouter(withApollo(UserAccessList))