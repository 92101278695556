import React, { Component } from "react";
import Invitation from "components/Invitation";
import userAuth from "utils/userAuth";

class InvitationPage extends Component {

  render() {
    const { code } = this.props.match.params
    return (
      <div className="InvitationPage">
        {code ? <Invitation code={code} /> : null}
      </div>
    )
  }
}

export default InvitationPage;
