import React, { Component } from 'react'

export default class Loading extends Component {
  render() {
    return (
      <div
        className="Loading"
        style={{
          textAlign: 'center',
          color: '#333',
          width: '100%',
          fontSize: 16,
          marginTop: 40
        }}>
        Loading...
      </div>
    )
  }
}
