import React from 'react';
import './BasicStyle.scss';
import Input from 'components/commons/Input';
import Button from 'components/commons/Button';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import DirectUploadSingleImage from 'components/DirectUploadSingleImage';
import SweetAlert from 'sweetalert2-react';
import Select from 'components/commons/Select';
import CreatableSelect from 'react-select/lib/Creatable';
import UserAccessList from './UserAccessList';
import FooterSection from '../FooterSection';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { scrollTop } from 'utils';
import ReactTooltip from 'react-tooltip';

import {
  CREATE_PROPERTY_GQL,
  GET_PROPERTY_GQL,
  GET_PROPERTY_OPTIONS_GQL,
  REMOVE_PROPERTY_GQL
} from './queries';

class BasicInformation extends React.Component {
  state = {
    showSuccess: false,
    model: {
      tag_names: [],
      name: '',
      thumbnail: '',
      thumbnail_original_url: '',
      country: '',
      market: '',
      owner: '',
      project_name: '',
      loan: '',
      deal_name: '',
      property_type: '',
      description: '',
      year_built: '',
      manager: '',
      longitude: '',
      latitude: '',
      stage: '',
      crop_data: {}
    },
    errors: {},
    propertyOptions: {
      countries: [],
      tags: [],
      propertyTypes: [],
      stages: []
    },
    access_rights: [],
    loading: false
  };

  static defaultProps = {
    proceedToNextSection: () => {},
    onSaveSuccess: () => {}
  };

  static refetch = () => {};

  submitCreateProperty(mutate, finish = false) {
    this.setState({ errors: {}, loading: true });

    const {
      permissions,
      thumbnail_signed_id,
      thumbnail_original_url,
      total_attachments_byte_size,
      ...input
    } = this.state.model;
    mutate({
      variables: {
        input: {
          ...input,
          thumbnail: thumbnail_signed_id,
          access_rights: this.state.access_rights,
          invitation_url: `${window.location.origin}/invitations`,
        },
      },
      update: (cache, { data: { createProperty } }) => {
        if (createProperty.success) {
          this.setState({ loading: false }, () => {
            this.refetch()
            this.onCreatePropertySuccess(createProperty.property, finish)
          })
        } else {
          this.setState({ errors: createProperty.errors, loading: false }, () => scrollTop())
        }
      },
    })
  }

  onCreatePropertySuccess(property, finish) {
    if (finish) this.props.onFinish(property.id);
    this.props.onSaveSuccess(property.id);
  }

  canEdit(unfreeze = true) {
    if (!this.state.model.id) return true;
    if (this.state.model.permissions == 'full_control') {
      return true;
    }
    return this.state.model.permissions == "can_edit"
    // if (unfreeze) {
    //   return this.state.model.permissions == 'can_edit';
    // }
    // return false;
  }

  viewOnly() {
    if (!this.state.model.id) return false;
    return this.state.model.permissions == 'can_view';
  }

  renderLoadingOverlay() {
    if (!this.state.loading) return null;

    return (
      <div className="loading-overlay">
        <i className="fa fa-spin fa-spinner" />
      </div>
    );
  }

  renderBasicPropertyFrom() {
    return (
      <Mutation mutation={CREATE_PROPERTY_GQL}>
        {(mutate, { loading }) => {
          return (
            <div className={`basic-information${this.viewOnly() ? " view-only" : ""}`}>
              <ReactTooltip type="light" border={1} />
              {this.viewOnly() ? null : (
                <div className="header-section">
                  <div className="title">Basic Information</div>
                </div>
              )}
              <div className="basic-container">
                {this.state.errors["base"] ? <p>{this.state.errors["base"]}</p> : null}
                {this.renderLoadingOverlay()}
                {this.state.model.permissions == "can_view" ? null : (
                  <div className="basic-information-form">
                    <div className="flex row space-between">
                      <div className="thumbnail">
                        <DirectUploadSingleImage
                          width={390}
                          height={260}
                          disabled={!this.canEdit()}
                          accept="image/x-png,image/jpeg,image/png"
                          previewUrl={this.state.model["thumbnail"]}
                          originalUrl={this.state.model["thumbnail_original_url"]}
                          recommended="( Recommended 480px by 320px )"
                          onUploadSuccess={(blob) => {
                            this.state.model["thumbnail_signed_id"] = blob.signed_id
                            this.state.model["crop_data"] = blob.cropData
                          }}
                        />
                      </div>
                      <div className="flex column" style={{ width: "100%" }}>
                        <Input
                          label="Property Name"
                          required={true}
                          style={{ width: "100%" }}
                          value={this.state.model["name"]}
                          onChange={(value) => (this.state.model["name"] = value)}
                          error={this.state.errors["name"]}
                          readOnly={!this.canEdit(false)}
                          disabled={!this.canEdit(false)}
                          // inputHtml={{
                          //   "data-tip": !this.canEdit(false) ? "Contact property admin to edit" : null,
                          // }}
                        />

                        <div className="flex row space-between">
                          <div
                            className={`input-container select ${!this.canEdit(false) ? "disabled" : ""}`}
                            style={{ flexBasis: "48%" }}
                          >
                            <label>Country</label>
                            <Select
                              // dataTip={!this.canEdit(false) ? "Contact property admin to edit" : null}
                              name="country"
                              classNamePrefix="select"
                              readOnly={!this.canEdit(false)}
                              matchFromStart={true}
                              defaultValue={
                                this.state.model["country"]
                                  ? {
                                      label: this.state.model["country"],
                                      value: this.state.model["country"],
                                    }
                                  : null
                              }
                              styles={{
                                option: (base, state) => {
                                  return {
                                    ...base,
                                    borderWidth: 1,
                                    backgroundColor: state.isSelected
                                      ? "#16254c"
                                      : state.isFocused
                                      ? "#16254c57"
                                      : null,
                                  }
                                },
                                control: () => ({
                                  borderColor: "#e5e5e5",
                                }),
                              }}
                              placeholder=" "
                              onChange={({ value }) => (this.state.model["country"] = value)}
                              options={(this.state.propertyOptions["countries"] || []).map((e) => ({
                                value: e,
                                label: e,
                              }))}
                            />
                          </div>
                          <div
                            className={`input-container select ${!this.canEdit(false) ? "disabled" : ""}`}
                            style={{ flexBasis: "48%" }}
                          >
                            <label>Property Type</label>
                            <Select
                              // dataTip={!this.canEdit(false) ? "Contact property admin to edit" : null}
                              name="property_type"
                              className="select-property-type"
                              classNamePrefix="select"
                              readOnly={!this.canEdit(false)}
                              defaultValue={
                                this.state.model["property_type"]
                                  ? {
                                      label: this.state.model["property_type"],
                                      value: this.state.model["property_type"],
                                    }
                                  : null
                              }
                              placeholder=" "
                              onChange={({ value }) => (this.state.model["property_type"] = value)}
                              styles={{
                                option: (base, state) => {
                                  return {
                                    ...base,
                                    borderWidth: 1,
                                    backgroundColor: state.isSelected
                                      ? "#16254c"
                                      : state.isFocused
                                      ? "#16254c57"
                                      : null,
                                  }
                                },
                              }}
                              options={(this.state.propertyOptions["propertyTypes"] || []).map((e) => ({
                                value: e,
                                label: e,
                              }))}
                            />
                          </div>
                        </div>
                        <Input
                          label="Market"
                          readOnly={!this.canEdit(false)}
                          onChange={(value) => (this.state.model["market"] = value)}
                          value={this.state.model["market"]}
                          disabled={!this.canEdit(false)}
                          // inputHtml={{
                          //   "data-tip": !this.canEdit(false) ? "Contact property admin to edit" : null,
                          // }}
                        />
                      </div>
                    </div>
                    <div className="flex column">
                      <Input
                        label="Property Manager"
                        readOnly={!this.canEdit()}
                        onChange={(value) => (this.state.model["manager"] = value)}
                        value={this.state.model["manager"]}
                      />
                      <Input
                        label="Owner"
                        readOnly={!this.canEdit()}
                        onChange={(value) => (this.state.model["owner"] = value)}
                        value={this.state.model["owner"]}
                      />
                      <Input
                        label="Loan"
                        readOnly={!this.canEdit()}
                        onChange={(value) => (this.state.model["loan"] = value)}
                        value={this.state.model["loan"]}
                      />
                      <Input
                        label="Deal Name"
                        readOnly={!this.canEdit()}
                        onChange={(value) => (this.state.model["deal_name"] = value)}
                        value={this.state.model["deal_name"]}
                      />
                      <Input
                        label="Project Name"
                        readOnly={!this.canEdit()}
                        onChange={(value) => (this.state.model["project_name"] = value)}
                        value={this.state.model["project_name"]}
                      />
                      <Input
                        label="Description ( 10000 Characters Max )"
                        readOnly={!this.canEdit()}
                        onChange={(value) => (this.state.model["description"] = value)}
                        value={this.state.model["description"]}
                        wrapperHtml={{
                          className: "property-details-container",
                        }}
                        inputHtml={{
                          className: "property-details",
                          rows: 6,
                          type: "richtext",
                          maxLength: 10000,
                        }}
                      />
                      <div className="flex row space-between">
                        <div className="input-container select" style={{ flexBasis: "48%" }}>
                          <label>Stage</label>
                          <Select
                            name="stage"
                            readOnly={!this.canEdit()}
                            classNamePrefix="select"
                            defaultValue={{
                              label: this.state.model["stage"],
                              value: this.state.model["stage"],
                            }}
                            styles={{
                              option: (base, state) => {
                                return {
                                  ...base,
                                  borderWidth: 1,
                                  backgroundColor: state.isSelected ? "#16254c" : state.isFocused ? "#16254c57" : null,
                                }
                              },
                            }}
                            placeholder=" "
                            onChange={({ value }) => (this.state.model["stage"] = value)}
                            options={(this.state.propertyOptions["stages"] || []).map((e) => ({
                              value: e,
                              label: e,
                            }))}
                          />
                        </div>

                        <Input
                          wrapperHtml={{ style: { flexBasis: "48%" } }}
                          label="Year Built"
                          readOnly={!this.canEdit()}
                          onChange={(value) => (this.state.model["year_built"] = value)}
                          value={this.state.model["year_built"]}
                        />
                      </div>
                      <div className="input-container select">
                        <label>Tags</label>
                        <CreatableSelect
                          isDisabled={!this.canEdit()}
                          styles={{
                            control: (base, state) => {
                              return {
                                ...base,
                                borderWidth: 1,
                                boxShadow: state.isFocused ? "none" : "",
                                backgroundColor: "white",
                                borderColor: state.isFocused ? "#262e40 !important" : "#ccc",
                              }
                            },
                            option: (base, state) => {
                              return {
                                ...base,
                                borderWidth: 1,
                                backgroundColor: state.isSelected ? "#16254c" : state.isFocused ? "#16254c57" : null,
                              }
                            },
                          }}
                          name="tags"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          defaultValue={this.state.model["tag_names"].map((e) => ({
                            value: e,
                            label: e,
                          }))}
                          placeholder=" "
                          isMulti
                          onChange={(value) => (this.state.model["tag_names"] = value.map((e) => e["value"]))}
                          options={(this.state.propertyOptions["tags"] || []).map((e) => ({
                            value: e,
                            label: e,
                          }))}
                        />
                      </div>
                      <div className="flex row space-between">
                        <Input
                          wrapperHtml={{ style: { flexBasis: "48%" } }}
                          label="Latitude (in DD e.g. 1.379477)"
                          readOnly={!this.canEdit()}
                          onChange={(value) => (this.state.model["latitude"] = value)}
                          value={this.state.model["latitude"]}
                        />
                        <Input
                          wrapperHtml={{ style: { flexBasis: "48%" } }}
                          label="Longitude (in DD e.g. 103.766733)"
                          readOnly={!this.canEdit()}
                          onChange={(value) => (this.state.model["longitude"] = value)}
                          value={this.state.model["longitude"]}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!this.state.model.id || this.state.model.permissions == "full_control" ? (
                  <UserAccessList
                    propertyId={this.state.model.id}
                    onListChange={(value) => (this.state["access_rights"] = value)}
                  />
                ) : null}
              </div>
              <FooterSection
                canBack={false}
                canNext={!this.viewOnly()}
                disabled={loading}
                doFinish={() => {
                  if (this.canEdit()) {
                    this.submitCreateProperty(mutate, true)
                  } else {
                    this.props.history.push(`/property/${this.props.propertyId}`)
                  }
                }}
                doNext={() => {
                  if (this.canEdit()) {
                    this.submitCreateProperty(mutate)
                  } else {
                    this.props.proceedToNextSection()
                  }
                }}
              />
            </div>
          )
        }}
      </Mutation>
    );
  }

  render() {
    const { propertyId } = this.props;
    return propertyId ? (
      <Query
        query={GET_PROPERTY_GQL}
        variables={{ id: propertyId }}
        fetchPolicy="network-only">
        {({ loading, error, data, refetch }) => {
          if (error || loading) return null;
          this.refetch = refetch;
          const { property = {}, propertyOptions = {} } = data;
          this.state.model = { ...this.state.model, ...property };
          this.state.propertyOptions = propertyOptions;
          return this.renderBasicPropertyFrom();
        }}
      </Query>
    ) : (
      <Query query={GET_PROPERTY_OPTIONS_GQL}>
        {({ loading, error, data, refetch }) => {
          if (error || loading) return null;
          this.refetch = refetch;
          const { propertyOptions = {} } = data;
          this.state.propertyOptions = propertyOptions;
          return this.renderBasicPropertyFrom();
        }}
      </Query>
    );
  }
}

export default withRouter(BasicInformation);
