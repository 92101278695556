import React from 'react';
import './ImageCropper.scss';
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import Button from 'components/commons/Button';
import Cropper from 'components/commons/ReactCropper';
import 'cropperjs/dist/cropper.css';
const $ = window.$;

export default class ImageCropper extends React.Component {
  state = {
    showPopup: this.props.showPopup,
    cropData: {}
  };

  static defaultProps = {
    showPopup: false,
    onCrop: () => {},
    previewId: '',
    onCancel: () => {}
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.showPopup != this.state.showPopup) {
      this.setState(
        {
          showPopup: nextProps.showPopup
        },
        () => {
          nextProps.showPopup
            ? this.openCropperLightbox()
            : this.closeCropperLightbox();
        }
      );
    }
  }

  componentDidMount() {
    this.state.showPopup && this.openCropperLightbox();
  }

  openCropperLightbox() {
    PopupboxManager.open({
      content: this.lightboxContent(),
      config: {
        overlayClose: false,
        titleBar: {
          enable: false,
          closeButton: false
        },
        onComplete: () => {
          $('#root').addClass('noscroll');
        },
        onClosed: () => {
          $('#root').removeClass('noscroll');
          setTimeout(() => this.setState({ showPopup: false }), 200);
        }
      }
    });
  }

  _crop(event) {
    this.state.cropData = event.detail;
  }

  _rotateLeft() {
    this.cropper.rotate(-90);
  }

  _rotateRight() {
    this.cropper.rotate(90);
  }

  lightboxContent() {
    return (
      <div className="CropperLighbox">
        {this.props.imgSrc && (
          <div className="image-wrapper">
            <div className="control-buttons">
              <div
                className="rotate-button rotate-left"
                onClick={() => this._rotateLeft()}>
                <span className="mdi mdi-rotate-left-variant" />
              </div>
              <div
                className="rotate-button rotate-right"
                onClick={() => this._rotateRight()}>
                <span className="mdi mdi-rotate-right-variant" />
              </div>
            </div>
            <Cropper
              ref={cropper => (this.cropper = cropper)}
              style={{maxHeight: "75vh"}}
              src={this.props.imgSrc}
              minCropBoxWidth={320}
              minCropBoxHeight={213.33333333}
              minCanvasWidth={320}
              minCanvasHeight={213.33333333}
              viewMode={0}
              checkCrossOrigin={true}
              checkOrientation={this.props.imgSrc.includes("blob")}
              aspectRatio={3 / 2}
              modal={true}
              guides={false}
              responsive={true}
              restore={true}
              scalable={true}
              rotatable={true}
              zoomable={true}
              dragMode="move"
              crossOrigin="Anonymous"
              crop={this._crop.bind(this)}
            />
          </div>
        )}
        <div
          className="filter-footer flex row jc-center al-center"
          style={{ marginTop: '15px' }}>
          <Button
            className="btn btn-dark-blue"
            onClick={() => {
              this.props.onCrop(
                this.state.cropData,
                this.cropper.getCroppedCanvas({ fillColor: '#fff' })
              );
              PopupboxManager.close();
            }}>
            Apply
          </Button>
          <div style={{ width: 15 }} />
          <Button
            className="btn btn-dark-blue"
            onClick={() => {
              this.props.onCancel();
              PopupboxManager.close();
            }}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  closeCropperLightbox() {
    PopupboxManager.close();
  }

  render() {
    return (
      <div className="ImageCropper">
        {this.state.showPopup && (
          <PopupboxContainer
            overlayClose={false}
            titleBar={{
              enable: false,
              closeButton: false
            }}
          />
        )}
      </div>
    );
  }
}
