import React from 'react';
import defaultThumbnail from 'assets/images/asset_base_logo_with_text.svg';
import Truncate from 'react-truncate';
import './PropertyPopup.scss';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import { compose, withProps, withStateHandlers } from "recompose"
import config from "config"

const PropertyPopup = props => {
  const property = props;
  return (
    <div className="info-window">
      <div className="thumbnail">
        <img
          src={property.thumbnail || defaultThumbnail}
          alt=""
        />
        <div className='type-stage'>
          {[property.property_type, property.stage].filter(e => !!e).join(', ')}
        </div>
      </div>
      <div className="property-info">
        <div className="name" title={property.name}>
          <Truncate lines={2} ellipsis={'...'}>
            {property.name}
          </Truncate>
        </div>
        <div className="description">
          {[property.country, property.market]
            .filter(e => !!e)
            .join(', ')}
        </div>
      </div>
    </div>
  );
};

const GoogleMapView = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${config.google_api_key}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    isMarkerShown: true
  }),
  withStateHandlers(() => ({
    isOpen: true,
  }), {
    onToggleOpen: ({ isOpen }) => () => ({
      isOpen: !isOpen
    })
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const position = { lat: parseFloat(props.latitude), lng: parseFloat(props.longitude) };
  return <GoogleMap
    defaultZoom={12}
    defaultCenter={position}
  >
    {props.isMarkerShown &&
      <Marker position={position} onClick={props.onToggleOpen}>
        {
          props.isOpen &&
          <InfoWindow onCloseClick={props.onToggleOpen}>
            <PropertyPopup {...props} />
          </InfoWindow>
        }
      </Marker>
    }

  </GoogleMap >
}

)

export default GoogleMapView;
