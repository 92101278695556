import React from 'react'
import RequestForm from 'components/RequestForm'
import './style.scss'

export const LandingPage = () => {
    return (
      <div className="LandingPage">
        <RequestForm />
      </div>
    )
}

export default LandingPage
