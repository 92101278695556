import React, { Component } from 'react'
import CustomerProfile from 'components/CustomerProfile'
class UserProfilePage extends Component {


  render() {
    return (
      <div className="UserProfilePage">
        <div className="title">USER PROFILE</div>
        <CustomerProfile />
      </div>
    );
  }
}

export default UserProfilePage
