import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import './PropertyReport.scss';
import ExportPropertyReportButton from './ExportPropertyReportButton';
import ReactTable from 'react-table';
import FoldableTableHOC from 'react-table/lib/hoc/foldableTable';
import 'react-table/react-table.css';
import Button from 'components/commons/Button';
import SearchBox from "./SearchBox";

const FoldableTable = FoldableTableHOC(ReactTable);

export const GET_LIST_PROPERTY_GQL = gql`
  query($page: Int, $per_page: Int, $filter: JSON, $sort: JSON) {
    propertyReport(
      filter: $filter
      sort: $sort
      page: $page
      per_page: $per_page
    ) {
      properties {
        id
        name
        country
        market
        owner
        project_name
        loan
        deal_name
        stage
        property_type
        manager
        year_built
        code
      }
      pagination {
        pages
        pageSize
        page
      }
    }
  }
`;

const foldButtonComponent = ({ header, collapsed, icon, onClick }) => {
  const style = {
    border: 'solid 1px #337ab7',
    cursor: 'pointer',
    color: '#337ab7',
    background: '#fff',
    maxWidth: 60,
    borderRadius: 4,
    padding: 2,
    margin: 'auto'
  };

  return (
    <div>
      {collapsed ? (
        <div style={style} onClick={onClick}>
          More
        </div>
      ) : (
        <div style={style} onClick={onClick}>
          Less
        </div>
      )}
    </div>
  );
};

export default class PropertyReport extends Component {
  state = {
    data: [],
    pages: -1,
    loading: false,
    page: 1,
    pageSize: 20,
    sort: {
      created_at: 'DESC'
    },
    folded: { more: true },
    filter: {}
  };

  getColumns() {
    return [
      {
        Header: 'Basic Info',
        foldable: false,
        columns: [
          {
            Header: 'Property Name',
            accessor: 'name',
            width: 140
          },
          {
            Header: 'Type',
            accessor: 'property_type'
          },
          {
            Header: 'Country',
            accessor: 'country'
          },
          {
            Header: 'Market',
            accessor: 'market'
          },
          {
            Header: 'Owner',
            accessor: 'owner'
          }
        ]
      },
      {
        Header: 'More Info',
        foldable: true,
        id: 'more',
        columns: [
          {
            Header: 'Project Name',
            accessor: 'project_name',
            width: 140
          },
          {
            Header: 'Deal Name',
            accessor: 'deal_name'
          },
          {
            Header: 'Loan',
            accessor: 'loan'
          },
          {
            Header: 'Stage',
            accessor: 'stage'
          },
          {
            Header: 'Year Built',
            accessor: 'year_built'
          },
          {
            Header: 'Manager',
            accessor: 'manager'
          },
          {
            Header: 'Code',
            accessor: 'code'
          }
        ]
      }
    ];
  }

  onFetchData(state, instance) {
    this.setState({
      page: state.page + 1,
      pageSize: state.pageSize,
      sort: state.sorted.reduce((sort, e) => {
        sort[e.id] = e.desc ? 'DESC' : 'ASC';
        return sort;
      }, {})
    });
  }

  render() {
    const { pageSize, page, sort, filter } = this.state;
    return (
      <div className="PropertyReport">
        <div className="top-buttons">
          <SearchBox onSearch={keyword => this.setState({filter: {keyword}, page: 1})} />
          <ExportPropertyReportButton />
        </div>
        <div className="table-container">
          <Query
            query={GET_LIST_PROPERTY_GQL}
            variables={{
              page: page,
              per_page: pageSize,
              filter: filter,
              sort:
                Object.keys(sort).length === 0 ? { created_at: 'DESC' } : sort
            }}
            fetchPolicy="cache-and-network">
            {({ loading, error, data = {} }) => {
              const { propertyReport = {} } = data;
              const { properties = [], pagination = {} } = propertyReport;
              return (
                <FoldableTable
                  className="-striped -highlight"
                  data={properties}
                  page={pagination.page ? pagination.page - 1 : 0}
                  pages={pagination.pages || -1}
                  loading={loading}
                  pageSize={pagination.pageSize || 20}
                  FoldButtonComponent={foldButtonComponent}
                  FoldedColumn={{
                    width: 80,
                    sortable: false,
                    resizable: false,
                    filterable: false
                  }}
                  columns={this.getColumns()}
                  onFoldChange={newFolded =>
                    this.setState(p => {
                      return { folded: newFolded };
                    })
                  }
                  folded={this.state.folded}
                  manual
                  onFetchData={(state, instance) =>
                    this.onFetchData(state, instance)
                  }
                />
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
}
