import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Help from 'components/Help'

class HelpPage extends Component {
  render() {
    return (
      <div className="HelpPage">
        <div className='title'>HELP</div>
        <Help />
      </div>
    )
  }
}

export default HelpPage
