import React, { Component } from "react"
import "./Invitation.scss"
import { withRouter } from "react-router-dom"
import gql from "graphql-tag"
import { Mutation, Query, withApollo } from "react-apollo"

export const GET_INVITATION_GQL = gql`
  query ($code: String!) {
    invitation(code: $code) {
      id
      email
      invite_type
      code
      status
      created_at
      updated_at
      property {
        id
        name
      }
      creator {
        email
      }
    }
  }
`

export const JOIN_PROPERTY_BY_INVITATION_GQL = gql`
  mutation ($input: JoinByInvitationInput!) {
    join_by_invitation(input: $input) {
      success
      message
    }
  }
`
class Invitation extends Component {
  state = {}

  componentDidMount() {
    if (this.props.code) this.fetchInvitation(this.props.code)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.code !== nextProps.code) {
      this.fetchInvitation(nextProps.code)
    }
  }

  fetchInvitation(code) {
    this.setState({ loading: true }, () => {
      this.props.client
        .query({
          query: GET_INVITATION_GQL,
          variables: { code: code },
        })
        .then(({ data: { invitation } }) => {
          if (invitation.status !== "pending") {
            this.setState({ loading: false, invitation })
          } else {
            this.handleJoin(invitation)
          }
        })
        .catch((error) => {
          this.setState({ loading: false, error: error.message })
        })
    })
  }

  handleJoin(invitation) {
    this.props.client.mutate({
      mutation: JOIN_PROPERTY_BY_INVITATION_GQL,
      variables: { input: { code: invitation.code } },
      update: (_, { data: { join_by_invitation } }) => {
        if (join_by_invitation.success) {
          if (invitation.invite_type === "customer") {
            this.props.history.push("/property/" + invitation.property.id)
          } else {
            this.props.history.push(`/sign-up?invitation_code=${invitation.code}`)
          }
        } else {
          this.setState({ message: join_by_invitation.message })
        }
      },
    })
  }

  renderInvalid() {
    return <div>Invalid Invitation</div>
  }

  renderCompletedInvitation(invitation) {
    return <div>This invitation completed</div>
  }

  render() {
    return (
      <div className="Invitation">
        {this.state.loading
          ? "Loading....."
          : this.state.error
          ? `Error! ${this.state.error}`
          : !this.state.invitation
          ? this.renderInvalid()
          : this.renderCompletedInvitation(this.state.invitation)}
      </div>
    )
  }
}

export default withRouter(withApollo(Invitation))
