import React from 'react';
import { Route } from 'react-router-dom';
import { zoomOutMobile } from 'utils';
import PageHeader from "./Header"
import PageFooter from "./Footer"
import { ToastContainer } from "react-toastify"

const SignUpLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => {
        zoomOutMobile();
        return (
          <div className="SignUpLayout">
            <PageHeader isSignInPage={matchProps.location.pathname === "/sign-in"} isTnCPage={matchProps.location.pathname === "/terms-and-conditions"} />
            <div className="PageContent">
              <Component {...matchProps} />
            </div>
            <PageFooter />
            <ToastContainer />
          </div>
        );
      }}
    />
  );
};

export default SignUpLayout;
