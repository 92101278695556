import React, { Component } from 'react'
import SharedCustomers from 'components/SharedCustomers'

class SharedCustomersPage extends Component {
  render() {
    return (
      <div className="SharedCustomersPage">
        <div className="title">SHARED USERS</div>
        <SharedCustomers />
      </div>
    );
  }
}

export default SharedCustomersPage
