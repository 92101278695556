import React, { Component } from "react";
import Cookies from "components/Cookies";

class CookiesPage extends Component {
  render() {
    return (
      <div className="CookiesPage">
        <Cookies />
      </div>
    )
  }
}

export default CookiesPage;
