import React from 'react';
import './PropertyPageHeader.scss';

const PropertyPageHeader = ({
  trails,
  currentIndex,
  onClick,
  suffixComponent
}) => (
  <div className="PropertyPageHeader">
    <div className="breadcrumb">
      {trails.map((e, i) => {
        const connector = i == 0 ? '' : ' | ';
        const highlightClass = currentIndex === i ? 'active' : '';
        const disabledClass = e['disabled'] ? 'disabled' : '';
        return (
          <div
            key={i}
            className={['menu-item', highlightClass, disabledClass].join(' ')}
            onClick={() => onClick(i)}>
            <span className="connector">{connector}</span>
            {e['text']}
          </div>
        );
      })}
      {suffixComponent}
    </div>
  </div>
);

export default PropertyPageHeader;
