import React, { Component } from 'react'
import ForgotPassword from 'components/ForgotPassword'

class ForgotPasswordPage extends Component {


  render() {
    return (
      <div className="ForgotPasswordPage">
        <ForgotPassword />
      </div>
    )
  }
}

export default ForgotPasswordPage
