import React, { Component } from "react"
import Select, { createFilter, components } from "react-select"
import PropTypes from "prop-types"
import { GET_ALL_SUBSCRIPTION_PLANS_GQL } from "queries/sign_up"
import { Query } from "react-apollo"

Select.propTypes = {
  label: PropTypes.string,
}

const SelectContainer = ({ children, ...props }) => {
  const { selectProps } = props
  return (
    <div data-tip={selectProps.dataTip}>
      <components.SelectContainer {...props}>{children}</components.SelectContainer>
    </div>
  )
}

export default class SubscriptionSelectInput extends Component {
  state = {
    defaultValue: this.props.defaultValue,
    options: this.props.options,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue != this.state.defaultValue) {
      this.setState({ defaultValue: nextProps.defaultValue })
    }
  }

  render() {
    const { defaultValue, onChange, styles, readOnly, ...rest } = this.props
    const filterConfig = {
      ignoreCase: true,
      ignoreAccents: true,
      matchFrom: this.props.matchFromStart ? "start" : "any",
    }

    return (
      <Query query={GET_ALL_SUBSCRIPTION_PLANS_GQL} fetchPolicy="cache-and-network">
        {({ loading, error, data, fetchMore, query }) => {
          const { all_subscription_plans } = data || {}
          const options = (all_subscription_plans || []).map((e) => ({
            label: e.name,
            value: e.id,
          }))
          const selected = options.find((e) => e.value == this.state.defaultValue)

          return (
            <Select
              isDisabled={readOnly}
              styles={{
                ...styles,
                control: (base) => {
                  return {
                    ...base,
                    backgroundColor: "#fff",
                  }
                },
              }}
              {...rest}
              value={selected}
              components={{ SelectContainer }}
              onChange={(option) => {
                this.setState(
                  {
                    defaultValue: option ? option.value : null,
                  },
                  () => onChange(option),
                )
              }}
              filterOption={createFilter(filterConfig)}
              options={options}
            />
          )
        }}
      </Query>
    )
  }
}
