import React from 'react';
import Button from 'components/commons/Button';
import CreatableSelect from 'react-select/lib/Creatable';
import CheckBox from 'components/commons/CheckBox';

import gql from "graphql-tag"
import { Mutation } from 'react-apollo';

export const CHECK_INVITATION_EMAIL_GQL = gql`
  mutation ($input: CheckInvitationEmailInput!) {
    check_invitation_email(input: $input) {
      success
      message
    }
  }
`

export default class InviteUserBox extends React.Component {
  state = {
    emails: this.props.emails || [],
    addedEmails: this.props.addedEmails || [],
    permission: "can_view",
    error: "",
    message: "",
  }

  static defaultProps = {
    onInviteDone: () => {},
  }

  handleSaveClick(mutate) {
    if (this.state.emails.length > 0) {
      const allEmailCorrect = this.state.emails.every(({ value: email }) => this.isValidEmail(email))
      if (allEmailCorrect) {
        mutate({
          variables: {
            input: {
              emails: this.state.emails.map((e) => e["value"]),
            },
          },
          update: (_, { data: { check_invitation_email } }) => {
            if (check_invitation_email.success) {
              this.setState(
                {
                  message: null,
                  errors: "",
                },
                () =>
                  this.props.onInviteDone(
                    this.state.emails.map((e) => e["value"]),
                    this.state.permission,
                  ),
              )
            } else {
              this.setState({ error: check_invitation_email.message }, () => {
                if (typeof this.props.onExceedPlan === "function") {
                  this.props.onExceedPlan()
                }
              })
            }
          },
        })
        
      } else {
        this.setState({ error: this.state.message })
      }
    } else if (this.state.inputValue) {
      if (this.isValidEmail(this.state.inputValue)) {
          mutate({
            variables: {
              input: {
                emails: [this.state.inputValue],
              },
            },
            update: (_, { data: { check_invitation_email } }) => {
              if (check_invitation_email.success) {
                this.setState(
                  {
                    message: null,
                    errors: "",
                  },
                  () => this.props.onInviteDone([this.state.inputValue], this.state.permission),
                )
              } else {
                this.setState({ error: check_invitation_email.message }, () => {
                  if (typeof this.props.onExceedPlan === "function") {
                    this.props.onExceedPlan()
                  }
                })
              }
            },
          })
      } else {
        this.setState({ error: this.state.message })
      }
    } else {
      this.props.onInviteDone([], this.state.permission)
    }
  }

  isValidEmail(email) {
    let check = true
    if (/([\w\.\-_+]+)?\w+@[\w-_]+(\.\w+){1,}/.test(email) === false) {
      this.state.message = "Email is not valid"
      check = false
    }
    this.state.addedEmails.forEach((item) => {
      if (item.email === email) {
        check = false
        this.state.message = "This user has already been invited"
      }
    })
    if (email === this.props.yourself) {
      check = false
      this.state.message = "Can not invite yourself"
    }
    return check
  }

  handleInputChange(inputValue, { action }) {
    if (action == "input-change") this.setState({ inputValue })
  }

  handleKeyDown(event, mutate) {
    const { inputValue } = this.state
    switch (event.key) {
      case "Tab":
        if (inputValue) return
        break
      case "Enter":
        if (inputValue) return
        this.handleSaveClick(mutate)
        event.preventDefault()
        break
    }
  }

  render() {
    return (
      <Mutation mutation={CHECK_INVITATION_EMAIL_GQL}>
        {(mutate) => (
          <div className="InviteUserBox">
            <div className="box-container">
              <div className="emails-section" id="invite-box">
                <label>Email:</label>
                <CreatableSelect
                  ref="select"
                  tabIndex={1}
                  isMulti
                  classNamePrefix="createable-select"
                  autoFocus={true}
                  onInputChange={(inputValue, event) => this.handleInputChange(inputValue, event)}
                  inputValue={this.state.inputValue}
                  onKeyDown={(event) => this.handleKeyDown(event, mutate)}
                  placeholder="Enter email here..."
                  components={{ DropdownIndicator: null }}
                  onChange={(value) => this.setState({ emails: value, error: "", inputValue: "" })}
                  innerRef={(ref) => (this.selectRef = ref)}
                  options={[]}
                  styles={{
                    menu: (base) => {
                      return {
                        ...base,
                        display: "none",
                      }
                    },
                    control: (base, state) => {
                      return {
                        ...base,
                        borderWidth: 1,
                        boxShadow: state.isFocused ? "none" : "",
                        borderColor: state.isFocused ? "#375fac !important" : "#DCDCDC",
                      }
                    },
                    multiValue: (base, { data }) => {
                      return { ...base, backgroundColor: "none" }
                    },
                    multiValueLabel: (base, { data }) => {
                      if (this.isValidEmail(data.value)) return base
                      return {
                        ...base,
                        textDecoration: "red underline dashed",
                        textDecorationSkipInk: "none",
                      }
                    },
                  }}
                />
                {this.state.error && <div className="err-message">{this.state.error}</div>}
              </div>
              <div className="permission-section">
            {['can_edit', 'full_control'].indexOf(this.props.permissions) >=
            0 ? (
              <div className="can-edit">
                <span>Edit & View</span>
                <CheckBox
                  tabIndex={2}
                  radio={true}
                  onChange={checked => {
                    if (!checked) {
                      this.setState({ permission: 'can_edit' });
                    }
                  }}
                  checked={this.state.permission == 'can_edit'}
                />
              </div>
            ) : null}
            <div className="can-view">
              <span>View Only</span>
              <CheckBox
                tabIndex={3}
                radio={true}
                onChange={checked => {
                  if (!checked) {
                    this.setState({ permission: 'can_view' });
                  }
                }}
                checked={this.state.permission == 'can_view'}
              />
            </div>
          </div>
            </div>
            <div className="footer flex row jc-center">
              <Button
                tabIndex={4}
                className="btn btn-dark-blue"
                onKeyDown={(event) => {
                  switch (event.key) {
                    case "Tab":
                      event.preventDefault()
                      this.refs.select.focus()
                      break
                    case "Enter":
                      this.handleSaveClick(mutate)
                      break
                  }
                }}
                onClick={() => this.handleSaveClick(mutate)}
              >
                Invite
              </Button>
            </div>
          </div>
        )}
      </Mutation>
    )
  }
}
