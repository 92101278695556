import React, { Component } from 'react'
import ResetPassword from 'components/ResetPassword'
import queryString from 'query-string'

class ResetPasswordPage extends Component {
  render() {
    const params = queryString.parse(this.props.location.search)
    const token = params['token']
    return (
      <div className="ResetPasswordPage">
        <ResetPassword token={token} />
      </div>
    )
  }
}

export default ResetPasswordPage
