import React from 'react';
import { Route } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import userAuth from 'utils/userAuth';
import { scrollTop } from 'utils';
import { ToastContainer } from 'react-toastify';
import { zoomOutMobile } from 'utils';

export default class DefaultLayout extends React.Component {
  renderScrollTopButton() {
    return (
      <div
        id="scrolltop-button"
        className={`scroll-top-button hide`}
        onClick={() => scrollTop()}>
        <div className="btn-scroll">
          <div className="arrow">
            <i className="fa fa-chevron-up" />
          </div>
          <div className="label" style={{ marginTop: 2 }}>
            TOP
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { component: Component, activeMenu, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={matchProps => {
					zoomOutMobile();
          return (
            <div className="DefaultLayout" id="root-layout">
              {userAuth.getAuth().is_authenticated && (
                <Header activeMenu={activeMenu} key="main-menu" />
              )}
              <div className="PageContent">
                <Component {...matchProps} />
              </div>
              {this.renderScrollTopButton()}
              <Footer />

              <ToastContainer />
            </div>
          );
        }}
      />
    );
  }

  isBottom(el) {
    if (!el) return false;
    return el.getBoundingClientRect().bottom - 100 <= window.innerHeight;
  }

  isTop(el) {
    if (!el) return false;
    return el.getBoundingClientRect().top >= -100;
  }

  componentDidMount() {
    document
      .getElementById('root')
      .addEventListener('scroll', this.trackScrolling, true);
  }

  componentWillUnmount() {
    document
      .getElementById('root')
      .removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('root-layout');
    if (this.isBottom(wrappedElement)) {
      document.getElementById('scrolltop-button').classList.remove('hide');
      document.getElementById('scrolltop-button').classList.add('show');
    }

    if (this.isTop(wrappedElement)) {
      document.getElementById('scrolltop-button').classList.remove('show');
      document.getElementById('scrolltop-button').classList.add('hide');
    }
  };
}
