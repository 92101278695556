import React from 'react'

const BasicView = ({
  id,
  thumbnail,
  name,
  country,
  market,
  property_type,
  owner,
  loan,
  deal_name,
  year_built,
  manager,
  stage,
  tags,
  description,
  is_new = false
}) => (
  <div className="BasicView flex column">
    <div className="main-content flex row">
      {thumbnail ? (
        <div
          className="thumbnail"
          style={{ backgroundImage: `url('${thumbnail}')` }}
        />
      ) : (
        <div className="thumbnail-placeholder">
          <div className="image" />
        </div>
      )}
      <div className="body-content flex column grow-1">
        <div className="name">{is_new ? <div className="new-label">NEW</div> : null }{name}</div>
        <div className="info flex column">
          {/\S/.test(country) && (
            <div className="row">
              <label>Country</label>
              <div className="content" title={country}>
                {country}
              </div>
            </div>
          )}
          {/\S/.test(market) && (
            <div className="row">
              <label>Market</label>
              <div className="content" title={market}>
                {market}
              </div>
            </div>
          )}
          {/\S/.test(property_type) && (
            <div className="row">
              <label>Type</label>
              <div className="content" title={property_type}>
                {property_type}
              </div>
            </div>
          )}
          {/\S/.test(owner) && (
            <div className="row">
              <label>Owner</label>
              <div className="content" title={owner}>
                {owner}
              </div>
            </div>
          )}
          {/\S/.test(loan) && (
            <div className="row">
              <label>Loan</label>
              <div className="content" title={loan}>
                {loan}
              </div>
            </div>
          )}
          {/\S/.test(deal_name) && (
            <div className="row">
              <label>Deal name</label>
              <div className="content" title={deal_name}>
                {deal_name}
              </div>
            </div>
          )}
          {/\S/.test(year_built) && (
            <div className="row">
              <label>Year Built</label>
              <div className="content" title={year_built}>
                {year_built}
              </div>
            </div>
          )}
          {/\S/.test(manager) && (
            <div className="row">
              <label>Property Manager</label>
              <div className="content" title={manager}>
                {manager}
              </div>
            </div>
          )}
          {/\S/.test(stage) && (
            <div className="row">
              <label>Stage</label>
              <div className="content" title={stage}>
                {stage}
              </div>
            </div>
          )}
          {tags.length > 0 && (
            <div className="row">
              <label>Tags</label>
              <div className="content" title={tags.join(', ')}>
                {tags.join(', ')}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    <div className="hz-line" />
    <div className="description flex column">
      <div className="title">Details</div>
      <p
        className="description-content"
        dangerouslySetInnerHTML={{
          __html: description
        }}
      />
    </div>
    <div className="hz-line" />
  </div>
)

export default BasicView
