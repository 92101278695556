import React from 'react';
import './SwitchButton.scss';

export class DelayLoading extends React.Component {
  state = {
    show: this.props.show
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      const { delay = 400 } = this.props;
      this.timoutId = setTimeout(() => this.setState({ show: this.props.show }), delay);
    } else {
      this.setState({show: false})
    }
  }

  componentWillMount() {
    const { delay = 400 } = this.props;
    this.timoutId = setTimeout(() => this.setState({ show: this.props.show }), delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  render() {
    if (this.state.show) {
      return <span className="fa fa-spin fa-spinner" />;
    } else {
      return null;
    }
  }
}

export default class SwitchButton extends React.Component {
  state = {
    checked: this.props.checked || false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.checked != this.state.check && !nextProps.loading) {
      this.setState({ checked: nextProps.checked });
    }
  }

  handleOnChange({ target: { value } }) {
    const { onChange = () => {} } = this.props;
    this.setState(
      {
        checked: !this.state.checked
      },
      () => onChange(this.state.checked)
    );
  }

  render() {
    const { loading } = this.props;
    const { checked } = this.state;

    return (
      <div className={`SwitchButton ${loading ? 'loading' : ''}`}>
        <label className="switch">
          <input
            type="checkbox"
            checked={checked}
            onChange={event => this.handleOnChange(event)}
          />
          <span className="slider round">
            <DelayLoading  show={loading}/>
          </span>
        </label>
      </div>
    );
  }
}
