import React from 'react'
import userAuth from 'utils/userAuth'
import './Footer.scss'
import { Link, withRouter } from "react-router-dom";

class Footer extends React.Component {
  render() {
    const { history  } = this.props;
    return (
      <div className="Footer">
        <div className="footer-content">
          ©{new Date().getFullYear()} AssetBase Services Pte. Ltd. All rights reserved.
        </div>
        <div className="footer-menu">
          <MainMenu currentLocation={history.location.pathname} />
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);


const MENU = [
  {
    pathname: "/a/privacy",
    id: "privacy",
    text: "PRIVACY",
  },
  {
    pathname: "/a/legal",
    id: "legal",
    text: "LEGAL",
  },
  {
    pathname: "/a/cookies",
    id: "cookies",
    text: "COOKIES",
  },
];

const MainMenu = ({ currentLocation, activeMenu, onClickLink = () => {} }) => {
  const activeMenuLink = (menu) => {
    if (activeMenu) {
      return menu["id"] == activeMenu ? " active" : "";
    }
    return currentLocation == menu["pathname"] ? " active" : "";
  };
  const renderMenu = (menu, index) => {
    return (
      <Link
        key={index}
        onClick={() => onClickLink()}
        className={`menu-link${activeMenuLink(menu)}`}
        to={menu["pathname"]}
      >
        {index === 0 ? (
          menu["text"]
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="divider">|</div>
            <div>{menu["text"]}</div>
          </div>
        )}
      </Link>
    );
  };

  return <div className="MainMenu">{MENU.map((e, i) => renderMenu(e, i))}</div>;
};
