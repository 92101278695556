import React, { Component } from 'react'
import './UpdatePassword.scss'
import { withRouter } from 'react-router-dom'

import ContentBox from 'components/commons/ContentBox'
import Button from 'components/commons/Button'
import Input from 'components/commons/Input'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import SweetAlert from 'sweetalert2-react'

const UPDATE_PASSWORD_GQL = gql`
  mutation($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      success
      errors
    }
  }
`

class UpdatePassword extends Component {
  state = {
    showSuccess: false,
    password: '',
    password_confirmation: '',
    errors: []
  }

  submit(mutate) {
    this.setState({ errors: [] })
    const { password, password_confirmation } = this.state
    mutate({
      variables: {
        input: {
          password,
          password_confirmation
        }
      },
      update: (cache, { data: { updatePassword } }) => {
        if (!updatePassword.success) {
          this.setState({
            errors: updatePassword.errors
          })
        } else {
          this.setState({ showSuccess: true })
        }
      }
    })
  }

  renderErrors(errors = []) {
    return errors.map((err, index) => (
      <div key={index} className="err-message">
        {err}
      </div>
    ))
  }

  renderSuccessBox() {
    return (
      <SweetAlert
        show={this.state.showSuccess}
        title="Success"
        type="success"
        text="Update password success"
        confirmButtonText="Go to User Profile"
        onConfirm={() =>
          this.setState({ showSuccess: false }, () => {
            this.props.history.push('/dashboard')
          })
        }
      />
    )
  }

  render() {
    return (
      <div className="UpdatePassword">
        <Mutation mutation={UPDATE_PASSWORD_GQL}>
          {(mutate, { loading }) => (
            <ContentBox title="Update Password">
              {this.renderErrors(this.state.errors)}
              <div className="box-inputs">
                <Input
                  className="input-password"
                  onChange={value => this.setState({ password: value })}
                  value={this.state.password}
                  inputHtml={{
                    name: 'password',
                    type: 'password',
                    placeholder: 'Enter new password',
                    autoFocus: true
                  }}
                />
                <Input
                  className="input-password-confirmation"
                  value={this.state.password_confirmation}
                  onChange={value =>
                    this.setState({
                      password_confirmation: value
                    })
                  }
                  inputHtml={{
                    name: 'password_confirmation',
                    type: 'password',
                    placeholder: 'Retype new password',
                    onKeyUp: event =>
                      event.key === 'Enter' && this.submit(mutate)
                  }}
                />
              </div>
              <Button
                cucumber="SubmitButton"
                className="btn btn-login"
                loading={loading}
                onClick={() => this.submit(mutate)}
              >
                Save
              </Button>
            </ContentBox>
          )}
        </Mutation>
        {this.renderSuccessBox()}
      </div>
    )
  }
}

export default withRouter(UpdatePassword)
