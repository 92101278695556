import React from 'react';
import config from 'config';
import axios from 'utils/axios';
import './index.scss';
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import { getLocalDate } from 'utils';
import * as ActiveStorage from 'activestorage';
ActiveStorage.start();

const toMb = bytes => parseFloat(bytes / (1024 * 1024)).toFixed(2);

class Documents extends React.Component {
  state = {
    width: window.innerWidth,
    propertyId: this.props.propertyId,
    files: [],
    categories: [],
    categoryActiveId: null,
    categoryActive: null,
    editingCategory: {
      name: '',
      id: null,
      parentId: null
    },
    updating: false,
    errors: {},
    // drag drop files
    progresses: {},
    filesEnter: false,
    tmpFiles: [],
    toggleBox: false,
    showDetailCategoryMobileForm: false
  };

  updateDimensions() {
    let showDetailCategoryMobileForm = this.state.showDetailCategoryMobileForm;
    if (this.state.width > 777) {
      showDetailCategoryMobileForm = false;
    }
    this.setState({ width: window.innerWidth, showDetailCategoryMobileForm });
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillMount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
    this.updateDimensions();
    this.loadCategories();
  }

  loadCategories(closeForm = false) {
    axios.get(`/api/property/${this.props.propertyId}/categories`).then(rs => {
      if (rs.data.result == 'ok') {
        this.setState(
          {
            categories: rs.data.data
          },
          () => {
            if (
              this.state.categoryActiveId == null &&
              rs.data.data.length > 0
            ) {
              let firstCategory = rs.data.data[0];
              this.setState({
                categoryActive: firstCategory,
                categoryActiveId: firstCategory.id
              });
            }
          }
        );
        if (closeForm) {
          PopupboxManager.close();
          this._updating = false;
        }
      }
    });
  }

  getExtensionAndName(fileName) {
    let lastIndexOfDot = fileName.lastIndexOf('.');
    if (lastIndexOfDot < 0) {
      lastIndexOfDot = fileName.length;
    }
    return {
      name: fileName.substring(0, lastIndexOfDot),
      extension: fileName
        .substring(lastIndexOfDot + 1, fileName.length)
        .toUpperCase()
    };
  }

  renderCategories(categories) {
    let _this = this;
    let categoriesDom = categories.map(cat => {
      return _this.renderCategoryItem(cat);
    });
    return <div className="category-items">{categoriesDom}</div>;
  }

  handleCategoryClick(cat) {
    this.setState({ categoryActiveId: cat.id, categoryActive: cat });
  }

  renderCategoryItem(category) {
    let activeClass =
      this.state.categoryActiveId == category.id ? 'active' : '';
    return (
      <div key={`category-${category.id}`} className="category-item-wraper">
        <div
          className={`category-item ${activeClass}${
            isMobile ? ' is-mobile' : ' is-not-mobile'
            }`}
          onClick={() => this.handleCategoryClick(category)}>
          <i className="mdi mdi-folder folder-icon" />
          <div
            className='category-name big'
            title={category.name}>
            {category.name}
          </div>
          <div
            className={`actions-wraper${isMobile ? ' is-mobile' : ''}`}>
            <div
              title="View Files"
              className="detail-icon-wraper"
              onClick={() => this.showDetailCategoryPopup(category)}>
              <i className="mdi mdi-eye detail-icon" />
            </div>
          </div>
        </div>
        {this.renderSubCategories(category)}
      </div>
    );
  }

  renderSubCategories(category) {
    if (category.children.length == 0) return null;
    let _this = this;
    let subCategories = category.children.map(cat => {
      return _this.renderCategoryItem(cat);
    });
    return <div className="subcategories-wraper">{subCategories}</div>;
  }

  handleChangeName(name) {
    let record = this.state.editingCategory;
    record.name = name;
    this.setState({
      editingCategory: record
    });
  }

  toastInvalidFiles(files) {
    if (files.length > 0) {
      files.map(f =>
        toast.error(
          `Large file is not allowed
					${f.name} - ${toMb(f.size)}Mb`
        )
      );
    }
  }


  renderDropzone() {
    let category = this.state.categoryActive;
    if (category == null) {
      return <div className="no-content-view">No Content</div>;
    }
    let files = category.files;
    let rows = this.renderTable(files, category);
    let isHasRow = files.length > 0 || category.children.length > 0;
    const { tmpFiles, filesEnter } = this.state;
    const disabled = this.state.uploading;
    return (
      <div className={`uploader-container${filesEnter ? ' active' : ''}`}>
        <div
          className={`overlay-dropzone${filesEnter ? ' active' : ''}${
            isHasRow ? '' : ' no-file'
            }`}>
          <i
            className="fa fa-spin fa-spinner fa-3x"
            style={{ display: disabled ? 'block' : 'none' }}
          />
        </div>
        <h3 className="category-name">
          {category.name}
          <div
            className="close-popup-icon"
            onClick={() => {
              this.setState({ showDetailCategoryMobileForm: false });
            }}>
            x
          </div>
        </h3>
        <div className="category-file-list">
          {rows}
        </div>
      </div>
    );
  }

  renderTable(files, cat) {
    let folderList = cat.children.map((child, index) => {
      return (
        <div
          key={`folder-${child.id}`}
          className={`item-row${index == 0 ? ' first-row' : ''}`}>
          <div className="cell type-cell">
            <i className="mdi mdi-folder folder-icon" />
          </div>
          <div className="cell file-name" title={child.name}>
            {child.name}
          </div>
          <div className="cell size-cell">{toMb(this.calTotalSizeOfFolder(child))} MB</div>
          <div className="cell date-cell">{getLocalDate(child.updated_at, 'date')}</div>
          <div className="cell actions"></div>
        </div>
      );
    });
    let fileList = files.map((file, index) => {
      let nameAndExt = this.getExtensionAndName(file.name);
      return (
        <div
          key={`file-${file.signed_id}`}
          className={`item-row${index == 0 && cat.children.length == 0 ? " first-row" : ""}`}
        >
          <div className="cell type-cell">{nameAndExt.extension}</div>
          <div className="cell file-name" title={nameAndExt.name}>
            {nameAndExt.name}
          </div>
          <div className="cell size-cell">{toMb(file.size)} MB</div>
          <div className="cell date-cell">{getLocalDate(file.uploaded_at, "date")}</div>
          <div className="cell actions">
            <a
              className="download-icon"
              href={`${config.backend_host}/api/download-file/${file.signed_id}`}
              download
              title="Download File"
            >
              <i className="mdi mdi-arrow-down-bold-box" />
            </a>
          </div>
        </div>
      )
    });
    if (files.length + cat.children.length == 0) {
      return (
        <div className='no-content-wraper'>
          No content
        </div>
      )
    }
    return (
      <div
        className={`category-file-table${
          files.length + cat.children.length > 0 ? '' : ' hidden'
          }`}>
        <div className="tbl-header item-row">
          <div className="cell type-cell">Type</div>
          <div className="cell file-name">Name</div>
          <div className="cell size-cell">File Size</div>
          <div className="cell date-cell">Uploaded</div>
          <div className="cell actions">Actions</div>
        </div>
        {folderList}
        {fileList}
      </div>
    );
  }

  calTotalSizeOfFolder(cat) {
    let rs = 0;
    cat.files.forEach(file => {
      rs += file.size;
    });
    cat.children.forEach(child => {
      rs += this.calTotalSizeOfFolder(child);
    });
    return rs;
  }

  showDetailCategoryPopup(category) {
    this.setState({
      categoryActiveId: category.id,
      categoryActive: category,
      showDetailCategoryMobileForm: true
    });
  }

  render() {
    let categories = this.state.categories;
    const { filesEnter, showDetailCategoryMobileForm } = this.state;
    if (categories.length == 0) {
      return (
        <div
          className="flex grow-1 jc-center al-center"
          style={{ height: '40px', marginTop: '10px' }}>
          No content</div>
      )
    }

    return (
      <div className="document-view-commponent">
        <input
          type="input"
          ref={node => (this.tempInput = node)}
          style={{ height: 0, width: 0, position: 'fixed', left: '-100px' }}
        />
        <PopupboxContainer />
        <div className="category-list">
          <div className={`category-tree${isMobile ? ' is-mobile' : ''}`}>
            <div className="scroll-wraper">
              {this.renderCategories(categories)}
            </div>
          </div>

          <div
            className={`view-desktop category-detail${
              filesEnter ? ' file-entered' : ''
              }${isMobile ? ' is-mobile' : ''}`}>
            {this.renderDropzone()}
          </div>
          <div
            className={`view-mobile category-detail${
              filesEnter ? ' file-entered' : ''
              }`}
            style={{ display: showDetailCategoryMobileForm ? 'flex' : 'none' }}>
            {this.renderDropzone()}
            <div className="detail-category-overlay" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Documents);
