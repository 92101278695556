import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Property from 'components/Property';

class EditPropertyPage extends Component {
  render() {
    return (
      <div className="CreatePropertyPage">
        <Property propertyId={this.props.match.params['id']} />
      </div>
    );
  }
}

export default withRouter(EditPropertyPage);
