import React, { Component } from "react";
import "./SignInBox.scss";
import { withRouter } from "react-router-dom";
import userAuth from "utils/userAuth";
import ContentBox from "components/commons/ContentBox";
import Button from "components/commons/Button";
import Input from "components/commons/Input";
import { Link } from "react-router-dom";
import { Mutation, withApollo } from "react-apollo";
import { SIGN_IN_GQL } from "queries/sign_in";
import ReactHtmlParser from "react-html-parser"

class SignInBox extends Component {
  state = {
    email: "",
    password: "",
    errors: []
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.token && this.props.token !== nextProps.token) {
      this.handleSignInByToken(nextProps.token)
    }
  }
  componentDidMount() {
    if (this.props.token) {
      this.handleSignInByToken(this.props.token)
    }
  }

  handleSignInByToken(token) {
    this.props.client.mutate({
      mutation: SIGN_IN_GQL,
      variables: {
        input: {
          token,
        },
      },
      update: (cache, { data: { signIn } }) => {
        if (signIn.errors && signIn.errors.length > 0) {
          this.setState({
            errors: signIn.errors,
          })
        } else {
          userAuth.setAuth({
            auth_token: token,
            user_profile: Object.assign({}, signIn.customer, signIn.customer.customer_profile),
          })
          this.props.history.replace("/dashboard")
        }
      },
    })
  }

  submitLogin(mutate) {
    this.setState({ errors: [] });
    const { email, password } = this.state;
    mutate({
      variables: {
        input: {
          email,
          password
        }
      },
      update: (cache, { data: { signIn } }) => {
        if (signIn.errors && signIn.errors.length > 0) {
          this.setState({
            errors: signIn.errors
          });
        } else {
          userAuth.setAuth({
            auth_token: signIn.token,
            user_profile: Object.assign(
              {},
              signIn.customer,
              signIn.customer.customer_profile
            )
          });

          if (userAuth.needToAcceptTnc()) {
            this.props.history.push("/terms-and-conditions");
          } else {
            this.props.history.push("/dashboard");
          }
        }
      }
    });
  }

  renderErrors(errors = []) {
    return (
      <div className="error-container">
        {errors.map((err, index) => (
          <div key={index} className="err-message">
            {ReactHtmlParser(err)}
          </div>
        ))}
      </div>
    )
  }

  render() {
    return (
      <div className="SignInBox">
        <Mutation mutation={SIGN_IN_GQL}>
          {(mutate, { loading }) => (
            <ContentBox>
              {this.renderErrors(this.state.errors)}
              <div className="box-inputs">
                <Input
                  className="input-email"
                  onChange={(value) => this.setState({ email: value })}
                  value={this.state.email}
                  inputHtml={{
                    name: "email",
                    type: "email",
                    placeholder: "Email Address",
                    autoFocus: true,
                    onKeyUp: (event) => {
                      if (event.key === "Enter") this.submitLogin(mutate);
                    },
                  }}
                />
                <Input
                  className="input-password"
                  onChange={(value) => this.setState({ password: value })}
                  value={this.state.password}
                  inputHtml={{
                    name: "password",
                    type: "password",
                    placeholder: "Password",
                    onKeyUp: (event) => {
                      if (event.key === "Enter") this.submitLogin(mutate);
                    },
                  }}
                />
              </div>
              <Link className="forgot-password-link" to="/forgot-password">
                Forgot your password?
              </Link>
              <button type="submit" className="ThemeButton" disabled={loading} onClick={() => this.submitLogin(mutate)}>
                Log In
              </button>
              <span
                className="email-support"
                style={{
                  fontSize: 16,
                  textAlign: "center",
                  fontWeight: 100,
                  marginTop: 20,
                  width: "100%",
                }}
              >
                New user? Sign up for free
                <Link to="/sign-up">
                  {" "}
                  <span style={{ textDecoration: "underline", color: "#ac2430" }}>{"here"}</span>{" "}
                </Link>
              </span>
            </ContentBox>
          )}
        </Mutation>
      </div>
    );
  }
}

export default withRouter(withApollo(SignInBox));
