import React, { Component } from 'react'
import PaymentHistory from 'components/PaymentHistory'

class PaymentHistoryPage extends Component {
  render() {
    return (
      <div className="PaymentHistoryPage">
        <div className="title">PAYMENT HISTORY</div>
        <PaymentHistory />
      </div>
    );
  }
}

export default PaymentHistoryPage
