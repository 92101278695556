import gql from "graphql-tag";

export const GET_SHARED_CUSTOMERS_GQL = gql`
  query GetSharedCustomers {
    shared_customers {
      email
      name
      avatar_url
      shared_properties {
        id
        name
      }
    }
    properties(offset: 0, limit: 99999, ownedType: "owned") {
      id
      name
      pending_invitations {
        id
        email
      }
    }
  }
`


export const UPDATE_SHARED_PROPERTY_GQL = gql`
  mutation UpdateSharedProperty($input: UpdateSharedPropertyInput!) {
    update_shared_property(input: $input) {
      success
      message
    }
  }
`