import React from 'react';
import { Route } from 'react-router-dom';
import { zoomNormal } from 'utils';

export default class PdfViewLayout extends React.Component {
  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={matchProps => {
          zoomNormal();
          return (
            <div className="PdfViewLayout" id="pdf-layout">
              <div className="PageContentPdf">
                <Component {...matchProps} />
              </div>
            </div>
          );
        }}
      />
    );
  }
}
