import React, { Component } from "react"
import "./Privacy.scss"
import { withRouter } from "react-router-dom"
import { withApollo } from "react-apollo"
import gql from "graphql-tag"

export const GET_PRIVACY_GQL = gql`
  query {
    privacy {
      content
    }
  }
`

class Privacy extends Component {
  state = {
    loading: true,
    content: "",
  }

  componentDidMount() {
    this.loadContent()
  }

  loadContent() {
    this.props.client.query({
      query: GET_PRIVACY_GQL,
    }).then(({ data }) => {
      if (data) {
        this.setState({ loading: false, content: data.privacy.content })
      }
    })
  }

  renderError() {
    return <p>Error!!! Can't load content. Please contact our admin for more details.</p>
  }

  render() {
    return (
      <div className="Privacy">
        <h2>Privacy</h2>
        {this.state.loading ? (
          <div className="text-center">
            <h2>Loading...</h2>
            <br />
            <br />
          </div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
        )}
      </div>
    )
  }
}

export default withRouter(withApollo(Privacy))
