import React, { Component } from 'react'
import UpdatePassword from 'components/UpdatePassword'

class ChangePasswordpage extends Component {


  render() {
    return (
      <div className="ChangePasswordpage">
        <UpdatePassword />
      </div>
    )
  }
}

export default ChangePasswordpage
