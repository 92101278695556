import React, { Component } from 'react';
import PropertyPageHeader from 'components/PropertyPageHeader';
import ExteriorTour from './ExteriorTour';
import InteriorTour from './InteriorTour';
import PropertyTourBook from './PropertyTourBook';
import Documents from './Documents';
import BasicInformation from 'components/Property/BasicInformation';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import { REMOVE_PROPERTY_GQL } from './BasicInformation/queries';
import SweetAlert from 'sweetalert2-react';
import './Property.scss';
import config from 'config'

const GET_PROPERTY_PERMISSION_GQL = gql`
  query ($id: ID!) {
    property(id: $id) {
      permissions
      total_attachments_byte_size
    }
  }
`
class Property extends Component {
  state = {
    propertyId: this.props.propertyId,
    currentIndex: 0,
  }

  nextSection() {
    let index = this.state.currentIndex + 1
    const trails = this.getSectionTrails()
    index = index >= trails.length ? trails.length - 1 : index
    this.setState({
      currentIndex: index,
    })
  }

  previousSection() {
    let index = this.state.currentIndex - 1
    index = index > 0 ? index : 0
    this.setState({
      currentIndex: index,
    })
  }

  getSections(total_attachments_byte_size) {
    return [BasicInformation, ExteriorTour, InteriorTour, PropertyTourBook, Documents].map((SectionComponent) => (
      <SectionComponent
        propertyId={this.state.propertyId}
        onSaveSuccess={(propertyId) => {
          this.state["propertyId"] = propertyId
          this.nextSection()
        }}
        onFinish={(propertyId) => {
          this.props.history.push(`/property/${propertyId}`)
        }}
        remaining_bytes={config.MAXIMUM_BYTES_PER_PROPERTY - (total_attachments_byte_size || 0)}
        proceedToNextSection={() => this.nextSection()}
        backToPreviousSection={() => this.previousSection()}
      />
    ))
  }

  viewOnly(permissions) {
    if (!this.state.propertyId) return false
    return permissions == "can_view"
  }

  canRemove(permissions) {
    if (!this.state.propertyId) return false
    return permissions == "full_control"
  }

  getSectionTrails() {
    const disabled = !this.state.propertyId
    return [
      { text: "Basic Information" },
      { text: "Exterior", disabled },
      { text: "Interior", disabled },
      { text: "Presentations & Brochures", disabled },
      { text: "Documents & Data", disabled },
    ]
  }

  getPageHeaderTrails() {
    if (this.props.propertyId) {
      return [
        { to: `/property/${this.props.propertyId}`, text: "Property" },
        { to: `/property/${this.props.propertyId}/edit`, text: "Edit" },
      ]
    } else {
      return [{ to: "/create-property", text: "Add Property" }]
    }
  }

  renderRemovePropertyButton(permissions) {
    if (!this.canRemove(permissions)) return null
    const propertyId = this.state.propertyId
    return (
      <div className="RemoveProperty">
        <span className="connector"> | </span>
        <div
          title="Delete Property"
          className="remove-property-button"
          onClick={() => this.setState({ showRemoveProperty: true })}
        >
          <i className="mdi mdi-delete mdi-24px" />
          {
            <Mutation mutation={REMOVE_PROPERTY_GQL}>
              {(mutate) => (
                <SweetAlert
                  show={this.state.showRemoveProperty}
                  title="Warning"
                  type="warning"
                  text="Are you sure you want to delete this property?"
                  confirmButtonText="Yes"
                  showCancelButton
                  cancelButtonText="No"
                  onConfirm={() =>
                    mutate({
                      variables: { input: { id: propertyId } },
                      update: (cache, { data: { removeProperty } }) => {
                        if (removeProperty.success) {
                          this.setState({ showRemoveProperty: false }, () => this.props.history.push("/dashboard"))
                        } else {
                          this.setState({ showRemoveProperty: false }, () => {
                            toast.error(removeProperty.errors["base"])
                          })
                        }
                      },
                    })
                  }
                  onCancel={() => this.setState({ showRemoveProperty: false })}
                />
              )}
            </Mutation>
          }
        </div>
      </div>
    )
  }

  getCurrentSection(total_attachments_byte_size) {
    return this.getSections(total_attachments_byte_size)[this.state.currentIndex]
  }

  render() {
    if (this.state.propertyId) {
      return (
        <Query query={GET_PROPERTY_PERMISSION_GQL} variables={{ id: this.state.propertyId }}>
          {({ data }) => {
            const { property = {},  } = data
            const { permissions = "can_view", total_attachments_byte_size } = property
            return (
              <div className="Property">
                {this.viewOnly(permissions) ? null : (
                  <PropertyPageHeader
                    trails={this.getSectionTrails()}
                    currentIndex={this.state.currentIndex}
                    onClick={(index) => this.setState({ currentIndex: index })}
                    suffixComponent={this.renderRemovePropertyButton(permissions)}
                  />
                )}
                {this.getCurrentSection(total_attachments_byte_size || 0)}
              </div>
            )
          }}
        </Query>
      )
    } else {
      return (
        <div className="Property">
          <PropertyPageHeader
            trails={this.getSectionTrails()}
            currentIndex={this.state.currentIndex}
            onClick={(index) => this.setState({ currentIndex: index })}
          />
          {this.getCurrentSection()}
        </div>
      )
    }
  }
}

export default withRouter(Property);
