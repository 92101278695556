import React, { Component } from 'react';
import CollapseBox from 'components/commons/CollapseBox';
import "./CollapseList.scss"

class CollapseList extends React.Component {

  renderContent() {
    return this.props.data.map((item, index) => {
      return (
        <CollapseBox
          key={index}
          title={item.title}
          show={item.active}
          activeBackgroundColor="#ffffff"
          inactiveBackgroundColor="#ffffff"
          id={item.id}
          colorText="#15254c"
          style={{ borderBottom: '1px solid #15254c' }}>
          {item.content}
        </CollapseBox>
      );
    });
  }
  render() {
    return <div className="CollapseList">{this.renderContent()}</div>;
  }
}
export default CollapseList;
