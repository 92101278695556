import React, { Component } from 'react';
import UpdatePassword from 'components/UpdatePassword';

class UpdatePasswordPage extends Component {
  render() {
    return (
      <div className="UpdatePasswordPage">
        <UpdatePassword />
      </div>
    );
  }
}

export default UpdatePasswordPage;
