import React from 'react';
import './ExteriorTour.scss';
import { Mutation, Query, withApollo } from "react-apollo"
import gql from 'graphql-tag';
import FooterSection from '../FooterSection';
import DropzoneUploader from '../DropzoneUploader';
import Input from 'components/commons/Input';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import config from 'config'
import  NewFolderButton  from './NewFolderButton';
import NavBar from './NavBar';
import withReactContent from "sweetalert2-react-content"
import swal from "sweetalert2"

export const UPLOAD_ASSET_GQL = gql`
  mutation($input: UploadAssetInput!) {
    uploadAsset(input: $input) {
      success
      errors
    }
  }
`;

export const GET_EXTERIOR_ASSET_GQL = gql`
  query ($id: ID!, $photo_type: Photo) {
    property(id: $id) {
      id
      total_attachments_byte_size
      exterior_tour {
        url
        assets(photo_type: $photo_type) {
          id
          size
          title
          thumbnail
          asset_type
          photo_type
          file_type
          asset_id
        }
      }
    }
  }
`

export const REMOVE_ASSET_GQL = gql`
  mutation($input: RemoveAssetInput!) {
    removeAsset(input: $input) {
      success
      errors
    }
  }
`;

export const MOVE_ASSET_GQL = gql`
  mutation($input: MoveAssetInput!) {
    moveAsset(input: $input) {
      success
      errors
    }
  }
`;

const UPDATE_TITLE_GQL = gql`
  mutation($input: UpdateAssetTitleInput!) {
    updateAssetTitle(input: $input) {
      success
      errors
    }
  }
`;

const UPDATE_EXTERIOR_TOUR = gql`
  mutation($input: UpdateExteriorTourInput!) {
    updateExteriorTour(input: $input) {
      success
      errors
    }
  }
`;

class ExteriorTour extends React.Component {
  static refetch = () => {}

  state = {
    propertyId: this.props.propertyId,
    photoType: "normal",
    url: "",
    instructionText: {
      photo_360: isMobile
        ? "Click here to upload 360 degree images"
        : "Upload or Drag and drop your 360 degree images here",
      normal: isMobile ? "Click here to upload file or take photo/video" : "Upload or Drag and drop your Files here",
    },
    currentFolder: null,
  }

  handleUploadFinish(mutate, fileBlobs, propertyId) {
    const signed_ids = fileBlobs.map((e) => e["signed_id"])
    mutate({
      variables: {
        input: {
          asset_id: this.state.currentFolder ? this.state.currentFolder.id : null,
          property_id: propertyId,
          photo_type: this.state.photoType,
          container_type: "exterior_tour",
          signed_ids,
        },
      },
      update: (cache, { data: { uploadAsset } }) => {
        if (!uploadAsset.success) {
          this.setState({ errors: uploadAsset.errors })
        } else {
          this.refetch()
        }
      },
    })
  }

  handleRemovePhoto(removeMutate, photo) {
    if (!photo.id) return
    removeMutate({
      variables: {
        input: { id: photo.id, container_type: "exterior_tour" },
      },
      update: () => {
        this.refetch()
      },
    })
  }

  resolveFileData(files) {
    if (this.state.currentFolder) {
      return files
        .filter((f) => String(f.asset_id) == String(this.state.currentFolder.id))
        .sort((a, b) => {
          let check = ["file", "directory"].indexOf(b.file_type) - ["file", "directory"].indexOf(a.file_type)
          if (check === 0) return a.title < b.title ? -1 : 1
          return check
        })
    } else {
      return files
        .filter((f) => !f.asset_id)
        .sort((a, b) => {
          let check = ["file", "directory"].indexOf(b.file_type) - ["file", "directory"].indexOf(a.file_type)
          if (check === 0) return a.title < b.title ? -1 : 1
          return check
        })
    }
  }

  getFolderNames(files) {
    if (this.state.currentFolder) {
      return files.filter(
        (f) => f.file_type === "directory" && String(f.asset_id) == String(this.state.currentFolder.id),
      )
    } else {
      return files.filter((f) => f.file_type === "directory" && !f.asset_id)
    }
  }

  renderTabButtons(assets) {
    return (
      <div className="filter-tab-buttons">
        <div
          className={`tab${this.state.photoType == "normal" ? " active" : ""}`}
          onClick={() => this.setState({ photoType: "normal", currentFolder: null })}
        >
          Normal
        </div>
        <div
          className={`tab${this.state.photoType == "photo_360" ? " active" : ""}`}
          onClick={() => this.setState({ photoType: "photo_360", currentFolder: null })}
        >
          Photo 360
        </div>
        <NewFolderButton
          folders={this.getFolderNames(assets)}
          photoType={this.state.photoType}
          propertyId={this.state.propertyId}
          containerType="exterior_tour"
          assetId={this.state.currentFolder ? this.state.currentFolder.id : null}
          onCreated={() => this.refetch()}
        />
      </div>
    )
  }

  renderUrlLinkSection(url) {
    return (
      <Input
        value={this.state.url}
        defaultValue={url}
        className="url-link-input"
        onChange={(value) => {
          this.state.url = value
          this.setState({ showSave: true })
        }}
        label="Virtual Tour Link (must be https)"
      />
    )
  }

  validateUrl() {
    const { url } = this.state
    if (url) {
      if (url.indexOf("https://") !== 0) {
        toast.error("Virtual Tour Link must be https link")
        return false
      }
    }

    return true
  }

  handleMoveFile(file, assets) {
    withReactContent(swal)
      .fire({
        title: "Moving File",
        html: this.renderFolderBrowser(file, assets),
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "Close",
      })
      .then((result) => {
        if (result.value) {
          this.props.handleRemoveFile(file)
        }
      })
  }

  getFolderTree(folders, tree) {
    if (tree.id) {
      tree.children = folders.filter((f) => f.asset_id == tree.id)
    } else {
      tree.children = folders.filter((f) => !f.asset_id)
    }

    tree.children.forEach((child) => {
      this.getFolderTree(folders, child)
    })

    return tree
  }

  renderFolderBrowser(file, assets) {
    let folders = assets.filter((f) => f.file_type === "directory")
    if (file.file_type === 'directory') {
      folders = folders.filter(f => f.id !== file.id)
    }

    let tree = {
      id: null,
      title: "Home",
      children: [],
    }
    tree = this.getFolderTree(folders, tree)

    const client = this.props.client
    const propertyId = this.state.propertyId
    const refetch = this.refetch

    function callback(folder) {
      console.log(`Moving ${file.title} to ${folder.title}`);
      if (String(folder.id) === String(file.asset_id)) {
        toast.error("Cannot move to same folder")
        swal.close()
        return
      }

      if (file.file_type === 'directory') {
        const existed = (folder.children || []).find(f => f.title === file.title)
        if (existed) {
          toast.error("Cannot move to folder that contains same folder name")
          swal.close()
          return
        }
      }

      client.mutate({
        mutation: MOVE_ASSET_GQL,
        variables: {
          input: {
            id: file.id,
            asset_id: folder.id,
            property_id: propertyId,
          },
        },
        update: (store, { data: { moveAsset } }) => {
          swal.close()
          refetch()
        },
      })
    }

    return (
      <div className="folder-item root">
        <div className="folder-title" onClick={() => callback(tree)}>
          Home
        </div>
        <div className="folder-list">
          {tree.children.map((f, index) => (
            <React.Fragment key={index}>{this.renderFolderItem(f, callback)}</React.Fragment>
          ))}
        </div>
      </div>
    )
  }

  renderFolderItem(file, callback) {
    return (
      <div className="folder-item">
        <div className="folder-title" onClick={() => callback(file)}>
          {file.title}
        </div>
        <div className="folder-list">
          {(file.children || []).map((f, index) => (
            <React.Fragment key={index}>{this.renderFolderItem(f, callback)}</React.Fragment>
          ))}
        </div>
      </div>
    )
  }

  render() {
    const { propertyId, photoType, currentFolder } = this.state
    const queryProps = {
      query: GET_EXTERIOR_ASSET_GQL,
      variables: { id: propertyId, photo_type: photoType },
    }

    return !propertyId ? (
      <div className="err-message">Create Property first</div>
    ) : (
      <div className={`ExteriorTour InteriorTour${isMobile ? " is-mobile" : ""}`}>
        <div className="header-section">
          <div className="title">Asset Exterior</div>
        </div>
        <Query {...queryProps}>
          {({ loading: queryLoading, error, data, refetch }) => {
            if (queryLoading || error) return null
            this.refetch = refetch
            const { exterior_tour, total_attachments_byte_size = 0 } = data.property
            const { assets = [], url } = exterior_tour || {}
            const remaining_bytes = config.MAXIMUM_BYTES_PER_PROPERTY - (total_attachments_byte_size || 0)
            return (
              <Mutation mutation={UPLOAD_ASSET_GQL}>
                {(uploadPhotoMutate, { loading: uploadLoading, error, data }) => {
                  return (
                    <Mutation mutation={REMOVE_ASSET_GQL}>
                      {(removeMutate, { loading: removeLoading }) => (
                        <div>
                          {this.renderTabButtons(assets)}
                          <NavBar
                            assets={assets}
                            currentFolder={currentFolder}
                            onChangeFolder={(folder) => this.setState({ currentFolder: folder })}
                          />
                          <DropzoneUploader
                            onSelectFolder={(folder) => this.setState({ currentFolder: folder })}
                            onMovingFile={(file) => this.handleMoveFile(file, assets)}
                            refetch={this.refetch}
                            UPDATE_TITLE_GQL={UPDATE_TITLE_GQL}
                            listFileQueryProps={queryProps}
                            propertyAssetKey={"exterior_tour"}
                            disabled={queryLoading || uploadLoading || removeLoading}
                            remaining_bytes={remaining_bytes}
                            maximumSize={config.maxFileSizeUpload}
                            instructionText={this.state.instructionText[photoType]}
                            initialFiles={this.resolveFileData(assets)}
                            accept={
                              this.state.photoType == "normal" ? "image/*,video/*,.pdf,.mp4,.m4v" : "image/*,.zip"
                            }
                            modifyItemClass={(file) => file["photo_type"]}
                            onUploadFinish={(fileBlobs) =>
                              this.handleUploadFinish(uploadPhotoMutate, fileBlobs, propertyId)
                            }
                            handleRemoveFile={(file) => this.handleRemovePhoto(removeMutate, file)}
                          />
                          <div className={`help${photoType == "photo_360" ? " hidden" : ""}`}>
                            Note: videos may take up to an hour to upload.
                          </div>
                          {this.renderUrlLinkSection(url)}
                          <Mutation mutation={UPDATE_EXTERIOR_TOUR}>
                            {(updateUrlMutate, { updateUrlLoading }) => (
                              <FooterSection
                                disabled={uploadLoading || queryLoading || removeLoading || updateUrlLoading}
                                doFinish={() => {
                                  if (this.state.showSave)
                                    this.validateUrl() &&
                                      updateUrlMutate({
                                        variables: {
                                          input: {
                                            property_id: propertyId,
                                            url: (this.state.url || "").trim(),
                                          },
                                        },
                                        update: () => {
                                          this.props.history.push(`/property/${this.props.propertyId}`)
                                        },
                                      })
                                  else this.props.history.push(`/property/${this.props.propertyId}`)
                                }}
                                doNext={() => {
                                  if (this.state.showSave)
                                    this.validateUrl() &&
                                      updateUrlMutate({
                                        variables: {
                                          input: {
                                            property_id: propertyId,
                                            url: (this.state.url || "").trim(),
                                          },
                                        },
                                        update: () => {
                                          this.props.proceedToNextSection()
                                        },
                                      })
                                  else this.props.proceedToNextSection()
                                }}
                                doBack={() => this.props.backToPreviousSection()}
                              />
                            )}
                          </Mutation>
                        </div>
                      )}
                    </Mutation>
                  )
                }}
              </Mutation>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default withRouter(withApollo(ExteriorTour));
