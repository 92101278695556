import React from 'react';
import Button from 'components/commons/Button';
import DropdownMenu from 'react-dd-menu';

const directionIconClass = {
  ASC: 'fa fa-caret-down',
  DESC: 'fa fa-caret-up'
};

export default class SortProperty extends React.Component {
  state = {
    key: '',
    direction: 'ASC',
    isMenuOpen: false
  };

  static defaultProps = {
    onSelected: () => {}
  };

  getSortList() {
    return [
      {
        value: 'created_at',
        label: 'Most Recent'
      },
      {
        value: 'name',
        label: 'Property Name'
      },
      {
        value: 'market',
        label: 'Market'
      },
      {
        value: 'owner',
        label: 'Owner'
      },
      {
        value: 'country',
        label: 'Country'
      },
      {
        value: 'loan',
        label: 'Loan'
      },
      {
        value: 'deal_name',
        label: 'Deal Name'
      },
      {
        value: 'property_type',
        label: 'Property Type'
      },
      {
        value: 'favorite',
        label: 'Favorites'
      },
      {
        value: 'creator_id',
        label: 'Shared By'
      }
    ].filter(e => !!e);
  }

  getSortLabel() {
    const sort = this.getSortList().find(e => e['value'] == this.state.key);
    return sort ? `Sort By ${sort['label']}` : 'Sort By';
  }

  getSortByButton() {
    return (
      <Button
        className="btn btn-dark-blue flex space-between sort-by-button"
        style={{ padding: 10, minWidth: 160 }}
        onClick={() => this.setState({ isMenuOpen: !this.state.isMenuOpen })}>
        <span style={{ width: '20px' }} />
        <span className="sort-text">{this.getSortLabel()}</span>
        <i
          className={`sort-icon ${directionIconClass[this.state.direction]}`}
        />
      </Button>
    );
  }

  render() {
    return (
      <DropdownMenu
        close={() => this.setState({ isMenuOpen: false })}
        isOpen={this.state.isMenuOpen}
        toggle={this.getSortByButton()}
        closeOnInsideClick={false}
        menuAlign="left"
        textAlign="left">
        {this.getSortList().map((e, i) => {
          const selectedClass = e['value'] == this.state.key ? ' selected' : '';
          return (
            <li key={i}>
              <div
                onClick={() => {
                  if (e['value'] == this.state.key) {
                    this.setState(
                      {
                        direction:
                          this.state.direction == 'ASC' ? 'DESC' : 'ASC',
												isMenuOpen: false
                      },
                      () =>
                        this.props.onSelected({
                          [this.state.key]: this.state.direction
                        })
                    );
                  } else {
                    this.setState(
                      {
                        key: e['value'],
												isMenuOpen: false
                      },
                      () =>
                        this.props.onSelected({
                          [this.state.key]: this.state.direction
                        })
                    );
                  }
                }}>
                <div
                  className={`sort-option flex row space-between${selectedClass}`}>
                  <span>{e['label']}</span>
                  <i className={directionIconClass[this.state.direction]} />
                </div>
              </div>
            </li>
          );
        })}
      </DropdownMenu>
    );
  }
}
