import { getSubDomains } from "utils"

const configs = {
  development: () => {
    const hostname = "lvh.me"
    const subdomains = getSubDomains(hostname)
    const frontend_host = `http://${document.location.hostname}:5000`
    const backend_host = `http://a.${hostname}:3000`

    return {
      host: `http://${hostname}:4000`,
      frontend_host: frontend_host,
      subdomain: subdomains[0],
      direct_upload_url: `${backend_host}/rails/active_storage/direct_uploads`,
      graphql_endpoint: `${backend_host}/graphql`,
      backend_host: backend_host,
      google_api_key: "AIzaSyAZ8wWKFz7aJiVOOmLN6iPjOD3Im1aN-00",
      maxFileSizeUpload: 2 * 1024 * 1024 * 1024,
      MAXIMUM_BYTES_PER_PROPERTY: 10 * 1024 * 1024 * 1024,
      // MAXIMUM_BYTES_PER_PROPERTY: 4 * 1024 * 1024,
    }
  },

  staging: () => {
    const hostname = "assetbase.projectwebby.com"
    const subdomains = getSubDomains(hostname)
    const frontend_host = `https://${document.location.hostname}`
    const backend_host = `https://admin.${hostname}`

    return {
      host: `https://${hostname}`,
      frontend_host: frontend_host,
      subdomain: subdomains[0],
      direct_upload_url: `${backend_host}/rails/active_storage/direct_uploads`,
      graphql_endpoint: `${backend_host}/graphql`,
      backend_host: backend_host,
      google_api_key: "AIzaSyC-CfooWPG--a9DYBzzPxJW-twnleDWNno",
      maxFileSizeUpload: 2 * 1024 * 1024 * 1024,
      MAXIMUM_BYTES_PER_PROPERTY: 10 * 1024 * 1024 * 1024,
    }
  },

  production: () => {
    const hostname = "app.assetbase.com.sg"
    const subdomains = getSubDomains(hostname)
    const frontend_host = `https://${document.location.hostname.replace("www.", "")}`
    const backend_host = `https://admin.assetbase.com.sg`;

    return {
      host: `https://${hostname}`,
      frontend_host: frontend_host,
      subdomain: subdomains[0],
      direct_upload_url: `${backend_host}/rails/active_storage/direct_uploads`,
      graphql_endpoint: `${backend_host}/graphql`,
      backend_host: backend_host,
      google_api_key: "AIzaSyC-CfooWPG--a9DYBzzPxJW-twnleDWNno",
      maxFileSizeUpload: 2 * 1024 * 1024 * 1024,
      MAXIMUM_BYTES_PER_PROPERTY: 10 * 1024 * 1024 * 1024,
    }
  }
}

const config = configs[process.env.REACT_APP_HOST_ENV || "development"]()

export default config
