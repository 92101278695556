import React, { Component } from "react";
import Legal from "components/Legal";

class LegalPage extends Component {
  render() {
    return (
      <div className="LegalPage">
        <Legal />
      </div>
    )
  }
}

export default LegalPage;
