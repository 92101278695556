import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import config from 'config';
import userAuth from 'utils/userAuth';
import { onError } from 'apollo-link-error';
// import { toast } from 'react-toastify';

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: userAuth.getAuth().user_auth_token || null
    }
  });
  return forward(operation);
});

const linkErrorHandler = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      if (message.indexOf('Not authorized to access') > -1) {
        window.location = '/sign-out';
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export default () =>
  new ApolloClient({
    uri: config.graphql_endpoint,
    cache: new InMemoryCache({ addTypename: false }),
    link: ApolloLink.from([
      authMiddleware,
      linkErrorHandler,
      createUploadLink({ uri: config.graphql_endpoint })
    ])
  });
