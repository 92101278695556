import React, { Component } from "react"
import "./UserSubscription.scss"
import { withRouter } from "react-router-dom"
import { Mutation, Query, withApollo } from "react-apollo"
import {
  GET_USER_SUBSCRIPTION_GQL,
  CHANGE_SUBSCRIPTION_PLAN_GQL,
  UPGRADE_SUBSCRIPTION_GQL,
  PREVIEW_CHANGE_SUBSCRIPTION_GQL,
  CANCEL_SUBSCRIPTION_GQL,
} from "queries/subscription"
import SweetAlert from "sweetalert2-react"
import { PopupboxManager, PopupboxContainer } from "react-popupbox"
import PaymentBox from "./PaymentBox"
import moment from "moment"
import { toast } from 'react-toastify';
import _ from "lodash"

const planColors = ["#fafafa", "#eeeeee", "#e2e2e2", "#d5d5d5"]

function isChangeablePlan(currentPlan, targetPlan) {
  if (!currentPlan) return true
  const { property_count, shared_user_count } = currentPlan

  return targetPlan.property_capability >= property_count && targetPlan.invite_user_capability >= shared_user_count
}

class UserSubscription extends Component {
  state = {
    showConfirm: false,
    selectingPlan: null,
    confirmAction: "change",
    loading: false,
    sweetAlertState: {
      open: false,
    },
  }
  renderLoading() {
    return <div>Loading...</div>
  }

  renderError() {
    return <div>Unexpected error happen!</div>
  }

  renderEmpty() {
    return <div>There is no content</div>
  }

  handleUpgradeOrDowngradePlan(plan, current_subscription, action) {
    if (this.state.loading) return false

    this.setState({ loading: true }, () => {
      if (!current_subscription) {
        const content = (
          <PaymentBox
            mutation={UPGRADE_SUBSCRIPTION_GQL}
            subscription_plan={plan}
            uniqId={Date.now()}
            onSubmit={this.handleUpgradeSubscription.bind(this)}
          />
        )
        PopupboxManager.open({
          content: content,
          config: {
            titleBar: {
              text: "Subscribe subscription plan",
              enable: true,
              closeButton: true,
            },
            fadeIn: true,
            fadeInSpeed: 200,
            onClosed: () =>
              this.setState({ selectingPlan: null, confirmAction: "change", loading: false }, () => {
                console.log("Closed")
              }),
          },
        })
        return
      }

      this.props.client.mutate({
        mutation: PREVIEW_CHANGE_SUBSCRIPTION_GQL,
        variables: { input: { subscription_plan_id: plan.id } },
        update: (cache, { data: { preview_change_subscription } }) => {
          if (preview_change_subscription.success) {
            const { changes } = preview_change_subscription
            this.setState({
              selectingPlan: plan,
              showConfirm: true,
              confirmAction: action,
              changeAmount: changes.amount,
              activeDiscount: changes.active_discount,
              current_subscription,
            })
          }
        },
      })
    })
  }

  handleCancelSubscriptionPlan(current_subscription, refetch) {
    this.setState({
      sweetAlertState: {
        open: true,
        text: `You are about to cancel your ${
          current_subscription.name
        } plan. You will be able to access your account until ${
          current_subscription.billing_cycle_end_at
            ? moment(current_subscription.billing_cycle_end_at).subtract(1, "day").format("DD MMMM YYYY")
            : "N/A"
        }. Do you still wish to cancel your subscription plan?`,
        title: "Cancel Your Subscription Plan",
        type: "warning",
        action: "cancelSubscription",
        confirmButtonText: true,
        confirmButtonText: "Yes, cancel plan",
        showCancelButton: true,
        cancelButtonText: "No, go back",
      },
    });
  }

  cancelSubscription() {
    this.props.client.mutate({
      mutation: CANCEL_SUBSCRIPTION_GQL,
      variables: {input: {}},
      update: (cache, { data: { cancel_subscription } }) => {
        if (cancel_subscription.success) {
          const { success, message } = cancel_subscription
          if (success) {
            document.location.reload()
          } else {
            this.setState({
              loading: false
            }, () => {
              toast.error(
                message
              )
            })
          }
        }
      },
    })
  }

  handleChangePlan(plan) {
    this.setState({ loading: true }, () => {
      this.props.client.mutate({
        mutation: CHANGE_SUBSCRIPTION_PLAN_GQL,
        variables: { input: { subscription_plan_id: plan.id } },
        update: (_, { data: { change_subscription_plan } }) => {
          if (change_subscription_plan.success) {
            this.setState({ loading: false, selectingPlan: null, confirmAction: "change" }, () => {
              document.location.reload()
            })
          }
        },
      })
    })
  }

  handleUpgradeSubscription(plan, mutate, { stripe, elements, promoCode }) {
    elements.submit().then(({ error: submitError }) => {
      if (submitError) {
        this.setState({ errors: submitError, loading: false })
        return
      }
      this.setState({ loading: true }, () => {
        mutate({
          mutation: UPGRADE_SUBSCRIPTION_GQL,
          variables: {
            input: {
              subscription_plan_id: plan.id,
              return_url: `${window.location.origin}/subscriptions`,
              promo_code: promoCode,
            },
          },
          update: (_, { data: { upgrade_subscription } }) => {
            if (!upgrade_subscription.success) {
              this.setState({
                loading: false,
                errors: {
                  base: upgrade_subscription.message,
                },
              })
              toast.error(upgrade_subscription.message)
            } else {
              const { type, client_secret, return_url } = upgrade_subscription
              const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment
              confirmIntent({
                elements,
                clientSecret: client_secret,
                confirmParams: {
                  return_url: return_url,
                },
              }).then(({ error }) => {
                if (error) {
                  this.setState({ errors: error, loading: false })
                }
              })
            }
          },
        })
      })
    })
  }

  render() {
    return (
      <div className="UserSubscription">
        <div className="header-section">
          <div className="title">Subscription Plans</div>
        </div>
        <SweetAlert
          show={this.state.showConfirm}
          title="Update Your Plan"
          type="warning"
          html={`<div class="subscription-alert">You are about to ${this.state.confirmAction} your plan from ${
            this.state.current_subscription?.name
          } to ${
            this.state.selectingPlan?.name
          }. Your new plan and monthly fee will start immediately upon confirmation. ${
            this.state.confirmAction == "upgrade"
              ? `You will be charged USD ${Math.abs(this.state.changeAmount)} upon confirmation.`
              : `Your balance amount of  USD ${Math.abs(
                  this.state.changeAmount,
                )} will be applied toward your next monthly subscription fee.`
          } Do you wish to proceed to ${this.state.confirmAction} your plan?${
            this.state.activeDiscount
              ? `\n\nNote that changing your current subscription plan will end any remaining promotional/free trial period with no free trial or promotional period included in your new plan. Your credit card will be charged now for your new monthly subscription plan.</div>`
              : ""
          }`}
          confirmButtonText="Confirm"
          showCancelButton={true}
          cancelButtonText="Cancel"
          onConfirm={() => {
            this.setState({ showConfirm: false }, () => {
              this.handleChangePlan(this.state.selectingPlan);
            });
          }}
          onCancel={() =>
            this.setState({
              loading: false,
              showConfirm: false,
              selectingPlan: null,
              confirmAction: "change",
              changeAmount: 0,
            })
          }
        />
        <SweetAlert
          show={this.state.sweetAlertState.open}
          title={this.state.sweetAlertState.title}
          type={this.state.sweetAlertState.type}
          text={this.state.sweetAlertState.text}
          confirmButtonText={this.state.sweetAlertState.confirmButtonText}
          showCancelButton={this.state.sweetAlertState.showCancelButton}
          cancelButtonText={this.state.sweetAlertState.cancelButtonText}
          onConfirm={() => {
            this.setState({ sweetAlertState: { ...this.state.sweetAlertState, open: false }, loading: true }, () => {
              if (this.state.sweetAlertState.action && typeof this[this.state.sweetAlertState.action] === "function") {
                this[this.state.sweetAlertState.action](this.state.sweetAlertState);
              }
            });
          }}
          onCancel={() => this.setState({ sweetAlertState: { open: false } })}
        />
        <Query query={GET_USER_SUBSCRIPTION_GQL} fetchPolicy="no-cache">
          {({ loading: loadingQuery, error: errorQuery, data = {} }) => {
            if (loadingQuery) return this.renderLoading();
            if (errorQuery) return this.renderError();
            const { current_subscription, all_subscription_plans } = data;
            if (!all_subscription_plans || !all_subscription_plans.length) return this.renderEmpty();
            const currentPlanPrice = current_subscription ? current_subscription.price : null;
            const isCancelling = current_subscription ? ["cancelling"].includes(current_subscription.status) : false;

            return (
              <div className="plans-list">
                {this.state.loading ? <div className="loading-overlay">Updating your plan...</div> : null}
                {all_subscription_plans.map((plan, index) => {
                  const canChangePlan =
                    isChangeablePlan(current_subscription, plan) && !this.state.loading && !isCancelling;
                  return (
                    <div
                      key={index}
                      className="plans-item"
                      style={{ backgroundColor: planColors[index] || planColors[planColors.length - 1] }}
                    >
                      <div className="plan-name">{plan.name}</div>
                      <div className="plan-desc">Up to {plan.property_capability} Property Listing</div>
                      <div className="plan-desc">Invite Up to {plan.invite_user_capability} Users</div>
                      {plan.free_trial_days ? (
                        <div className="plan-desc">Free for first {plan.free_trial_days} Days</div>
                      ) : (
                        ""
                      )}
                      <div className="plan-desc">
                        ${plan.price} / {_.startCase(plan.recurring_interval)}
                      </div>
                      {current_subscription && current_subscription.subscription_plan_id == plan.id ? (
                        <div className="plan-usage">
                          <div>Usage</div>
                          <div>
                            {current_subscription.property_count}/{current_subscription.property_capability} Properties
                          </div>
                          <div>
                            {current_subscription.shared_user_count}/{current_subscription.invite_user_capability} Users
                          </div>
                          {current_subscription.free_trial_days > 0 ? (
                            <div>{current_subscription.trial_days_left} free days left</div>
                          ) : null}
                        </div>
                      ) : null}
                      <div className="plan-sign-up">
                        {!current_subscription ? (
                          <div
                            disabled={!canChangePlan}
                            className="btn-sign-up-now"
                            onClick={() =>
                              canChangePlan && this.handleUpgradeOrDowngradePlan(plan, current_subscription, "upgrade")
                            }
                          >
                            <span>Subscribe</span>
                          </div>
                        ) : current_subscription.subscription_plan_id == plan.id ? (
                          <div className="current-plan">Current Plan</div>
                        ) : plan.price > currentPlanPrice ? (
                          <div
                            disabled={!canChangePlan}
                            className="btn-sign-up-now"
                            onClick={() =>
                              canChangePlan && this.handleUpgradeOrDowngradePlan(plan, current_subscription, "upgrade")
                            }
                          >
                            <span>Upgrade</span>
                          </div>
                        ) : (
                          <div
                            disabled={!canChangePlan}
                            className="btn-sign-up-now"
                            onClick={() =>
                              canChangePlan &&
                              this.handleUpgradeOrDowngradePlan(plan, current_subscription, "downgrade")
                            }
                          >
                            Downgrade
                          </div>
                        )}
                      </div>
                      {current_subscription && current_subscription.subscription_plan_id == plan.id ? (
                        <div className="plan-cancel">
                          {!["cancelling", "cancelled"].includes(current_subscription.status) ? (
                            <button
                              className="cancel-subscription-plan-btn"
                              onClick={() => this.handleCancelSubscriptionPlan(current_subscription)}
                            >
                              Cancel Subscription Plan
                            </button>
                          ) : null}
                          {["cancelling"].includes(current_subscription.status) ? (
                            <div className="cancelling-subscription-desc">
                              Cancelled, access available until{" "}
                              {moment(current_subscription.cancelled_at).subtract(1, "day").format("DD MMMM YYYY")}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            );
          }}
        </Query>
        <PopupboxContainer />
      </div>
    );
  }
}

export default withRouter(withApollo(UserSubscription))
