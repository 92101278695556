import React, { Component } from 'react'
import './ResetPassword.scss'
import { withRouter } from 'react-router-dom'

import ContentBox from 'components/commons/ContentBox'
import Button from 'components/commons/Button'
import Input from 'components/commons/Input'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import SweetAlert from 'sweetalert2-react'

const RESET_PASSWORD_GQL = gql`
  mutation($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
      errors
    }
  }
`

class ResetPassword extends Component {
  state = {
    showSuccess: false,
    password: '',
    password_confirmation: '',
    errors: []
  }

  submit(mutate) {
    this.setState({ errors: [] })
    const { password, password_confirmation } = this.state
    const { token } = this.props
    mutate({
      variables: {
        input: {
          password,
          password_confirmation,
          token
        }
      },
      update: (cache, { data: { resetPassword } }) => {
        if (!resetPassword.success) {
          this.setState({
            errors: resetPassword.errors
          })
        } else {
          this.setState({ showSuccess: true })
        }
      }
    })
  }

  renderErrors(errors = []) {
    return errors.map((err, index) => (
      <div key={index} className="err-message">
        {err}
      </div>
    ))
  }

  renderSuccessBox() {
    return (
      <SweetAlert
        show={this.state.showSuccess}
        title="Success"
        type="success"
        text="Your password has been reset successfully"
        confirmButtonText="Back to Sign In"
        onConfirm={() =>
          this.setState({ showSuccess: false }, () => {
            this.props.history.push('/sign-in')
          })
        }
      />
    )
  }

  render() {
    return (
      <div className="ResetPassword">
        <Mutation mutation={RESET_PASSWORD_GQL}>
          {(mutate, { loading }) => (
            <ContentBox title="Reset Password">
              {this.renderErrors(this.state.errors)}
              <div className="box-inputs">
                <Input
                  className="input-password"
                  onChange={(value) => this.setState({ password: value })}
                  value={this.state.password}
                  inputHtml={{
                    name: "password",
                    type: "password",
                    placeholder: "Enter new password",
                    autoFocus: true,
                  }}
                />
                <Input
                  className="input-password-confirmation"
                  value={this.state.password_confirmation}
                  onChange={(value) =>
                    this.setState({
                      password_confirmation: value,
                    })
                  }
                  inputHtml={{
                    name: "password_confirmation",
                    type: "password",
                    placeholder: "Retype new password",
                    onKeyUp: (event) => event.key === "Enter" && this.submit(mutate),
                  }}
                />
              </div>
              <button type="submit" className="ThemeButton" disabled={loading} onClick={() => this.submit(mutate)}>
                Submit
              </button>
            </ContentBox>
          )}
        </Mutation>
        {this.renderSuccessBox()}
      </div>
    );
  }
}

export default withRouter(ResetPassword)
