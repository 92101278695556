import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

export default class PropertyTourBook extends Component {
  renderItems(items) {
    return items.map(item => {
      return (
        <div key={`photo-item-${item.id}`} className="photo-item">
          <Link
            className="photo-wraper"
            to={`/pdf/${item.id}`}
            disabled={item.processing}
            style={{ textAlign: "center" }}
          >
            {item.thumbnail ? (
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <div className={["spine", item.orientation].join(" ")} />
                <img
                  className={["thumbnail", item.orientation].join(" ")}
                  src={item.thumbnail}
                  alt={item.title}
                  style={{ width: "auto", backgroundImage: "unset" }}
                />
                <div className={["pages", item.orientation].join(" ")} />
              </div>
            ) : (
              <div className="thumbnail" style={{ backgroundSize: "70%" }}>
                <div className="spine" />
                <div className="pages" />
              </div>
            )}
            {item.processing ? <div className="overlay">Processing...</div> : null}
            <div className="label-wraper">{item.title}</div>
          </Link>
        </div>
      )
    });
  }

  render() {
    const { pdf_books = [] } = this.props;

    return (
      <div className="interior-tour-container exterior-tour-container">
        {pdf_books.length > 0 ? (
          this.renderItems(pdf_books)
        ) : (
          <div
            className="flex grow-1 jc-center al-center"
            style={{ height: '40px' }}>
            No content
          </div>
        )}
      </div>
    );
  }
}
