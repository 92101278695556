import React, { Component } from 'react';
import TermsAndConditions from 'components/TermsAndConditions';

class TermsAndConditionsPage extends Component {
  render() {
    return (
      <div className="TermsAndConditionsPage">
        <TermsAndConditions />
      </div>
    );
  }
}

export default TermsAndConditionsPage;
