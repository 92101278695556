import React from 'react';
import config from 'config';
import cookie from 'js-cookie'

class ExportPropertyReportButton extends React.Component {
  getUrl() {
    let token = cookie.get('user-auth-token')
    let url = `${config.backend_host}/api/reports/properties_report.xlsx?auth_token=${token}&subdomain=${config.subdomain}`;
    return url;
  }

  render() {
    return (
      <a className="btn btn-dark-blue btn-export" href={this.getUrl()} target="_blank">
        <span className="mdi mdi-file-export" />
        &nbsp; Export Excel
      </a>
    );
  }
}

export default ExportPropertyReportButton;
