import React, { Component } from 'react';
import './Input.scss';

export default class Input extends Component {
  state = {
    value: this.props.value
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value != this.state.value) {
      this.setState({ value: nextProps.value });
    }
  }

  getUniqueId() {
    return this.uniqueId || (this.uniqueId = this.randomUniqueId());
  }

  randomUniqueId() {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    );
  }

  render() {
    const {
      inputHtml = {},
      wrapperHtml = {},
      label,
      required,
      error,
      onChange = event => {},
      defaultValue,
      readOnly,
      className='',
      ...rest
    } = this.props;

    const { type = 'text' } = inputHtml;

    return (
      <div
        className={`Input ${className}${(error && ' err') || ''} ${readOnly ? 'disabled' : ''}`}
        {...wrapperHtml}>
        <label htmlFor={this.getUniqueId()}>
          {label}
          {required && <abbr>*</abbr>}
        </label>
          <div className='form-input'>
        {type == 'richtext' ? (
          <textarea
            {...inputHtml}
            value={this.state.value || ''}
            onChange={({ target: { value } }) =>
              this.setState({ value: value }, () => onChange(value))
            }
            id={this.getUniqueId()}
          />
        ) : (
          <input
            {...inputHtml}
            readOnly={readOnly}
            value={
              !this.state.edited
                ? defaultValue || this.state.value
                : this.state.value || ''
            }
            onChange={({ target: { value } }) => {
              this.setState({ value: value, edited: true }, () =>
                onChange(value)
              );
            }}
            id={this.getUniqueId()}
          />
        )}
        {error && <span className="err-message">{error}</span>}
        </div>
      </div>
    );
  }
}
