import React, { Component } from 'react'
import CollapseList from 'components/commons/CollapseList'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import './Help.scss'

export const GET_FAQ_LIST_GQL = gql`
  query {
    faqs {
      question
      answer
    }
  }
`

export default class Help extends Component {
  convertData(sourceData) {
    return sourceData.map(item => {
      let obj = {}
      obj['title'] = item.question
      obj['content'] = <div className="content">{item.answer}</div>
      return obj
    })
  }
  render() {
    return (
      <Query query={GET_FAQ_LIST_GQL}>
        {({ loading, error, data }) => {
          if (error) return `Error! ${error.message}`
          const { faqs = [] } = data
          return (
            <div className="Help">
              <div className="title">Frequently Asked Questions</div>
              {/* <div className="sub-title">
              </div> */}
              <div className="separate-line" />
              {<CollapseList data={this.convertData(faqs)} />}
            </div>
          )
        }}
      </Query>
    )
  }
}
