import React, { useState } from 'react';
import './TermsAndConditions.scss';
import { withRouter } from 'react-router-dom';
import userAuth from 'utils/userAuth';

import CheckBox from 'components/commons/CheckBox';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';

export const ACCEPT_TNC_GQL = gql`
  mutation {
    acceptTermsAndConditions(input: {}) {
      success
      customer {
        email
        accepted_tnc
      }
    }
  }
`;

export const GET_TNC_GQL = gql`
  query {
    termsAndConditions {
      content
    }
  }
`;

export default  withRouter(({history}) => {
  const [accepted, setAccepted] = useState(false)

  const submitAcceptTnc = (event, mutate) => {
    event.preventDefault();
    mutate({
      update: (cache, { data: { acceptTermsAndConditions } }) => {
        if (acceptTermsAndConditions.success) {
          const token = userAuth.getAuth().user_auth_token;
          userAuth.setAuth({
            auth_token: token,
            user_profile: acceptTermsAndConditions.customer
          });
          history.push('/dashboard');
        }
      }
    });
  }

  const handleDecline = (event) => {
    event.preventDefault();
    history.push("/sign-in")
  }

  const loadingClass = (loading) => {
    return loading ? 'loading' : '';
  }

  const disabledClass = () => {
    return accepted ? '' : 'disabled';
  }

    return (
      <Query query={GET_TNC_GQL}>
        {({ loading, error, data }) => {
          if (loading) return 'Loadding...';
          if (error) return `Error! ${error.message}`;

          return (
            <Mutation mutation={ACCEPT_TNC_GQL}>
              {(mutate, { loading }) => (
                <div className="TermsAndConditions">
                  <h1 className="title">Terms And Conditions</h1>
                  <p
                    className="content"
                    style={{ whiteSpace: 'pre-line', width: '100%' }}
                    dangerouslySetInnerHTML={{
                      __html: data.termsAndConditions.content
                    }}
                  />
                  <CheckBox
                    label="I agree to adhere by the above stated Terms and Conditions"
                    onChange={accepted =>setAccepted(accepted)}
                    checked={accepted}
                  />
                  <div className="buttons-container">
                    <button
                      className={`proceed-button ${loadingClass(
                        loading
                      )} ${disabledClass()}`}
                      onClick={event => submitAcceptTnc(event, mutate)}>
                      Agree
                    </button>

                    <button
                      className={'decline-button'}
                      onClick={event => handleDecline (event)}>
                      Decline
                    </button>
                  </div>
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    )
})
