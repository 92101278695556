import React, { Component } from "react"

import { Elements, PaymentElement, ElementsConsumer } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { STRIPE_PUBLISHABLE_KEY } from "utils/constants"
import Input from "components/commons/Input"
import { Mutation, withApollo } from "react-apollo"
import { CREATE_PAYMENT_INTENT_GQL, APPLY_PROMO_CODE_GQL } from "queries/sign_up"
import { toast } from "react-toastify"

const appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#ac2430",
  },
  rules: {
    ".Label": {
      opacity: 0,
    },
  },
}
class Payment extends Component {
  state = {
    stripePromise: null,
    options: null,
    promoCode: "",
    errors: {},
    loading: false,
  }

  componentDidMount() {
    const { registration } = this.props
    const { subscription_plan } = registration
    this.setState({
      stripePromise: loadStripe(STRIPE_PUBLISHABLE_KEY),
      options: {
        mode: "payment",
        currency: String(subscription_plan.currency).toLowerCase(),
        amount: Math.round(subscription_plan.price * 100),
        setupFutureUsage: "off_session",
        mode: "subscription",
        captureMethod: "automatic",
        paymentMethodTypes: ["card"],
        appearance: appearance,
      },
      promoCode: registration.promo_code || "",
    })
  }

  async handleSubmit(event, { stripe, elements }) {
    event.preventDefault()
    event.stopPropagation()

    if (this.state.loading) return false
    this.setState({ errors: {}, loading: true })

    if (this.state.promoCode) {
      try {
        const {
          data: { apply_promo_code },
        } = await this.props.client.mutate({
          mutation: APPLY_PROMO_CODE_GQL,
          variables: { input: { promo_code: this.state.promoCode, registration_code: this.props.registration.code } },
        })
        if (!apply_promo_code.success) {
          this.setState({
            loading: false,
            errors: {
              promoCode: apply_promo_code.message,
            },
          })
          return false
        } else {
          toast.success(apply_promo_code.message)
        }
      } catch (error) {
        this.setState({
          loading: false,
        })
        toast.error(error.message)
        return false
      }
    }

    try {
      const { error: submitError } = await elements.submit()

      if (submitError) {
        this.setState({ loading: false }, () => {
          toast.error(submitError.message)
        })
        return
      }

      const {
        data: { create_payment_intent },
      } = await this.props.client.mutate({
        mutation: CREATE_PAYMENT_INTENT_GQL,
        variables: {
          input: {
            code: this.props.registration.code,
            return_url: window.location.href,
            verify_url: `${window.location.origin}/verify-email`,
          },
        },
      })

      if (!create_payment_intent.success) {
        this.setState({
          loading: false,
          errors: {
            base: create_payment_intent.message,
          },
        })
      } else {
        const { type, client_secret, return_url } = create_payment_intent
        const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment
        const { error } = await confirmIntent({
          elements,
          clientSecret: client_secret,
          confirmParams: {
            return_url: return_url,
          },
        })

        if (error) {
          this.setState({ loading: false }, () => {
            toast.error(error.message)
          })
          return
        }
      }
    } catch (error) {
      this.setState({ errors: error, loading: false })
      toast.error(error.message)
    }
  }

  render() {
    const { options, stripePromise } = this.state
    if (!stripePromise || !options) return null

    return (
      <Elements stripe={stripePromise} options={options}>
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <form onSubmit={(event) => this.handleSubmit(event, { elements, stripe })}>
              <div className="box-inputs">
                <label>Card Details</label>
                <PaymentElement />
              </div>
              <div className="box-inputs">
                <label>Promotion Code (if any)</label>
                <Input
                  className="input"
                  onChange={(value) => this.setState({ promoCode: value })}
                  value={this.state.promoCode}
                  inputHtml={{
                    name: "promoCode",
                    type: "text",
                    placeholder: "Enter promotion code",
                    style: {fontSize: '16px'}
                  }}
                  error={this.state.errors["promoCode"]}
                />
              </div>
              <div style={{ marginTop: 20, textAlign: "right" }}>
                <button type="submit" className="ThemeButton" disabled={this.state.loading}>
                  Continue
                </button>
              </div>
            </form>
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
}

export default withApollo(Payment)