import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CheckBox from "components/commons/CheckBox";
import withSetting from "hocs/withSetting";
import "./style.scss";

const SweetAlert = withReactContent(Swal);

export default withSetting(
  ({ href, target, children, userSettings, setUserSettings, ...props }) => {
    const handlOnClick = event => {
      event.preventDefault();
      event.stopPropagation();

      if (userSettings["dontShowExternalLinkAlert"]) {
        return window.open(href, target);
      }

      SweetAlert.fire({
        title:
          "Warning: this is an external link that will open in a new browser tab.",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, proceed",
        cancelButtonText: "No, return to AssetBase",
        footer: (
          <div className="alert-footer">
            <CheckBox
              className="AlertCheckbox"
              label="Don't show me this again"
              onChange={accepted => setUserSettings({ ...userSettings, dontShowExternalLinkAlert: accepted })}
            />
          </div>
        )
      }).then(result => {
        if (result.value) {
          window.open(href, target);
          SweetAlert.close();
        } else {
          SweetAlert.close();
        }

      });

      return false;
    };

    return (
      <a href={href} {...props} onClick={handlOnClick}>
        {children}
      </a>
    );
  }
);
