import gql from "graphql-tag";

export const SIGN_IN_GQL = gql`
  mutation($input: SignInInput!) {
    signIn(input: $input) {
      token
      customer {
        email
        accepted_tnc
        customer_profile {
          first_name
          last_name
          avatar
        }
      }
      errors
    }
  }
`;
