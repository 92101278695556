import React, { Component } from 'react'
import './List.scss'

export default class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: props.data || [],
      loading: this.props.loading || false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data != this.state.items) {
      this.setState({ items: nextProps.data })
    }

    if (nextProps.loading != this.state.loading) {
      this.setState({ loading: nextProps.loading })
    }
  }

  renderItem(e, i) {
    const key = this.extractKey(e, i)
    return (
      <div className="Item" key={key}>
        {this.props.renderChildComponent(e, i)}
      </div>
    )
  }

  extractKey(e, i) {
    if (typeof this.props.keyExtractor === 'function') {
      return this.props.keyExtractor(e)
    } else {
      return i
    }
  }

  renderLoading() {
    if (typeof this.props.loadingComponent === 'function') {
      return (
        <div key="loading" className="Item" style={{ flexBasis: '100%' }}>
          {this.props.loadingComponent()}
        </div>
      )
    } else {
      return (
        <div key="loading" className="Item" style={{ flexBasis: '100%' }}>
          <div className="loading">Loading...</div>
        </div>
      )
    }
  }

  renderList() {
    return this.state.items.map((e, i) => this.renderItem(e, i))
  }

  render() {
    if (this.state.items.length == 0 && !this.state.loading) {
      return (
        <div className={`List ${this.props.className}`}>
          <div className="Item" style={{ width: '100%', alignSelf: 'center' }}>
            <div className="loading" style={{ color: '#373a3c' }}>
              There are no listings at the moment
            </div>
          </div>
        </div>
      )
    }
    const itemComponents = this.renderList()
    return (
      <div
        className={`List ${this.props.className}`}
        style={{ ...this.props.style }}
      >
        {itemComponents}
        {this.state.loading && this.renderLoading()}
      </div>
    )
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom - 50 <= window.innerHeight
  }

  componentDidMount() {
    document
      .getElementById(this.props.containerId || 'root')
      .addEventListener('scroll', this.trackScrolling)
  }

  componentWillUnmount() {
    document
      .getElementById(this.props.containerId || 'root')
      .removeEventListener('scroll', this.trackScrolling)
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById(
      this.props.containerId || 'root-layout'
    )
    if (this.isBottom(wrappedElement)) {
      if (typeof this.props.reachEndCallback === 'function') {
        this.props.reachEndCallback()
      }
    }
  }
}
