import React, { useState } from 'react';
import './PropertyView.scss';
import { Link, withRouter } from 'react-router-dom';
import { Query, withApollo } from "react-apollo"
import BasicView from './BasicView';
import SectionListView from './SectionListView';
import Button from 'components/commons/Button';
import SweetAlert from 'sweetalert2-react';
import axios from "utils/axios";
import Loading from '../Loading';
import { toast } from 'react-toastify';
import { GET_PROPERTY_GQL } from 'queries/property'
import { GET_CURRENT_SUBSCRIPTION_GQL } from "queries/subscription"

const PropertyView = (props) => {
  const [show, setShow] = useState(false);
  const [alertProps, setAlertProps] = useState({
    title: "Are you sure?",
    type: "warning",
    text: "Are you sure you want to duplicate this property?",
    confirmButtonText: "Yes",
    showCancelButton: true,
    cancelButtonText: "No"
  })
  const [popupState, setPopupState] = useState({
    showPopup: false,
    popupMessage: '',
  })
  const { propertyId, scrollToTourBook } = props;

  const renderEditButton = ({ permissions }) => {

    let text = 'Invite';
    if (['can_edit', 'full_control'].indexOf(permissions) >= 0) {
      text = 'Edit';
    }

    if (text !== 'Edit') return null
    return (
      <Link to={`/property/${props.propertyId}/edit`}>
        <Button className="btn btn-dark-blue btn-property">{text}</Button>
      </Link>
    );
  }

  const handleDuplicate = ({ propertyId }) => {
    let property_id = propertyId;
    setAlertProps({
      title: "Duplicating...",
      type: "info",
      html: "<span class='fa fa-spin fa-spinner fa-2x' />",
      showCancelButton: false,
      showConfirmButton: false
    })
    axios.post(`/api/properties/${property_id}/duplicate`)
      .then(rs => {
        setAlertProps({
          title: "Duplicate successfully!",
          type: "success",
          text: "The property has bean duplicated successfully!",
          showCancelButton: false,
          showConfirmButton: false
        })
        setTimeout(() => {
          window.location = `/property/${rs.data.result}/edit`
          setShow(!show);
        }, 1000)
      }).catch(err => {
        toastMessages(err.response.data.errors);
        setShow(!show);
      })
  }

  const toastMessages = (messages) => {
    Object.keys(messages).forEach(key => {
      [messages[key]].flatten().forEach(message => toast.error(message));
    });
  }

  const handleClickDuplicate = () => {
    props.client
      .query({
        query: GET_CURRENT_SUBSCRIPTION_GQL,
        fetchPolicy: "no-cache",
      })
      .then(({ data: { current_subscription } }) => {
        if (!current_subscription) {
          setPopupState({
            showPopup: true,
            popupMessage: "In order to duplicate a property, please sign up for a subscription plan under your account menu.",
          })
        } else if (!current_subscription.allowed_create_property) {
          setPopupState({
            showPopup: true,
            popupMessage:
              "You have reached the maximum number of properties under your current subscription plan. Would you like to update your plan?",
          })
        } else {
          setShow(true)
        }
      })
      .catch((error) => {
        setShow(true)
        console.log(error);
      })
  }

  const renderDuplicateButton = ({ permissions }) => {
    if (['full_control'].indexOf(permissions) >= 0) {
      let text = 'Duplicate';
      return (
        <>
          <SweetAlert
            show={show}
            {...alertProps}
            onConfirm={() => handleDuplicate(props)}
            onCancel={() => setShow(!show)}
          />
          <SweetAlert
            show={popupState.showPopup}
            title="Warning"
            type="warning"
            text={popupState.popupMessage}
            confirmButtonText="OK"
            showCancelButton
            cancelButtonText="Cancel"
            onConfirm={() => {
              setPopupState((prev) => ({ ...prev, showPopup: false }))
              props.history.push("/subscriptions")
            }}
            onCancel={() => setPopupState((prev) => ({ ...prev, showPopup: false }))}
          />
          <Button className="btn btn-dark-blue btn-property" onClick={handleClickDuplicate}>
            {text}
          </Button>
        </>
      )
    }
    return false;
  }

  return (
    <Query
      query={GET_PROPERTY_GQL}
      variables={{ id: propertyId }}
      fetchPolicy="no-cache">
      {({ loading, error, data, refetch: refetchProperty }) => {
        if (error) return `Error! ${error.message}`;
        if (loading) return <Loading />;
        const { property } = data;
        if (!property)
          return (
            <div className="PropertyView">
              <div className="flex jc-center" style={{ marginTop: '40px' }}>
                Property Not Found
                </div>
            </div>
          );
        return (
          <div className="PropertyView">
            <div className="edit-container flex row al-center" style={{ justifyContent: "flex-end" }}>
              <div className="duplicate-button-container">{renderDuplicateButton(property)}</div>
              <div className="edit-button-container">{renderEditButton(property)}</div>
            </div>
            <div className="property-view-container">
              <BasicView {...property} />
              <SectionListView {...property} refetchProperty={refetchProperty} scrollToTourBook={scrollToTourBook} />
            </div>
          </div>
        )
      }}
    </Query>
  );
}
export default withRouter(withApollo(PropertyView));
