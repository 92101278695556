import React from 'react';
import Button from 'components/commons/Button';

export default class FooterSection extends React.Component {
  static defaultProps = {
    canNext: true,
    canBack: true,
    doNext: () => {},
    doBack: () => {},
    doFinish: () => {},
    showFinish: true,
    nextTitle: 'Next',
    finishTitle: 'Finish'
  };

  render() {
    const {
      disabled,
      canNext,
      canBack,
      doNext,
      doBack,
      doFinish,
      showFinish,
      nextTitle,
			finishTitle
    } = this.props;
    return (
      <div className="footer-section flex row grow-1">
        <div className="navigate-buttons flex row grow-1 jc-end al-center">
          {canBack && (
            <Button
              disabled={disabled}
              onClick={() => doBack()}
              className="btn btn-dark-blue previous">
              Previous
            </Button>
          )}
          {showFinish && (
            <Button
              disabled={disabled}
              onClick={() => doFinish()}
              className="btn btn-dark-blue finish">
						{finishTitle}
            </Button>
          )}
          {canNext && (
            <Button
              disabled={disabled}
              onClick={() => doNext()}
              className="btn btn-dark-blue next">
              {nextTitle}
            </Button>
          )}
        </div>
      </div>
    );
  }
}
