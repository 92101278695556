import React, { Component } from 'react';
import './style.scss';
import { withRouter } from 'react-router-dom';
import ContentBox from 'components/commons/ContentBox';
import Button from 'components/commons/Button';
import Input from 'components/commons/Input';
import { Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import SweetAlert from 'sweetalert2-react';
import default_logo from 'assets/images/asset_base_logo_with_text.svg';

const SUBMIT_REQUEST_GQL = gql`
  mutation($input: SubmitRequestInput!) {
    submitRequest(input: $input) {
      success
      errors
    }
  }
`;

class RequestForm extends Component {
  state = {
    showSuccess: false,
    email: '',
    first_name: '',
    last_name: '',
    message: '',
    errors: []
  };

  submit(mutate) {
    this.setState({ errors: [] });
    const { first_name, last_name, email, message } = this.state;
    mutate({
      variables: {
        input: {
          email,
          first_name,
          last_name,
          message
        }
      },
      update: (cache, { data: { submitRequest } }) => {
        if (submitRequest.errors && submitRequest.errors.length > 0) {
          this.setState({
            errors: submitRequest.errors
          });
        } else {
          this.setState({
            showSuccess: true,
            email: '',
            first_name: '',
            last_name: '',
            message: ''
          });
        }
      }
    });
  }

  renderSuccessBox() {
    return (
      <SweetAlert
        show={this.state.showSuccess}
        title="Success"
        type="success"
        text="Your request has been submitted sucessfully!"
        onConfirm={() => this.setState({ showSuccess: false }, () => {})}
      />
    );
  }

  renderErrors(errors = []) {
    return (
      <div className="error-container">
        {errors.map((err, index) => (
          <div key={index} className="err-message">
            {err}
          </div>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div className="ContactUs">
        <ContentBox
          title="Request Access"
          logo={
            <div
              className="logo"
              style={{
                backgroundImage: `url(${default_logo})`
              }}
            />
          }>
          {this.renderErrors(this.state.errors)}
          <div className="box-inputs">
            <Input
              className="input-email"
              onChange={value => this.setState({ email: value })}
              value={this.state.email}
              inputHtml={{
                name: 'email',
                type: 'email',
                placeholder: 'Email',
                autoFocus: true
              }}
            />
            <Input
              className="input-first-name"
              onChange={value => this.setState({ first_name: value })}
              value={this.state.first_name}
              inputHtml={{
                name: 'first_name',
                type: 'text',
                placeholder: 'First Name'
              }}
            />
            <Input
              className="input-last-name"
              onChange={value => this.setState({ last_name: value })}
              value={this.state.last_name}
              inputHtml={{
                name: 'last_name',
                type: 'text',
                placeholder: 'Last Name'
              }}
            />
            <Input
              className="input-message"
              onChange={value => this.setState({ message: value })}
              value={this.state.message}
              inputHtml={{
                name: 'message',
                type: 'richtext',
                placeholder: 'Message'
              }}
            />
          </div>
          <Mutation mutation={SUBMIT_REQUEST_GQL}>
            {(mutate, { loading }) => (
              <Button
                cucumber="SubmitButton"
                className="btn btn-login"
                loading={loading}
                onClick={() => this.submit(mutate)}>
                Submit
              </Button>
            )}
          </Mutation>
        </ContentBox>
        {this.renderSuccessBox()}
      </div>
    );
  }
}

export default withRouter(RequestForm);
