import React, { Component } from 'react'
import PropertyReport from 'components/PropertyReport'
import "./ReportPage.scss"

class ReportPage extends Component {
  state = {
    tab: 'property-reports'
  }

  render() {
    return (
      <div className="ReportPage">
        <div className='title'>REPORTS</div>
        <PropertyListingTab tab={this.state.tab} onSelectTab={(tab) => this.setState({ tab: tab })} />
        {this.state.tab === "property-reports" ? <PropertyReport /> : null}
      </div>
    )
  }
}

export function PropertyListingTab({ tab, onSelectTab = () => {} }) {
  return (
    <div className="PropertyPageHeader">
      <div className="breadcrumb">
        <div className={`menu-item ${tab === "all" ? "active" : ""}`} onClick={() => onSelectTab("property-reports")}>
          <span className="connector"> </span>
          Property Report
        </div>
      </div>
    </div>
  )
}

export default ReportPage
