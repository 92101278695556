import React, { Component } from "react"
import "./SignUpBox.scss"
import { withRouter } from "react-router-dom"
import Input from "components/commons/Input"
import { Link } from "react-router-dom"
import { Mutation, Query, withApollo } from "react-apollo"
import { SIGN_UP_GQL, GET_REGISTRATION_GQL } from "queries/sign_up"
import logo from "assets/images/asset_base_logo_with_text.svg"
import SubscriptionSelectInput from "components/commons/SubscriptionSelectInput"
import Payment from "./Payment"
import { GET_INVITATION_GQL } from "../Invitation/index"

function validateSignUp(values) {
  const errors = {}
  if (!values.first_name) {
    errors["first_name"] = "First name is required"
  }
  if (!values.last_name) {
    errors["last_name"] = "Last name is required"
  }

  if (!values.email) {
    errors["email"] = "Email is required"
  }
  if (!values.password) {
    errors["password"] = "Password is required"
  }
  if (!values.password_confirm) {
    errors["password_confirm"] = "Re-enter Password is required"
  }
  if (values.password_confirm !== values.password) {
    errors["password_confirm"] = "Passwords does not match"
  }
  if (!values.subscription_plan_id && !values.invitation) {
    errors["subscription_plan_id"] = "Subscription Plan Type is required"
  }
  return errors
}

const dev_profile = {
  firstName: "Vin",
  lastName: "Le",
  email: "vin+le@rubify.com",
  password: "Password123",
  passwordConfirm: "Password123",
  subscriptionPlanId: "1",
}

class SignUpBox extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirm: "",
    subscriptionPlanId: null,
    registration: null,
    promoCode: "",
    errors: [],
    sent: false,
    invitation: null,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.plan_id != this.state.subscriptionPlanId) {
      this.setState({ subscriptionPlanId: nextProps.plan_id })
    }
    if (nextProps.invitation_code) {
      this.setState({ loading: true }, () => {
        this.getInvitationInfo(nextProps.invitation_code)
      })
      
    }
  }

  componentDidMount() {
    // this.setState({ errors: [], ...dev_profile })
    this.setState({ errors: [], subscriptionPlanId: this.props.plan_id })
    if (this.props.invitation_code) {
      this.setState({loading: true}, () => {
        this.getInvitationInfo(this.props.invitation_code)
      })
      
    }
  }

  submitSignUp(mutate, submitData) {
    mutate({
      variables: {
        input: submitData,
      },
      update: (cache, { data: { sign_up } }) => {
        if (sign_up.errors && sign_up.errors.length > 0) {
          this.setState({
            errors: sign_up.errors,
          })
        } else if (sign_up.success) {
          const { registration } = sign_up
          const { code } = registration
          if (this.state.invitation) {
            this.props.history.replace(`/sign-up/${code}`)
          } else {
            this.props.history.replace(`/sign-up/${code}?step=2`)
          }
        }
      },
    })
  }

  renderErrors(errors = []) {
    return (
      <div className="error-container">
        {errors.map((err, index) => (
          <div key={index} className="err-message">
            {err}
          </div>
        ))}
      </div>
    )
  }

  handleSubmit(e, mutate) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ errors: [] })
    const submitData = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
    }

    if (!this.state.invitation) {
      submitData.subscription_plan_id = this.state.subscriptionPlanId
    } else {
      submitData.invitation_code = this.state.invitation.code
      submitData.verify_url = `${window.location.origin}/verify-email`
    }
    const errors = validateSignUp({
      ...submitData,
      password_confirm: this.state.passwordConfirm,
      invitation: this.state.invitation,
    })

    if (Object.values(errors).some(Boolean)) {
      this.setState({ errors })
      return false
    }

    this.submitSignUp(mutate, submitData)
  }

  renderSignUpForm() {
    return (
      <>
        <div className="SignUpBoxHeader">
          <img alt="AssetBase" className="medium-logo" src={logo} />
          <h2>Sign Up Form</h2>
          <p>
            Please complete the fields below. Please contact{" "}
            <a href="mailto:support@assetbase.com.sg">support@assetbase.com.sg</a> if you require further assistance.
            Thank you!
          </p>
        </div>
        <div className="sign-up-content">
          <Mutation mutation={SIGN_UP_GQL}>
            {(mutate, { loading }) => (
              <form onSubmit={(event) => !loading && this.handleSubmit(event, mutate)}>
                <div className="box-inputs">
                  <div className="input-row">
                    <Input
                      className="input"
                      onChange={(value) => this.setState({ firstName: value })}
                      value={this.state.firstName}
                      inputHtml={{
                        name: "firstName",
                        type: "text",
                        placeholder: "First Name",
                        autoFocus: true,
                      }}
                      error={this.state.errors["first_name"]}
                    />
                    <Input
                      className="input"
                      onChange={(value) => this.setState({ lastName: value })}
                      value={this.state.lastName}
                      inputHtml={{
                        name: "lastName",
                        type: "text",
                        placeholder: "Last Name",
                        autoFocus: false,
                      }}
                      error={this.state.errors["last_name"]}
                    />
                  </div>
                  <Input
                    className="input"
                    onChange={(value) => this.setState({ email: value })}
                    value={this.state.email}
                    inputHtml={{
                      name: "email",
                      type: "email",
                      placeholder: "Email Address",
                      autoFocus: false,
                    }}
                    error={this.state.errors["email"]}
                  />
                  <Input
                    className="input"
                    onChange={(value) => this.setState({ password: value })}
                    value={this.state.password}
                    inputHtml={{
                      name: "password",
                      type: "password",
                      placeholder: "Password",
                      autoFocus: false,
                    }}
                    error={this.state.errors["password"]}
                  />
                  <Input
                    className="input"
                    onChange={(value) => this.setState({ passwordConfirm: value })}
                    value={this.state.passwordConfirm}
                    inputHtml={{
                      name: "passwordConfirm",
                      type: "password",
                      placeholder: "Re-enter Password",
                      autoFocus: false,
                    }}
                    error={this.state.errors["password_confirm"]}
                  />
                  {!this.state.invitation && !this.state.loading ? (
                    <>
                      <SubscriptionSelectInput
                        name="subscription_plan_id"
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={this.state.subscriptionPlanId}
                        styles={{
                          option: (base, state) => {
                            return {
                              ...base,
                              borderWidth: 1,
                              backgroundColor: state.isSelected ? "#16254c" : state.isFocused ? "#16254c57" : null,
                            }
                          },
                        }}
                        onChange={({ value }) => {
                          this.state.subscriptionPlanId = value
                        }}
                        placeholder="Select Subscription Plan"
                        menuPlacement="top"
                      />
                      {this.state.errors["subscription_plan_id"] && (
                        <p className="err-message" style={{ marginTop: 10 }}>
                          {this.state.errors["subscription_plan_id"]}
                        </p>
                      )}
                    </>
                  ) : null}
                </div>
                <div style={{ marginTop: 20, textAlign: "right" }}>
                  <button type="submit" className="ThemeButton" disabled={loading}>
                    Continue
                  </button>
                </div>
              </form>
            )}
          </Mutation>
        </div>
      </>
    )
  }

  renderCreditCardForm(registration) {
    return (
      <>
        <div className="SignUpBoxHeader">
          <img alt="AssetBase" className="medium-logo" src={logo} />
          <h2>Credit Card Details</h2>
          <p>
            You have selected the <b className="inline-block">{registration.subscription_plan.name} Plan.</b>
          </p>
        </div>
        <div className="sign-up-content w-full">
          <Payment registration={registration} />
        </div>
      </>
    )
  }

  renderSuccessBox(registration) {
    return (
      <div className="complete-registration text-center">
        <h2>Sign Up Successful - Please Verify your Email</h2>
        <p>
          You are almost there! We have sent an email to <b>{registration.email}</b>
        </p>
        <p>
          Just click on the link in that email to complete your sign up. If you don't see it, please check the spam
          folder.
        </p>
        <p>
          Or contact <a href="mailto:support@assetbase.com.sg">support@assetbase.com.sg</a> if you require further
          assistance.
        </p>
        <br />
        <br />
        <br />
        <div>
          <Link className="ThemeButton btn-log-in" to="/sign-in">
            Log In
          </Link>
        </div>
        <br />
        <br />
      </div>
    )
  }

  getInvitationInfo(invitation_code) {
    this.props.client
      .query({
        query: GET_INVITATION_GQL,
        variables: { code: invitation_code },
      })
      .then(({ data: { invitation } }) => {
        if (invitation && invitation.invite_type === "guest" && invitation.status === "pending") {
          this.setState({ 
            email: invitation.email,
            invitation, 
            loading: false,
          })
        } else {
          this.setState({ loading: false })
        }
      }).catch(() => {
         this.setState({
           loading: false,
         })
      })
  }

  render() {
    const { code, step } = this.props
    return (
      <div className="SignUpBox">
        <Query query={GET_REGISTRATION_GQL} variables={{ code }} skip={!code}>
          {({ loading, error, data }) => {
            if (loading) return <div>Loading...</div>
            if (error) return <div>Error :(</div>

            const { registration } = data || {}
            if (registration && registration.stage == "sign_up") {
              return this.renderSignUpForm(registration)
            } else if (registration && registration.stage == "completed") {
              return this.renderSuccessBox(registration)
            }
            if (registration && (step == "2" || registration.stage == "credit_card")) {
              return this.renderCreditCardForm(registration)
            } else {
              return this.renderSignUpForm()
            }
          }}
        </Query>
      </div>
    )
  }
}

export default withRouter(withApollo(SignUpBox))
