import axios from 'axios'
import cookie from 'js-cookie'
import config from 'config'

let instance = axios.create({
  baseURL: config.backend_host,
  withCredentials: false,
  headers: {
    'Agency': 'Web'
  }
})

instance.interceptors.request.use(
  config => {
    let token = cookie.get('user-auth-token')
    let configHeaders = config.headers
    config.headers = { ...configHeaders, Authorization: token }
    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      window.location = '/sign-in'
    }
    return Promise.reject(error)
  }
)

export default instance
