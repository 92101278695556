import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './InteriorTour.scss';
import folderIcon from "assets/images/folder-icon.svg"
import NavBar from "./NavBar"

const $ = window.$;

class InteriorTour extends Component {
  state = {
    currentFolder: null,
  }

  componentDidMount() {
    $(".interior-tour-item").fancybox({
      afterShow: (instance, currentSlide) => {
        let containerId = $(currentSlide.src).attr("container-id")
        if (!containerId) return
        if ($(`#${containerId}`).children().length > 0) return

        let url = $(`#${containerId}`).attr("src")
        let panorama = new window.PANOLENS.ImagePanorama(url)

        let viewer = new window.PANOLENS.Viewer({
          container: document.getElementById(containerId),
          controlBar: true,
          cameraFov: 60,
          controlButtons: ["fullscreen"],
        })
        viewer.add(panorama)
      },
      thumbs: {
        autoStart: true,
        axis: "x",
      },
      beforeClose: function (instance, current, event) {
        let targetClassName = event.currentTarget.className
        return targetClassName.includes("fancybox-close-small") || targetClassName.includes("fancybox-button--close")
      },
      smallBtn: false,
      toolbar: true,
    })
  }

  renderAssets(assets) {
    return assets.map((asset) => {
      if (asset.file_type == "directory") {
        return this.renderFolder(asset)
      }
      switch (asset.asset_type) {
        case "video":
          return this.renderVideoFancy(asset)
        case "image":
          return this.renderPhotoFancy(asset)
        case "pdf":
          return this.renderPdfFancy(asset)
        case "other":
          if (asset.photo_type == "photo_360") return this.renderPdfFancy(asset)
          else return null
      }
    })
  }

  renderFolder(asset) {
     const overlay = (
       <div className="photo-overlay">
         <div className="icon" />
       </div>
     )
    const style = {}
    style["backgroundImage"] = `url('${folderIcon}')`
    style["backgroundRepeat"] = "no-repeat"
    style["backgroundPosition"] = "center"
    style["backgroundSize"] = "50%"

    return (
      <div key={`asset-${asset.id}`} className="photo-item" onClick={() => this.setState({ currentFolder: asset })}>
        <div className="photo-wraper">
          <div className="thumbnail" style={style} />
          {asset.photo_type == "photo_360" ? overlay : null}
          <div className="label-wraper">{asset.title}</div>
        </div>
      </div>
    )
  }

  renderPdfFancy(asset) {
    return (
      <div key={`asset-${asset.id}`} className="photo-item" onClick={() => window.open(asset.download_url, "_blank")}>
        <div className="photo-wraper">
          <div
            className="thumbnail"
            style={
              asset.thumbnail
                ? {
                    backgroundImage: `url('${asset.thumbnail}')`,
                  }
                : {
                    backgroundSize: "70%",
                  }
            }
          />
          <div className="label-wraper">{asset.title}</div>
        </div>
      </div>
    )
  }

  renderPhotoFancy(asset) {
    const overlay = (
      <div className="photo-overlay">
        <div className="icon" />
      </div>
    )
    const paranoma = asset.photo_type == "photo_360" ? { "container-id": `asset-photo-360-${asset.id}` } : {}
    return (
      <a
        key={`asset-item-${asset.id}`}
        className="asset-item photo-item interior-tour-item"
        data-fancybox="gallery"
        data-src={`#hidden-asset-content-${asset.id}`}
        data-parent-el="#interior-tour"
        data-touch={false}
        data-hash={false}
        href="javascript:;"
      >
        <div
          className="photo-fancybox-content"
          style={{ display: "none" }}
          {...paranoma}
          id={`hidden-asset-content-${asset.id}`}
        >
          <h3>{asset.title}</h3>
          <div className="photo-content">{this.renderPhotoPopupContent(asset)}</div>
        </div>
        <div className="photo-wraper">
          {asset.thumbnail ? (
            <img className="thumbnail" src={asset.thumbnail} alt={asset.title} style={{ backgroundImage: "unset" }} />
          ) : (
            <div className="thumbnail" style={{ backgroundSize: "70%" }} />
          )}
          {asset.photo_type == "photo_360" ? overlay : null}
          <div className="label-wraper">{asset.title}</div>
        </div>
      </a>
    )
  }

  renderPhotoPopupContent(photo) {
    if (!photo) return null
    const { source_url, title, asset_type } = photo

    let content = <img src={source_url} alt={title} />

    if (photo.photo_type == "photo_360") {
      content = (
        <div
          className="pano panolens-container"
          style={{ width: "1920px", height: "1080px" }}
          id={`asset-photo-360-${photo.id}`}
          src={photo.source_url}
        />
      )
    }
    return content
  }

  renderVideoFancy(asset) {
    const galleryProps = asset.complete ? { "data-fancybox": "gallery" } : {}
    return (
      <a
        key={`asset-item-${asset.id}`}
        className="asset-item photo-item interior-tour-item"
        href={asset.vimeo_url}
        {...galleryProps}
        data-parent-el="#interior-tour"
        data-touch={false}
        data-hash={false}
        disabled={!asset.complete}
      >
        <div className="photo-wraper">
          {asset.thumbnail ? (
            <img className="thumbnail" src={asset.thumbnail} alt={asset.title} style={{ backgroundImage: "unset" }} />
          ) : (
            <div className="thumbnail" style={{ backgroundSize: "70%" }} />
          )}
          {asset.complete ? "" : <div className="overlay">Processing...</div>}
          <div className="label-wraper">{asset.title}</div>
        </div>
      </a>
    )
  }

  resolveFileData(files) {
    if (this.state.currentFolder) {
      return files
        .filter((f) => String(f.asset_id) == String(this.state.currentFolder.id))
        .sort((a, b) => {
          let check = ["file", "directory"].indexOf(b.file_type) - ["file", "directory"].indexOf(a.file_type)
          if (check === 0) return a.title < b.title ? -1 : 1
          return check
        })
    } else {
      return files
        .filter((f) => !f.asset_id)
        .sort((a, b) => {
          let check = ["file", "directory"].indexOf(b.file_type) - ["file", "directory"].indexOf(a.file_type)
          if (check === 0) return a.title < b.title ? -1 : 1
          return check
        })
    }
  }


  render() {
    const assets = this.resolveFileData(this.props.assets || [])
    return (
      <>
        {this.props.assets.length > 0 && (
          <NavBar
            assets={this.props.assets}
            currentFolder={this.state.currentFolder}
            onChangeFolder={(folder) => this.setState({ currentFolder: folder })}
          />
        )}
        <div className="interior-tour-container exterior-tour-container" id="interior-tour">
          {assets.length > 0 ? (
            this.renderAssets(assets)
          ) : (
            <div className="flex grow-1 jc-center al-center" style={{ height: "40px" }}>
              No content
            </div>
          )}
        </div>
      </>
    )
  }
}

export default InteriorTour;
