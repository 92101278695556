import React, { Component, useState } from 'react';
import userAuth from 'utils/userAuth';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Link, withRouter } from 'react-router-dom';
import DropdownMenu from 'react-dd-menu';
import './Header.scss';
import { slide as BurgerMenu } from 'react-burger-menu';
import logo from 'assets/images/asset_base_logo_with_text.svg';

export const GET_PROFILE_GQL = gql`
  query {
    customerProfile {
      first_name
      last_name
      avatar
    }
  }
`;

class HeaderMenu extends React.Component {
  state = { isMenuOpen: false };
  static defaultProps = {
    onClickLink: () => {}
  };

  getUserMenu() {
    return [
      {
        text: "User Profile",
        iconClass: "mdi mdi-account-card-details",
        link: {
          to: "/user-profile",
          replace: true,
        },
      },
      {
        text: "Shared Users",
        iconClass: "mdi mdi-account-multiple",
        link: {
          to: "/shared-users",
          replace: true,
        },
      },
      {
        text: "Subscription Plans",
        iconClass: "mdi mdi-credit-card",
        link: {
          to: "/subscriptions",
          replace: true,
        },
      },
      {
        text: "Settings",
        iconClass: "mdi mdi-settings",
        link: {
          to: "/user-settings",
          replace: true,
        },
      },
      {
        text: "Payment History",
        iconClass: "mdi mdi-file",
        link: {
          to: "/payment-history",
          replace: true,
        },
      },
      {
        text: "Sign Out",
        iconClass: "mdi mdi-logout",
        link: {
          to: "/sign-out",
          replace: true,
        },
      },
    ]
  }

  getUserDisplayName(user_profile = {}) {
    const { first_name = '', last_name = '', email = '' } = user_profile;
    let displayName = [(first_name || '').trim(), (last_name || '').trim()]
      .join(' ')
      .trim();
    displayName = displayName || email || 'Anonymous';
    return displayName;
  }

  renderUserMenu(customerProfile = {}) {
    const style = {};
    if (customerProfile.avatar)
      style['backgroundImage'] = `url('${customerProfile.avatar}')`;
    const userMenuButton = (
      <div
        className="menu user"
        onClick={() => this.setState({ isMenuOpen: !this.state.isMenuOpen })}>
        <div className="welcome-text">
          {this.getUserDisplayName(customerProfile)}
        </div>
        <span className="profile-thumb" style={style} />
      </div>
    );

    return (
      <DropdownMenu
        close={() => this.setState({ isMenuOpen: false })}
        isOpen={this.state.isMenuOpen}
        toggle={userMenuButton}
        closeOnInsideClick={false}
        menuAlign="right"
        textAlign="left"
        size="sm">
        {this.getUserMenu().map((e, i) => {
          return (
            <li key={i}>
              <Link {...e.link} onClick={() => this.props.onClickLink()}>
                <i className={e['iconClass']} /> {e['text']}
              </Link>
            </li>
          );
        })}
      </DropdownMenu>
    );
  }

  needToUpdateProfile(customerProfile = {}) {
    const { first_name, last_name } = customerProfile;
    return !first_name || !last_name;
  }

  render() {
    const {loading, error, customerProfile} = this.props

    if (
      this.needToUpdateProfile(customerProfile) &&
      this.props.history.location.pathname != '/user-profile' &&
      !loading &&
      !error
    ) {
      this.props.history.push('/user-profile');
    }
    return (
      <div className="header-menu">
        {this.renderUserMenu(customerProfile)}
      </div>
    );
  }
}

const UserHeaderMenu = withRouter(HeaderMenu);
const MENU = [
  {
    pathname: '/dashboard',
    id: 'dashboard',
    text: 'PORTFOLIO'
  },
  {
    pathname: '/create-property',
    id: 'create_property',
    text: 'ADD PROPERTY'
  },
  {
    pathname: '/reports',
    id: 'reports',
    text: 'REPORTS'
  },
  {
    pathname: '/help',
    id: 'help',
    text: 'HELP'
  }
];

const USER_MENU = [
  {
    pathname: "/user-profile",
    id: "user-profile",
    text: "User Profile",
  },
  {
    pathname: "/shared-users",
    id: "shared-users",
    text: "Shared Users",
  },
  {
    pathname: "/subscriptions",
    id: "subscriptions",
    text: "Subscription Plans",
  },
  {
    pathname: "/user-settings",
    id: "user-settings",
    text: "Settings",
  },
  {
    pathname: "/payment-history",
    id: "payment-history",
    text: "Payment History",
  },
  {
    pathname: "/sign-out",
    id: "sign-out",
    text: "Sign Out",
  },
];
const MainMenu = ({ currentLocation, activeMenu, onClickLink = () => {} }) => {
  const activeMenuLink = menu => {
    if (activeMenu) {
      return menu['id'] == activeMenu ? ' active' : '';
    }
    return currentLocation == menu['pathname'] ? ' active' : '';
  };
  const renderMenu = (menu, index) => {
    return (
      <Link
        key={index}
        onClick={() => onClickLink()}
        className={`menu-link${activeMenuLink(menu)}`}
        replace={true}
        to={menu['pathname']}>
        {index === 0 ? (
          menu['text']
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="divider">|</div>
            <div>{menu['text']}</div>
          </div>
        )}
      </Link>
    );
  };

  return <div className="MainMenu">{MENU.map((e, i) => renderMenu(e, i))}</div>;
};


const UserMainMenu = ({ currentLocation, activeMenu, onClickLink = () => {} }) => {
  const activeMenuLink = (menu) => {
    if (activeMenu) {
      return menu["id"] == activeMenu ? " active" : "";
    }
    return currentLocation == menu["pathname"] ? " active" : "";
  };
  const renderMenu = (menu, index) => {
    return (
      <Link
        key={index}
        onClick={() => onClickLink()}
        className={`menu-link${activeMenuLink(menu)}`}
        to={menu["pathname"]}
      >
        {index === 0 ? (
          menu["text"]
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="divider">|</div>
            <div>{menu["text"]}</div>
          </div>
        )}
      </Link>
    );
  };

  return <div className="MainMenu">{USER_MENU.map((e, i) => renderMenu(e, i))}</div>;
};

export const Header  = (props) => {
  const [isOpen, setOpen] = useState(false);
  const { history, activeMenu  } = props;
    return (

      <Query query={GET_PROFILE_GQL}>
        {({ loading, error, data = {}, client }) => {
          const { customerProfile = {} } = data;

          return (
            <div className={`Header ${userAuth.getAuth().is_authenticated && "authenticated"}`}>
              {userAuth.getAuth().impersonated_by && (
                <div className="impersonated">Impersonated by {userAuth.getAuth().impersonated_by}</div>
              )}
              <div className="HeaderContainer">
                <div className="header-left">
                  <a href="https://assetbase.com.sg" className="header-logo">
                    <img src={logo} />
                  </a>
                  <MainMenu currentLocation={history.location.pathname} activeMenu={activeMenu} />
                </div>
                <div className="header-right">
                  {userAuth.getAuth().is_authenticated && (
                    <UserHeaderMenu
                      user={userAuth.getAuth().user_profile}
                      customerProfile={customerProfile}
                      loading={loading}
                      error={error}
                    />
                  )}
                </div>

                <div className="BurgerMenu">
                  <UserBurgerMenu {...props} customerProfile={customerProfile} loading={loading} error={error} />
                  <BurgerMenu
                    isOpen={isOpen}
                    onStateChange={(state) => setOpen(state.isOpen)}
                    customBurgerIcon={
                      <div>
                        <i
                          style={{
                            fontSize: 32,
                            textAlign: "center",
                            marginLeft: 14,
                            marginTop: 7,
                          }}
                          className="fa fa-bars"
                        />
                      </div>
                    }
                    right
                  >
                    <MainMenu
                      currentLocation={history.location.pathname}
                      activeMenu={activeMenu}
                      onClickLink={() => setOpen(false)}
                    />
                  </BurgerMenu>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
}


function UserBurgerMenu(props) {
  const { history, activeMenu, customerProfile } = props;
  const [isOpen, setOpen] = useState(false);

  if (!customerProfile) {
    return null;
  }

  const getUserDisplayName = (user_profile = {}) => {
    const { first_name = "", last_name = "", email = "" } = user_profile;
    let displayName = [(first_name || "").trim(), (last_name || "").trim()].join(" ").trim();
    displayName = displayName || email || "Anonymous";
    return displayName;
  };

  const renderUserButton = (displayName = false) => {
    const style = {};
    if (customerProfile.avatar) style["backgroundImage"] = `url('${customerProfile.avatar}')`;
    return (
      <div>
        <div className="menu user">
          {displayName && <div className="welcome-text">{getUserDisplayName(customerProfile)}</div>}
          <span className="profile-thumb" style={style} />
        </div>
      </div>
    );
  };

  return (
    <div className="UserBurger">
      <BurgerMenu
        isOpen={isOpen}
        onStateChange={(state) => setOpen(state.isOpen)}
        customBurgerIcon={<div>{renderUserButton(false)}</div>}
        right
      >
        {userAuth.getAuth().is_authenticated && renderUserButton(true)}
        <UserMainMenu
          currentLocation={history.location.pathname}
          activeMenu={activeMenu}
          onClickLink={() => setOpen(false)}
        />
      </BurgerMenu>
    </div>
  );
}

export default withRouter(Header)
