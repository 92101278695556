import React, { useState } from "react";
import "./NoteStyle.scss";
import Button from "components/commons/Button";
import { toast } from "react-toastify";
import getThumbnailIcon from "../../thumbNailIcon";
import { Mutation } from "react-apollo";
import SweetAlert from "sweetalert2-react";
import {
  GET_PROPERTY_GQL,
  SUBMIT_CREATE_NOTE_GQL,
  SUBMIT_UPDATE_NOTE_GQL,
  REMOVE_NOTE_GQL
} from "queries/property";

const ShowForm = ({ onChangeNote, ...props }) => {
  const { note } = props;
  const query = note ? SUBMIT_UPDATE_NOTE_GQL : SUBMIT_CREATE_NOTE_GQL;
  const [state, setState] = useState({
    content: note ? note.content : "",
    files: note ? note.attachment_json : [],
    renderForm: note ? true : false,
    submitting: false,
    alert: {
      title: "Saving...",
      type: "info",
      html: '<span class="fa fa-spin fa-spinner fa-2x" />',
      showCancelButton: false,
      showConfirmButton: false
    }
  });

  const handleFileChange = ({ target: { files }, ...event }) => {
    if (state.files.length + files.length > 10) {
      toast.error("Can't upload more than 10 files");
    } else {
      let overFileSize = [...files].filter(
        file => file.size / 1024 / 1024 > 500
      );
      if (overFileSize.length > 0) {
        overFileSize.map(file =>
          toast.error(`File ${file.name} larger than 500MB.`)
        );
      } else {
        setState({ ...state, files: [...state.files, ...files] });
      }
    }
  };

  const handleInputChange = ({ target: { value } }) => {
    if (value.length > 10000) {
      toast.error("Can't upload more than 10000 characters.");
    } else {
      setState({ ...state, content: value });
    }
  };

  const handleDeleteAttach = (index, event) => {
    event.preventDefault();
    state.files.splice(index, 1);
    setState({ ...state, files: [...state.files] });
  };

  const handleCancel = event => {
    event.preventDefault();
    if (note) {
      setState({ ...state, content: "", files: [], renderForm: false });
      onChangeNote(true);
    } else {
      setState({
        ...state,
        content: "",
        files: [],
        renderForm: false
      });
    }
  };

  const handleSubmit = (mutate, event) => {
    event.preventDefault();
    setState({ ...state, submitting: true });
    if (state.content === "" && state.files.length === 0) {
      toast.error("Can't submit the empty. Please check fields again!");
      setState({ ...state, submitting: false })
    } else if (state.content.length > 10000 || state.files.length > 10) {
      toast.error("Can't submit. Please check fields again!");
      setState({ ...state, submitting: false })
    } else {
      const { content, files } = state;
      if (note) {
        const id = note.id;
        const fileExist = files
          .filter(item => !!item.signed_id)
          .map(e => e.signed_id);
        const fileNew = files.filter(item => !item.signed_id);
        mutate({
          variables: {
            input: {
              content: content,
              uploads: fileNew,
              file_exist: fileExist,
              id: id
            }
          },
          update: (cache, { data: { updateNote } }) => {
            if (updateNote.success) {
              setState({ ...state, submitting: false });
              const { note } = updateNote;
              cache.writeQuery({
                query: GET_PROPERTY_GQL,
                variables: { id: props.id },
                data: {
                  property: { ...props, note }
                }
              });
              onChangeNote(true)
            } else {
              setState({
                ...state,
                files: files,
                submitting: false
              });
              Object.values(updateNote.errors).map(e => toast.error(e));
            }
          }
        });
      } else {
        const propertyId = props.id;
        mutate({
          variables: {
            input: {
              content: content,
              uploads: files,
              property_id: propertyId
            }
          },
          update: (cache, { data: { createNote } }) => {
            if (createNote.success) {
              setState({ ...state, submitting: false });
              const { note } = createNote;
              cache.writeQuery({
                query: GET_PROPERTY_GQL,
                variables: { id: props.id },
                data: {
                  property: { ...props, note }
                }
              });
              onChangeNote(true)
            } else {
              setState({
                ...state,
                files: files,
                submitting: false
              });
              Object.values(createNote.errors).map(e => toast.error(e));
            }
          }
        });
      }
    }
  };

  return !state.renderForm ? (
    <Button
      className="btn btn-dark-blue"
      onClick={() => setState({ ...state, renderForm: true })}
    >
      Create
    </Button>
  ) : (
      <div className="content-show">
        <Mutation mutation={query}>
          {(mutate, { }) => {
            return (
              <form onSubmit={(e) => handleSubmit(mutate, e)}>
                <div className="content-area">
                  <div className="content-header">
                    <p>Write</p>
                  </div>
                  <textarea
                    disabled={state.submitting}
                    value={state.content}
                    onChange={handleInputChange}
                    className="content-holder"
                    name="content"
                    rows="4"
                    placeholder="Write your note here…"
                  />
                  <div className="content-toolbar">
                    <label className="attachment-button" htmlFor="note-attachment-input">
                      <span className="mdi mdi-attachment" />
                    </label>
                    <input
                      disabled={state.submitting}
                      type="file"
                      id="note-attachment-input"
                      multiple={true}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <div className="attachment-file">
                      {state.files.length > 0 &&
                        state.files
                          .filter((f) => !!f)
                          .map((file, index) => (
                            <div key={index} className={`upload-wrapper ${file.type.replace("/", " ")} upload-section`}>
                              <span title={file.name}>{getThumbnailIcon(file)}</span>
                              <button disabled={state.submitting} onClick={(e) => handleDeleteAttach(index, e)}>
                                <i className="mdi mdi-close" />
                              </button>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
                <div className="content-submit">
                  <div className="button-container">
                    <Button
                      type="submit"
                      loading={state.submitting}
                      disabled={state.submitting}
                      className="btn btn-dark-blue submit-button"
                    >
                      Save
                    </Button>
                  </div>
                  <SweetAlert show={state.submitting} {...state.alert} />
                  <div className="button-container">
                    <Button
                      loading={state.submitting}
                      disabled={state.submitting}
                      onClick={handleCancel}
                      className="btn btn-dark-blue submit-button"
                    >
                      Cancel
                    </Button>
                  </div>
                  {!!note && (
                    <ButtonDelete
                      onDelete={(flag) => {
                        setState({
                          ...state,
                          content: "",
                          files: [],
                          renderForm: flag,
                        })
                        onChangeNote(true)
                      }}
                      {...props}
                    />
                  )}
                  <div className="inline-block"><abbr>*</abbr>{" "}This note is not seen by other users.</div>
                </div>
              </form>
            )
          }}
        </Mutation>
      </div>
    );
};

const ButtonDelete = ({ onDelete, ...props }) => {
  const { note } = props;
  const [state, setState] = useState({
    show: false,
    alert: {
      title: "Are you sure?",
      type: "warning",
      text: "Are you sure you want to delete this note?",
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No"
    }
  });

  const handleDelete = mutate => {
    setState({
      ...state,
      alert: {
        title: "Deleting...",
        type: "info",
        html: '<span class="fa fa-spin fa-spinner fa-2x" />',
        showCancelButton: false,
        showConfirmButton: false
      }
    });
    mutate({
      variables: {
        input: {
          id: note.id
        }
      },
      update: (cache, { data: { removeNote } }) => {
        if (removeNote.success) {
          setState({ ...state, show: false });
          cache.writeQuery({
            query: GET_PROPERTY_GQL,
            variables: { id: props.id },
            data: {
              property: { ...props, note: null }
            }
          });
          onDelete(false);
        } else {
          setState({ ...state, show: false });
          toast.error(removeNote.errors);
        }
        
      }
    });
  };

  return (
    <Mutation mutation={REMOVE_NOTE_GQL}>
      {(mutate, { }) => {
        return (
          <div className="button-container delete">
            <button
              className="btn-delete btn"
              onClick={event => {
                event.preventDefault();
                setState({ ...state, show: true });
              }}
            >
              <i className="fa fa-trash fa-2x" aria-hidden="true" />
            </button>
            <SweetAlert
              show={state.show}
              {...state.alert}
              onConfirm={() => handleDelete(mutate)}
              onCancel={() => setState({ ...state, show: !state.show })}
            />
          </div>
        );
      }}
    </Mutation>
  );
};

export default ShowForm;
