import React from 'react';
import { Route } from 'react-router-dom';
import { zoomOutMobile } from 'utils';
import { ToastContainer } from 'react-toastify';

const LoginLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => {
        zoomOutMobile();
        return (
          <div className="LoginLayout">
            <div className="PageContent">
              <Component {...matchProps} />
            </div>

              <ToastContainer />
          </div>
        );
      }}
    />
  );
};

export default LoginLayout;
