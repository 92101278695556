import React, { useEffect, useState } from "react";
import "./ListProperty.scss";
import { Link, withRouter } from "react-router-dom";
import Favorite from "./Favorite";
import { PopupboxManager, PopupboxContainer } from "react-popupbox"

const PropertyItem = ({
  id,
  thumbnail = "http://via.placeholder.com/318x180",
  name = "Property Name",
  market,
  country,
  property_type,
  owner,
  loan,
  deal_name,
  stage,
  is_new = false,
  is_favorite = false,
  queryObj,
  creator,
  is_shared,
}) => {
  function handleOpenOwner(event) {
    event.preventDefault()
    event.stopPropagation()

    const content = <OwnerContent uniqId={Date.now()} creator={creator} />

    PopupboxManager.open({
      content: content,
      config: {
        titleBar: {
          text: "Owner",
          enable: true,
          closeButton: true,
        },
        fadeIn: true,
        fadeInSpeed: 200,
      },
    })
  }

  return (
    <>
      <Link className="PropertyItem" to={`/property/${id}`}>
        <Favorite queryObj={queryObj} property_id={id} is_favorite={is_favorite} />

        {is_new ? <div className="new-label">NEW</div> : null}
        {thumbnail ? (
          <div className="thumbnail" style={{ backgroundImage: `url('${thumbnail}')` }}>
            {creator && is_shared ? (
              <button className="owner-btn" onClick={(e) => handleOpenOwner(e)}>
                <i className="mdi mdi-account"></i>
              </button>
            ) : null}
          </div>
        ) : (
          <div className="thumbnail-placeholder">
            <div className="image" />
            {creator && is_shared ? (
              <button className="owner-btn" onClick={(e) => handleOpenOwner(e)}>
                <i className="mdi mdi-account"></i>
              </button>
            ) : null}
          </div>
        )}

        <div className="body-content">
          <div className="name" title={name}>
            {name}
          </div>
          <div className="info">
            {/\S/.test(country) && (
              <div className="row">
                <label>Country:</label>
                <div className="content" title={country}>
                  {country}
                </div>
              </div>
            )}
            {/\S/.test(market) && (
              <div className="row">
                <label>Market:</label>
                <div className="content" title={market}>
                  {market}
                </div>
              </div>
            )}
            {/\S/.test(property_type) && (
              <div className="row">
                <label>Type:</label>
                <div className="content" title={property_type}>
                  {property_type}
                </div>
              </div>
            )}
            {/\S/.test(owner) && (
              <div className="row">
                <label>Owner:</label>
                <div className="content" title={owner}>
                  {owner}
                </div>
              </div>
            )}
            {/\S/.test(loan) && (
              <div className="row">
                <label>Loan:</label>
                <div className="content" title={loan}>
                  {loan}
                </div>
              </div>
            )}
            {/\S/.test(deal_name) && (
              <div className="row">
                <label>Deal name:</label>
                <div className="content" title={deal_name}>
                  {deal_name}
                </div>
              </div>
            )}
          </div>
        </div>
      </Link>
      <PopupboxContainer />
    </>
  )
}

export default withRouter(PropertyItem);


export function OwnerContent(props) {
  const [uniqId, setUniqId] = useState(props.uniqId)
  useEffect(() => {
    setUniqId(props.uniqId)
  }, [props.uniqId])
  return (
    <div key={uniqId}>
      Name: {props.creator.name}
      <br />
      Email: {props.creator.email}
      <br />
    </div>
  )
}