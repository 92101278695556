import React, { Component } from "react"
import "./VerifyEmail.scss"
import { withRouter } from "react-router-dom"
import { VERIFY_EMAIL_GQL } from "queries/sign_up"
import { Link } from "react-router-dom"
import { withApollo } from "react-apollo"

class VerifyEmail extends Component {
  state = {
    status: "pending",
    message: "",
  }

  componentDidMount() {
    this.verifyEmail()
  }

  verifyEmail() {
    this.props.client.mutate({
      mutation: VERIFY_EMAIL_GQL,
      variables: {
        input: {
          code: this.props.code,
        },
      },
      update: (cache, { data: { verify_email } }) => {
        if (verify_email.success) {
          this.setState({ status: "verified" })
        } else {
          this.setState({ message: verify_email.message, status: "failed" })
        }
      },
    })
  }

  renderError() {
    return <p>Error!!! Can't load subscription plans. Please contact our admin for more details.</p>
  }

  render() {
    return (
      <div className="VerifyEmail">
        {this.state.status === "pending" ? (
          <div className="verify-email-content text-center">
            <h2>Your email has been verifying...</h2>
            <br />
            <p>
              Or contact <a href="mailto:support@assetbase.com.sg">support@assetbase.com.sg</a> if you require further
              assistance.
            </p>
            <br />
            <br />
          </div>
        ) : this.state.status === "verified" ? (
          <div className="verify-email-content text-center">
            <h2>Your email has been successfully verified</h2>
            <br />
            <br />
            <p>Welcome to AssetBase. Your account is verified and ready for you to use. Please login to continue.</p>
            <p>
              Or contact <a href="mailto:support@assetbase.com.sg">support@assetbase.com.sg</a> if you require further
              assistance.
            </p>
            <br />
            <br />
            <div>
              <Link className="ThemeButton btn-log-in" to="/sign-in">
                Log In
              </Link>
            </div>
            <br />
            <br />
          </div>
        ) : this.state.status === "failed" ? (
          <div className="verify-email-content text-center">
            <h2>Verification Failed</h2>
            <br />
            <p>
              Please contact <a href="mailto:support@assetbase.com.sg">support@assetbase.com.sg</a> if you require
              further assistance.
            </p>
            <br />
            <br />
          </div>
        ) : null}
      </div>
    )
  }
}

export default withRouter(withApollo(VerifyEmail))
