import React from 'react'
import _ from 'lodash'

function getFolderTree(assets, currentFolder) {
  if (!currentFolder) return []

  const folders = assets.filter((f) => f.file_type === "directory")

  let current = currentFolder
  let tree = [current]
  while (current) {
    current = folders.find((f) => String(f.id) === String(current.asset_id))
    if (current) {
      tree.unshift(current)
      if (!current.asset_id) {
        break
      }
    }
  }
  return tree
}

export default function NavBar(props){
  const { currentFolder, assets = [] } = props
  const folders = getFolderTree(assets, currentFolder)

  function handleClickUp() {
    if (!currentFolder) return false
    if (!currentFolder.asset_id) props.onChangeFolder(null)

    const parent = folders.find((f) => String(f.id) === String(currentFolder.asset_id))
    props.onChangeFolder(parent || null)
  }

  let renderingFolders = _.cloneDeep(folders)
  if (renderingFolders.length > 7) {
    renderingFolders.splice(1, renderingFolders.length - 7)
    renderingFolders[1].disabled = true
    renderingFolders[1].title = "..."
  }

  return (
    <div className="folder-nav-bar">
      <button className="up-btn" onClick={handleClickUp} disabled={!currentFolder}>
        <span className="fa fa-chevron-up"></span>
      </button>
      <button className="home-btn" onClick={() => props.onChangeFolder(null)}>
        <span className="fa fa-home"></span>
      </button>
      {renderingFolders.map((folder, index) => (
        <React.Fragment key={index}>
          <span>/</span>
          <button
            className="folder-btn"
            disabled={folder.disabled ? true : currentFolder ? currentFolder.id == folder.id : false}
            onClick={() => props.onChangeFolder(folder)}
          >
            {folder.title}
          </button>
        </React.Fragment>
      ))}
    </div>
  )
}