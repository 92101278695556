import React, { useState, useEffect } from 'react';
import './NoteStyle.scss';
import Button from 'components/commons/Button';
import ShowForm from './ShowForm';
import getThumbnailIcon from '../../thumbNailIcon'
import { getSmartTime } from 'utils';

const Note = (props) => {
  const { scrollToTourBook, refetchProperty, ...property } = props
  const { note } = property
  const [state, setState] = useState({
    showResult: note ? true : false
  })
  useEffect(() => {
    setState({
      ...state,
      showResult: note ? true : false
    })
  }, [note])
  return <div className="content-note">
    {state.showResult ? <ShowResult {...property} onEdit={() => setState({ showResult: false })} /> :
      <ShowForm onChangeNote={(flag) => {
        setState({ showResult: flag }); 
        refetchProperty();
      }} {...property} />}
  </div >
}

const ShowResult = (props) => {
  const { note, onEdit = () => { } } = props
  const handleEdit = (event) => {
    event.preventDefault();
    onEdit()
  }
  if (!note) return null
  return (
    <>
      <div className="content-section">
        <div className="content-text">
          <p> {note.content} </p>
          <div className="date">{getSmartTime(note.created_at)}</div>
        </div>
        {note.attachment_json.length > 0 && (
          <div className="show-attachment">
            {note.attachment_json.map((item, index) => (
              <div key={index} className={"attachment-upload"}>
                <a title={item.name} href={item.download_url} target="_blank">
                  {getThumbnailIcon(item)}
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="content-edit">
        <Button className="btn btn-dark-blue" onClick={handleEdit}>
          Edit
        </Button>
      </div>
    </>
  )
}

export default Note;
