import React from "react"
import logo from "assets/images/asset_base_logo_with_text.svg"

const SignUpHeader = (props) => {

  const isLoginPage = props.isSignInPage;
  const isTnC = props.isTnCPage;

  return (
    <div className="SignUpPageHeader">
      <div className="HeaderContent">
        <div className="HeaderLeft">
          <a href="https://assetbase.com.sg">
            <img alt="AssetBase" className="header-logo" src={logo} />
          </a>
        </div>
        <div className="HeaderRight">
          {isLoginPage ? (
            <a href="/sign-up" className="ThemeButton">
              Sign Up
            </a>
          ) : !isTnC ? (
            <a href="/sign-in" className="ThemeButton">
              Log In
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SignUpHeader
