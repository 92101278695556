import React from "react"
import config from "config"
import axios from "utils/axios"
import Dropzone from "react-dropzone"
import "./Documents.scss"
import FooterSection from "../FooterSection"
import downloadIcon from "../../../assets/images/download-icon.png"
import { PopupboxManager, PopupboxContainer } from "react-popupbox"
import Input from "components/commons/Input"
import { toast } from "react-toastify"
import SweetAlert from "sweetalert2-react"
import { isMobile } from "react-device-detect"

import { withRouter } from "react-router-dom"
import { getLocalDate, toMb } from "utils"

//activestorage
import * as ActiveStorage from "activestorage"
ActiveStorage.start()

class Documents extends React.Component {
  state = {
    width: window.innerWidth,
    propertyId: this.props.propertyId,
    files: [],
    sorts: [],
    categories: [],
    categoryActiveId: null,
    categoryActive: null,
    editingCategory: {
      name: "",
      id: null,
      parentId: null,
    },
    updating: false,
    errors: {},
    // drag drop files
    progresses: {},
    filesEnter: false,
    tmpFiles: [],
    toggleBox: false,
    showDetailCategoryMobileForm: false,
    showRemoveCategoryForm: false,
  }

  updateDimensions() {
    let showDetailCategoryMobileForm = this.state.showDetailCategoryMobileForm
    if (this.state.width > 777) {
      showDetailCategoryMobileForm = false
    }
    this.setState({ width: window.innerWidth, showDetailCategoryMobileForm })
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this))
  }

  componentWillMount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this))
    this.updateDimensions()
    this.loadCategories()
  }

  loadCategories(closeForm = false) {
    axios.get(`/api/property/${this.props.propertyId}/categories`).then((rs) => {
      if (rs.data.result == "ok") {
        this.setState(
          {
            categories: rs.data.data,
          },
          () => {
            if (this.state.categoryActiveId == null && rs.data.data.length > 0) {
              let firstCategory = rs.data.data[0]
              this.setState({
                categoryActive: firstCategory,
                categoryActiveId: firstCategory.id,
              })
            }
          },
        )
        if (closeForm) {
          PopupboxManager.close()
          this._updating = false
        }
      }
    })
  }

  addRootCategory() {
    this.setState(
      {
        editingCategory: {
          parentId: null,
          id: null,
          name: "",
        },
      },
      () => {
        this.showFormEdit()
      },
    )
  }

  getExtensionAndName(fileName) {
    let lastIndexOfDot = fileName.lastIndexOf(".")
    if (lastIndexOfDot < 0) {
      lastIndexOfDot = fileName.length
    }
    return {
      name: fileName.substring(0, lastIndexOfDot),
      extension: fileName.substring(lastIndexOfDot + 1, fileName.length).toUpperCase(),
    }
  }

  renderCategories(categories) {
    let _this = this
    let categoriesDom = categories.map((cat) => {
      return _this.renderCategoryItem(cat)
    })
    return <div className="category-items">{categoriesDom}</div>
  }

  handleCategoryClick(cat) {
    this.setState({ categoryActiveId: cat.id, categoryActive: cat })
  }

  renderCategoryItem(category) {
    let activeClass = this.state.categoryActiveId == category.id ? "active" : ""
    let iconAdd = null
    if (category.level < 4) {
      iconAdd = (
        <div className="add-icon" title="Add Folder" onClick={() => this.handleAddIconClick(category)}>
          <i className="mdi mdi-plus" />
        </div>
      )
    }
    return (
      <div key={`category-${category.id}`} className="category-item-wraper">
        <div
          className={`category-item ${activeClass}${isMobile ? " is-mobile" : " is-not-mobile"}`}
          onClick={() => this.handleCategoryClick(category)}
        >
          <i className="mdi mdi-folder folder-icon" />
          <div className={`category-name ${iconAdd == null ? "big" : ""}`} title={category.name}>
            {category.name}
          </div>
          <div className={`actions-wraper${iconAdd == null ? " small" : ""}${isMobile ? " is-mobile" : ""}`}>
            <div className="edit-icon" title="Edit Name" onClick={() => this.handleEditIconClick(category)}>
              <i className="mdi mdi-pencil" />
            </div>
            {iconAdd}
            <div
              title="View Files"
              className="detail-icon-wraper"
              onClick={() => this.showDetailCategoryPopup(category)}
            >
              <i className="mdi mdi-cloud-upload detail-icon" />
            </div>
            <div
              title="Remove Folder"
              className="delete-cat-icon-wraper"
              onClick={() => {
                this.setState({
                  showRemoveCategoryForm: true,
                  selectCategoryToDelete: category,
                })
              }}
            >
              <i className="mdi mdi-delete" />
            </div>
          </div>
        </div>
        {this.renderSubCategories(category)}
      </div>
    )
  }

  handleAddIconClick(cat) {
    this.setState(
      {
        categoryActiveId: cat.id,
        editingCategory: {
          id: null,
          parentId: cat.id,
          name: "",
        },
      },
      () => this.showFormEdit(),
    )
  }

  handleEditIconClick(cat) {
    this.setState(
      {
        categoryActiveId: cat.id,
        editingCategory: {
          id: cat.id,
          parentId: cat.id,
          name: cat.name,
        },
      },
      () => this.showFormEdit(),
    )
  }

  renderSubCategories(category) {
    if (category.children.length == 0) return null
    let _this = this
    let subCategories = category.children.map((cat) => {
      return _this.renderCategoryItem(cat)
    })
    return <div className="subcategories-wraper">{subCategories}</div>
  }

  handleChangeName(name) {
    let record = this.state.editingCategory
    record.name = name
    this.setState({
      editingCategory: record,
    })
  }

  showFormEdit() {
    let record = this.state.editingCategory
    let content = (
      <div>
        <Input
          key={Date.now()}
          ref={(node) => {
            this.txtCategoryName = node
          }}
          id="txtCategory"
          className="txtCategory"
          style={{ width: "100%" }}
          value={record.name}
          onChange={(value) => this.handleChangeName(value)}
          error={this.state.errors["name"]}
          inputHtml={{
            autoFocus: true,
            onKeyUp: (event) => {
              if (event.key === "Enter") this.saveFormEdit()
            },
          }}
        />
        <div className="save-category-btn-wraper">
          <div
            onClick={() => this.saveFormEdit()}
            className="btn-dark-blue save-category-btn"
            ref={(node) => (this.saveFormEditBtn = node)}
          >
            {record.id == null ? "Add" : "Save"}
          </div>
        </div>
      </div>
    )

    PopupboxManager.open({
      content,
      config: {
        overlayClose: true,
        titleBar: {
          enable: true,
          text: record.id == null ? "Add New Folder" : "Edit Folder Name",
        },
      },
    })
  }

  saveFormEdit() {
    if (this._updating) return
    this._updating = true

    let record = this.state.editingCategory
    let params = {
      category_id: record.id,
      name: record.name,
      parent_id: record.parentId,
      property_id: this.state.propertyId,
    }

    axios.post("api/update-category", { ...params }).then(
      (rs) => {
        if (rs.data.result == "ok") {
          this.loadCategories(true)
          this.tempInput.focus()
          this.setState({
            categoryActive: rs.data.data,
            categoryActiveId: rs.data.data.id,
          })
        }
      },
      (err) => {
        this._updating = false
        toast.error(err.response.data.errors.category.join())
      },
    )
  }

  onDrop(acceptedFiles) {
    this.setState({ filesEnter: false })
    let currentState = { ...this.state }
    let params = {
      category_id: currentState.categoryActiveId,
      property_id: currentState.propertyId,
    }
    const files = acceptedFiles.filter((f) => this.validateFileSize(f) && this.validateFileType(f))
    const invalidFiles = acceptedFiles.filter((f) => !this.validateFileSize(f))
    const invalidFileType = acceptedFiles.filter((f) => !this.validateFileType(f))
    this.toastInvalidFiles(invalidFiles)
    this.toastInvalidFileType(invalidFileType)

    let signed_ids = []
    if (files.length == 0) {
      this.setState({ filesEnter: false, loading: false })
      return
    }
    this.setState(
      {
        fileBlobs: {},
        loading: true,
      },
      () => {
        let countFinish = 0
        files.map((file, index) => {
          const upload = new ActiveStorage.DirectUpload(file, config.direct_upload_url, {
            directUploadWillStoreFileWithXHR: (xhr) => {
              xhr.upload.addEventListener("progress", (event) => this.uploadRequestDidProgress(event, index))
            },
          })
          upload.create((err, blob) => {
            countFinish += 1
            if (err) {
              console.log(err)
            } else {
              signed_ids.push(blob.signed_id)
              this.state.fileBlobs[blob.signed_id] = blob

              if (countFinish == files.length) {
                params["signed_ids"] = signed_ids
                axios.post("api/category/upload-files", { ...params }).then((rs) => {
                  if (rs.data.result == "ok") {
                    this.setState({
                      categoryActive: rs.data.data,
                      loading: false,
                      filesEnter: false,
                    })
                    this.loadCategories()
                  }
                })
              }
            }
          })
        })
      },
    )
  }

  uploadRequestDidProgress(event, index) {
    const progress = (event.loaded / event.total) * 100
    let progresses = this.state.progresses
    progresses[index] = progress
    this.setState({
      progresses: { ...progresses },
    })
  }

  validateFileSize(file) {
    let valid = true
    valid = valid && config.maxFileSizeUpload > file.size

    if (this.props.remaining_bytes) {
      valid = valid && this.props.remaining_bytes > file.size
    } else {
      valid = false
    }
    return valid
  }

  toastInvalidFiles(files) {
    if (files.length > 0) {
      files.map((f) => {
        if (f.size > config.maxFileSizeUpload) {
          toast.error(
            `Large file is not allowed
          ${f.name} - ${toMb(f.size)}Mb (maximum ${toMb(config.maxFileSizeUpload)}Mb)`,
          )
        } else if (f.size > this.props.remaining_bytes) {
          toast.error(
            `You have exceeded the data limit for this property. Please either delete files to free up space or create a new property to continue adding file.`,
          )
        }
      })
    }
  }

  validateFileType(file) {
    return !!file.type
  }

  toastInvalidFileType(files) {
    if (files.length > 0) {
      files.map((file) => {
        if (!file.type) {
          toast.error("Folder cannot be uploaded")
        } else {
          toast.error("Format are not allowed to upload")
        }
      })
    }
  }

  showMessageRejectedFile(files) {
    if (files.length > 0) {
      files.map((f) =>
        toast.error(
          `Format is not allowed
					${f.name}`,
        ),
      )
    }
  }

  renderDropzone() {
    let category = this.state.categoryActive
    if (category == null) {
      return <div className="no-content-view">No Content</div>
    }
    let files = category.files
    let rows = this.renderTable(files, category)
    let isHasRow = files.length > 0 || category.children.length > 0
    const { tmpFiles, filesEnter } = this.state
    const loading = this.state.loading
    return (
      <Dropzone
        ref={(node) => {
          this.dropzoneRef = node
        }}
        disableClick={true}
        className={`uploader-container${filesEnter ? " active" : ""}`}
        multiple={true}
        disabled={loading}
        onDragEnter={() => this.setState({ filesEnter: true })}
        onDragLeave={() => this.setState({ filesEnter: false })}
        onDrop={(acceptedFiles, rejectedFiles) => {
          this.setState({ filesEnter: false }, () => {
            this.showMessageRejectedFile(rejectedFiles)
            this.onDrop(acceptedFiles)
          })
        }}
      >
        <div className={`overlay-dropzone${filesEnter ? " active" : ""}${isHasRow ? "" : " no-file"}`}></div>
        <h3 className="category-name">
          {category.name}
          <div
            className="btn-dark-blue add-new-files-icon"
            onClick={() => {
              this.dropzoneRef.open()
            }}
          >
            Add Files
          </div>
          <div
            className="close-popup-icon"
            onClick={() => {
              this.setState({ showDetailCategoryMobileForm: false })
            }}
          >
            x
          </div>
        </h3>
        <div className="category-file-list">
          {rows}
          {this.renderInstructionText(category)}
        </div>
      </Dropzone>
    )
  }

  renderTable(files, cat) {
    let folderList = this.sortList(cat.children).map((child, index) => {
      return (
        <div key={`folder-${child.id}`} className={`item-row${index == 0 ? " first-row" : ""}`}>
          <div className="cell type-cell">
            <i className="mdi mdi-folder folder-icon" />
          </div>
          <div className="cell file-name" title={child.name}>
            {child.name}
          </div>
          <div className="cell size-cell">{toMb(this.calTotalSizeOfFolder(child))} MB</div>
          <div className="cell date-cell">{getLocalDate(child.updated_at, "date")}</div>
          <div className="cell actions is-folder" title="Remove Folder">
            <i
              className="mdi mdi-delete remove-icon"
              aria-hidden="true"
              onClick={() => {
                this.setState({
                  showRemoveCategoryForm: true,
                  selectCategoryToDelete: child,
                })
              }}
            />
          </div>
        </div>
      )
    })
    let fileList = this.sortList(files).map((file, index) => {
      let nameAndExt = this.getExtensionAndName(file.name)
      return (
        <div
          key={`file-${file.signed_id}`}
          className={`item-row${index == 0 && cat.children.length == 0 ? " first-row" : ""}`}
        >
          <div className="cell type-cell">{nameAndExt.extension}</div>
          <div className="cell file-name" title={nameAndExt.name}>
            {nameAndExt.name}
          </div>
          <div className="cell size-cell">{toMb(file.size)} MB</div>
          <div className="cell date-cell">{getLocalDate(file.uploaded_at, "date")}</div>
          <div className="cell actions">
            <i
              title="Remove File"
              className="mdi mdi-delete remove-icon"
              aria-hidden="true"
              onClick={() => this.openConfirmRemovePopup(file)}
            />
            <a
              className="download-icon"
              href={`${config.backend_host}/api/download-file/${file.signed_id}`}
              download
              title="Download File"
            >
              <i className="mdi mdi-arrow-down-bold-box" />
            </a>
          </div>
        </div>
      )
    })
    return (
      <div className={`category-file-table${files.length + cat.children.length > 0 ? "" : " hidden"}`}>
        <div className="tbl-header item-row">
          <div className="cell type-cell">Type</div>
          <div
            className="cell sortable file-name"
            onClick={() =>
              this.setSortOnColumn("name", (a, b) => {
                return this.getExtensionAndName(a.name).name > this.getExtensionAndName(b.name).name ? -1 : 1
              })
            }
          >
            <span>Name</span>
            {this.showSortIconOnColumn("name")}
          </div>
          <div
            className="cell sortable size-cell"
            onClick={() =>
              this.setSortOnColumn("size", (a, b) => {
                if (a.size) {
                  return a["size"] > b["size"] ? -1 : 1
                } else {
                  return this.calTotalSizeOfFolder(a) > this.calTotalSizeOfFolder(b) ? -1 : 1
                }
              })
            }
          >
            <span>File Size</span>
            {this.showSortIconOnColumn("size")}
          </div>
          <div
            className="cell sortable date-cell"
            onClick={() =>
              this.setSortOnColumn("uploaded_at", (a, b) => {
                if (a.updated_at) {
                  return a["updated_at"] > b["updated_at"] ? -1 : 1
                } else {
                  return a["uploaded_at"] > b["uploaded_at"] ? -1 : 1
                }
              })
            }
          >
            <span>Uploaded</span>
            {this.showSortIconOnColumn("uploaded_at")}
          </div>
          <div className="cell actions">Actions</div>
        </div>
        {folderList}
        {fileList}
      </div>
    )
  }

  setSortOnColumn(column, sortFunc) {
    const { sorts } = this.state
    let sort = sorts.find((e) => e.key === column)
    if (sort) {
      sort = {
        ...sort,
        direction: sort.direction === "asc" ? "desc" : "asc",
      }
    } else {
      sort = {
        key: column,
        direction: "asc",
        sortFunction: sortFunc || ((a, b) => (a[column] > b[column] ? -1 : 1)),
      }
      sorts.push(sort)
    }

    this.setState({ sorts: [sort] })
  }

  showSortIconOnColumn(column) {
    const { sorts } = this.state
    let sort = sorts.find((e) => e.key === column)
    if (sort) {
      return <span className={`sort-icon fa fa-${sort.direction === "asc" ? "arrow-down" : "arrow-up"}`} />
    } else {
      return null
    }
  }

  sortList(items) {
    if (this.state.sorts.length > 0) {
      let newList = [...items]
      this.state.sorts.forEach((sort) => {
        newList = newList.sort(sort.sortFunction)
        if (sort.direction == "desc") {
          newList = newList.reverse()
        }
      })
      return newList
    }

    return items
  }

  calTotalSizeOfFolder(cat) {
    let rs = 0
    cat.files.forEach((file) => {
      rs += file.size
    })
    cat.children.forEach((child) => {
      rs += this.calTotalSizeOfFolder(child)
    })
    return rs
  }

  renderInstructionText(cat) {
    let files = cat.files
    if (files.length > 0 || cat.children.length > 0) return null
    return (
      <div
        className="intrustion-text"
        onClick={() => {
          this.dropzoneRef.open()
        }}
      >
        Upload or Drag and drop your files here, maximum 2GB.
        <br />
        <i className="fa fa-plus-circle fa-3x" />
      </div>
    )
  }

  openConfirmRemovePopup(file) {
    this.setState({
      toggleBox: true,
      selectFileToDelete: file,
    })
  }

  renderConfirmDeleteFile() {
    return (
      <SweetAlert
        show={this.state.toggleBox}
        title="Confirm Deletion"
        type="warning"
        text="Are you sure you would like to remove this file?"
        confirmButtonText="Yes"
        showCancelButton
        cancelButtonText="No"
        onConfirm={() => this.removeFile()}
        onCancel={() => this.setState({ toggleBox: false, selectFileToDelete: null })}
      />
    )
  }

  renderConfirmDeleteCategory() {
    return (
      <SweetAlert
        show={this.state.showRemoveCategoryForm}
        title="Confirm Deletion"
        type="warning"
        text="Are you sure you would like to remove this folder?"
        confirmButtonText="Yes"
        showCancelButton
        cancelButtonText="No"
        onConfirm={() => this.removeFolder()}
        onCancel={() =>
          this.setState({
            showRemoveCategoryForm: false,
            selectCategoryToDelete: null,
          })
        }
      />
    )
  }

  removeFile() {
    this.setState({ loading: true, toggleBox: false })
    let signed_id = this.state.selectFileToDelete.signed_id
    let category_id = this.state.categoryActiveId
    let property_id = this.props.propertyId
    axios.post("api/remove-file", { signed_id, category_id, property_id }).then(
      (rs) => {
        this.setState({
          selectFileToDelete: null,
          loading: false,
          categoryActive: rs.data.data,
        })
        this.loadCategories(true)
      },
      (err) => {
        this._updating = false
        toast.error(err.response.data.errors.remove_file.join())
        this.setState({ selectFileToDelete: null, loading: false })
      },
    )
  }

  showDetailCategoryPopup(category) {
    let record = this.state.editingCategory
    this.setState({
      categoryActiveId: category.id,
      categoryActive: category,
      showDetailCategoryMobileForm: true,
    })
  }

  removeFolder() {
    this.setState({ loading: true, showRemoveCategoryForm: false })
    let category_id = this.state.selectCategoryToDelete.id
    let property_id = this.props.propertyId
    axios.post("api/remove-category", { category_id, property_id }).then(
      (rs) => {
        let categoryActive = rs.data.data
        let categoryActiveId = null

        if (categoryActive != null) {
          categoryActiveId = categoryActive.id
        }
        this.setState({
          selectCategoryToDelete: null,
          loading: false,
          categoryActive,
          categoryActiveId,
        })
        this.loadCategories(true)
      },
      (err) => {
        this._updating = false
        toast.error(err.response.data.errors.remove_category.join())
        this.setState({ selectCategoryToDelete: null, loading: false })
      },
    )
  }

  render() {
    let categories = this.state.categories
    const { showDetailCategoryMobileForm, loading } = this.state

    return (
      <div className="document-commponent">
        <div className="header-section">
          <div className="title">Documents & Data</div>
        </div>
        <input
          type="input"
          ref={(node) => (this.tempInput = node)}
          style={{ height: 0, width: 0, position: "fixed", left: "-100px" }}
        />
        <PopupboxContainer />
        {loading && (
          <div className="loading-center">
            <span className="fa fa-spin fa-spinner" />
          </div>
        )}
        <div className={["category-list", loading ? "disabled-overlay" : ""].join(" ")}>
          <div className={`category-tree${isMobile ? " is-mobile" : ""}`}>
            <div onClick={() => this.addRootCategory()} className="btn-dark-blue add-category-btn">
              Add New Folder
            </div>
            <div className="scroll-wraper">{this.renderCategories(categories)}</div>
          </div>

          <div
            className={`view-desktop category-detail${loading ? " file-entered" : ""}${isMobile ? " is-mobile" : ""}`}
          >
            {this.renderDropzone()}
          </div>
          <div
            className={`view-mobile category-detail${loading ? " file-entered" : ""}`}
            style={{ display: showDetailCategoryMobileForm ? "flex" : "none" }}
          >
            {this.renderDropzone()}
            <div className="detail-category-overlay" />
          </div>
        </div>
        <FooterSection
          disabled={loading}
          canNext={false}
          doFinish={() => this.props.history.push(`/property/${this.props.propertyId}`)}
          doBack={() => this.props.backToPreviousSection()}
          style={{ maxWidth: "100%" }}
        />
        {this.renderConfirmDeleteFile()}
        {this.renderConfirmDeleteCategory()}
      </div>
    )
  }
}

export default withRouter(Documents)
