import React from 'react';

export class SearchBox extends React.Component {
  state = {
    keyword: ''
  };

  handleOnChange = event => {
    const { onSearch = () => {} } = this.props;

    this.setState(
      {
        keyword: event.target.value
      },
      () => {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          onSearch(this.state.keyword);
        }, 500);
      }
    );
  };

  render() {
    return (
      <div className="SearchBox">
        <input
          type="text"
          placeholder="Search for..."
          value={this.state.keyword}
          onChange={this.handleOnChange}
        />
        <div className="fa fa-search" />
      </div>
    );
  }
}

export default SearchBox;
