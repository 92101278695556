import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '@fengyuanchen/loader';

class ImageLoader extends Component {
  componentDidMount() {
    this.loader = new Loader(this.img, {
      width: 160,
      height: 160
    });
  }

  componentWillUnmount() {
    if (this.img) {
      delete this.img;
      delete this.loader;
    }
  }

  render() {
    const { src, alt, crossOrigin, className } = this.props;

    return (
      <img
        crossOrigin={crossOrigin}
        className={className}
        ref={img => {
          this.img = img;
        }}
        src={src}
        alt={alt === undefined ? 'picture' : alt}
      />
    );
  }
}

ImageLoader.propTypes = {
  crossOrigin: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string
};

ImageLoader.defaultProps = {
  src: null,
  crossOrigin: 'anonymous'
};

export default ImageLoader;
