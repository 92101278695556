import React, { Component } from 'react'
import ContactUs from 'components/ContactUs'

class ContactUsPage extends Component {
  render() {
    return (
      <div className="ContactUsPage">
        <ContactUs />
      </div>
    )
  }
}

export default ContactUsPage
