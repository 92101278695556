import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import './Favorite.scss';

export const MARK_FAVORITE_GQL = gql`
  mutation($input: MarkFavoriteInput!) {
    markFavorite(input: $input) {
      is_favorite
      errors
    }
  }
`;

export class  Favorite extends React.Component {

  state = {
    is_favorite: this.props.is_favorite || false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.is_favorite != this.state.is_favorite) {
      this.setState({
        is_favorite: nextProps.is_favorite
      })
    }
  }


  handleClick = mutate => {
    const  { property_id } = this.props
    mutate({
      variables: {
        input: {
          property_id
        }
      },
      update: (cache, { data: { markFavorite } }) => {
        const { is_favorite } = markFavorite;
        this.setState({is_favorite});
      }
    });
  };

  render() {
  return (
    <Mutation mutation={MARK_FAVORITE_GQL}>
      {(mutate, { loading }) => (
        <div
          className={`Favorite ${loading ? 'is-loading' : ''}`}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            !loading && this.handleClick(mutate);
          }}>
          {loading ? <span className="fa fa-spin fa-spinner" /> : null}
          { this.state.is_favorite ? (
            !loading && <span className="mdi mdi-heart" />
          ) : (
            !loading && <span className="mdi mdi-heart-outline" />
          )}
        </div>
      )}
    </Mutation>
  );
};
}

export default React.memo(Favorite);
