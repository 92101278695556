import React, { Component } from 'react';
import PdfFlipBook from 'components/PdfFlipBook';

export default class PdfViewPage extends Component {
  render() {
    return (
      <div className="PdfViewPage">
        <PdfFlipBook id={this.props.match.params.id} />
      </div>
    );
  }
}
