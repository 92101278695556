import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import ContentBox from 'components/commons/ContentBox';

class NoMatch extends Component {
  render() {
    return (
      <ContentBox title="404 Not found">
        <div className="NoMatch">
          <Link className="go-home" to={'/'}>
            Back to Home Page
          </Link>
        </div>
      </ContentBox>
    );
  }
}

export default withRouter(NoMatch);
