import React, { Component } from 'react';
import './UserSetting.scss';
import { withRouter } from 'react-router-dom';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import SweetAlert from 'sweetalert2-react';
import Button from 'components/commons/Button';
import SwitchButton from 'components/commons/SwitchButton';

export const UPDATE_USER_SETTING_GQL = gql`
  mutation($input: UpdateUserSettingInput!) {
    updateUserSetting(input: $input) {
      settings
    }
  }
`;

const GET_USER_SETTING_GQL = gql`
  query {
    user_settings {
      settings
    }
  }
`;

class UserSetting extends Component {

  submit(mutate, {daily_email}) {
    mutate({
      variables: {
        input: {
          daily_email
        }
      },
      update: (cache, { data: {updateUserSetting: { settings } }}) => {
        cache.writeQuery({
          query: GET_USER_SETTING_GQL,
          data: {
            user_settings: {settings}
          }
        });
      }
    });
  }

  render() {
    return (
        <Query query={GET_USER_SETTING_GQL}>
          {({ loading: loadingQuery, error: errorQuery, data = {} }) => {
            if (loadingQuery || errorQuery) return null;
            const { user_settings = {} } = data;

            return (
              <div className="UserSetting">
                <div className="header-section">
                  <div className="title">USER SETTINGS</div>
                </div>
                <Mutation mutation={UPDATE_USER_SETTING_GQL}>
                  {(mutate, { loading, error, data = {} }) => {
                    return (
                      <div className="settings-list">
                        <div className="setting-row">
                          <div className="setting-name">
                            <div>
                              Daily email
                            </div>
                            <i>(Invitation emails will be sent daily)</i>
                          </div>
                          <SwitchButton
                            loading={loading}
                            checked={user_settings.settings.daily_email}
                            onChange={checked => this.submit(mutate, {daily_email: checked}) }
                          />
                        </div>
                      </div>
                    );
                  }}
                </Mutation>
              </div>
            );
          }}
        </Query>
    );
  }
}

export default withRouter(UserSetting);
