import React, { Component, useEffect, useState } from 'react';
import './ListProperty.scss';
import { withRouter } from 'react-router-dom';
import userAuth from 'utils/userAuth';
import myCookies from 'utils/myCookies';
import List from 'components/commons/List';
import { Mutation, Query } from 'react-apollo';
import PropertyItem from './PropertyItem';
import gql from 'graphql-tag';
import Button from 'components/commons/Button';
import Input from 'components/commons/Input';
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import Select, { createFilter } from 'react-select';
import SortProperty from './SortProperty';
const $ = window.$;

export const GET_LIST_PROPERTY_GQL = gql`
  query ($offset: Int, $limit: Int, $filter: PropertyFilterInput!, $sort: PropertySortInput!, $ownedType: String) {
    properties(filter: $filter, sort: $sort, offset: $offset, limit: $limit, ownedType: $ownedType) {
      id
      thumbnail
      name
      country
      market
      owner
      project_name
      loan
      deal_name
      stage
      property_type
      is_new
      is_favorite
      permissions
      creator {
        id
        name
        email
      }
      is_shared
    }
    propertyFilters {
      countries
      stages
      propertyTypes
      tags
      shared_by_users
    }
  }
`

class ListProperty extends Component {
  state = {
    noMoreData: false,
    filters: {
      country: [],
      owner: '',
      project_name: '',
      market: '',
      stages: [],
      propertyTypes: [],
      tags: [],
      shared_by_users: [],
    },
    sort: {
      created_at: 'DESC'
    },
    showPopup: false,
    numberFilters: 0,
    ownedType: 'all' // all | owned | shared
  };

  renderItem(item, queryObj) {
    return <PropertyItem {...item} queryObj={queryObj} />;
  }

  renderHtmlForFilter() {
    return <div className="filter-property">Filter Properties</div>;
  }

  renderFilterPopup() {
    return <PopupboxContainer />;
  }

  openFilterPopup({ countries, tags, stages, propertyTypes, shared_by_users }) {
    const content = (
      <div className="filters" id="filter-popup">
        <div className="filer-main-content">
          <div className="flex row al-center">
            <div className="label">Country:</div>
            <div className="input-container">
              <Select
                name="country"
                className="basic-multi-select"
                classNamePrefix="select"
                autoFocus={true}
                isMulti
                placeholder="Select country..."
                captureMenuScroll={false}
                menuShouldScrollIntoView={false}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base, state) => {
                    return {
                      ...base,
                      borderWidth: 1,
                      boxShadow: state.isFocused ? "none" : "",
                      borderColor: state.isFocused ? "#375fac !important" : "#DCDCDC",
                    }
                  },
                }}
                defaultValue={this.state.filters["country"]}
                onChange={(selected) => (this.state.filters["country"] = selected)}
                options={(countries || []).map((e) => ({
                  value: e,
                  label: e,
                }))}
                filterOption={createFilter({
                  ignoreCase: true,
                  ignoreAccents: true,
                  matchFrom: "start",
                })}
              />
            </div>
          </div>
          <div className="flex row al-center">
            <div className="label">Market:</div>
            <Input
              wrapperHtml={{
                className: "Input input-container",
              }}
              value={this.state.filters.market}
              onChange={(value) => (this.state.filters["market"] = value)}
              type="text"
            />
          </div>
          <div className="flex row al-center">
            <div className="label">Owner:</div>
            <Input
              wrapperHtml={{
                className: "Input input-container",
              }}
              value={this.state.filters.owner}
              onChange={(value) => (this.state.filters["owner"] = value)}
              type="text"
            />
          </div>
          <div className="flex row al-center">
            <div className="label">Project Name:</div>
            <Input
              wrapperHtml={{
                className: "Input input-container",
              }}
              value={this.state.filters.project_name}
              onChange={(value) => (this.state.filters["project_name"] = value)}
              type="text"
            />
          </div>
          <div className="flex row al-center">
            <label>Type:</label>
            <div className="input-container">
              <Select
                name="type"
                className="basic-multi-select"
                classNamePrefix="select"
                isMulti
                placeholder="Select type..."
                captureMenuScroll={false}
                menuShouldScrollIntoView={false}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base, state) => {
                    return {
                      ...base,
                      borderWidth: 1,
                      boxShadow: state.isFocused ? "none" : "",
                      borderColor: state.isFocused ? "#375fac !important" : "#DCDCDC",
                    }
                  },
                }}
                defaultValue={this.state.filters["propertyTypes"]}
                onChange={(selected) => (this.state.filters["propertyTypes"] = selected)}
                options={(propertyTypes || []).map((e) => ({
                  value: e,
                  label: e,
                }))}
                filterOption={createFilter({
                  ignoreCase: true,
                  ignoreAccents: true,
                  matchFrom: "start",
                })}
              />
            </div>
          </div>
          <div className="flex row al-center">
            <label>Stage:</label>
            <div className="input-container">
              <Select
                name="tag"
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select stage..."
                isMulti
                captureMenuScroll={false}
                menuShouldScrollIntoView={false}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base, state) => {
                    return {
                      ...base,
                      borderWidth: 1,
                      boxShadow: state.isFocused ? "none" : "",
                      borderColor: state.isFocused ? "#375fac !important" : "#DCDCDC",
                    }
                  },
                }}
                defaultValue={this.state.filters["stages"]}
                onChange={(selected) => (this.state.filters["stages"] = selected)}
                options={(stages || []).map((e) => ({
                  value: e,
                  label: e,
                }))}
              />
            </div>
          </div>
          <div className="flex row al-center">
            <div className="label">Tags:</div>
            <div className="input-container">
              <Select
                name="tag"
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select tags..."
                isMulti
                captureMenuScroll={false}
                menuShouldScrollIntoView={false}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base, state) => {
                    return {
                      ...base,
                      borderWidth: 1,
                      boxShadow: state.isFocused ? "none" : "",
                      borderColor: state.isFocused ? "#375fac !important" : "#DCDCDC",
                    }
                  },
                }}
                defaultValue={this.state.filters["tags"]}
                onChange={(selected) => (this.state.filters["tags"] = selected)}
                options={(tags || []).map((e) => ({
                  value: e,
                  label: e,
                }))}
              />
            </div>
          </div>
          <div className="flex row al-center">
            <div className="label">Shared By:</div>
            <div className="input-container">
              <Select
                name="shared_by"
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select users..."
                isMulti
                captureMenuScroll={false}
                menuShouldScrollIntoView={false}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base, state) => {
                    return {
                      ...base,
                      borderWidth: 1,
                      boxShadow: state.isFocused ? "none" : "",
                      borderColor: state.isFocused ? "#375fac !important" : "#DCDCDC",
                    }
                  },
                }}
                defaultValue={this.state.filters["shared_by_users"]}
                onChange={(selected) => (this.state.filters["shared_by_users"] = selected)}
                options={(shared_by_users || []).map((e) => ({
                  value: e[0],
                  label: e[1],
                }))}
              />
            </div>
          </div>
        </div>
        <div className="filter-footer flex row jc-center" style={{ marginTop: "15px" }}>
          <Button
            className="btn btn-dark-blue"
            onClick={() => {
              PopupboxManager.close()
              myCookies.setFilters(this.state.filters)
              this.setState({
                noMoreData: false,
                filters: { ...this.state.filters },
              })
            }}
          >
            Apply
          </Button>
          <div style={{ width: 15 }} />
          <Button
            className="btn btn-dark-blue"
            onClick={() => {
              PopupboxManager.close()
              myCookies.clearFilters()
              this.setState({
                noMoreData: false,
                filters: {
                  country: [],
                  owner: "",
                  project_name: "",
                  market: "",
                  propertyTypes: [],
                  stages: [],
                  tags: [],
                  shared_by_users: [],
                },
              })
            }}
          >
            Clear All
          </Button>
        </div>
      </div>
    )
    
    PopupboxManager.open({
      content: content,
      config: {
        overlayClose: false,
        titleBar: {
          enable: true,
          text: 'Filter By'
        },
        onComplete: () => {
          $('#root').addClass('noscroll');
        },
        onClosed: () => {
          $('#root').removeClass('noscroll');
          setTimeout(() => this.setState({ showPopup: false }), 200);
        }
      }
    });
  }

  getFilters() {
    if (myCookies.getFilters()) {
      this.state.filters = myCookies.getFilters();
    }
    const {
      country,
      market,
      owner,
      project_name,
      stages,
      tags,
      propertyTypes,
      shared_by_users = [],
    } = this.state.filters;
    let count = 0;
    count =
      (country.length > 0 ? 1 : 0) +
      (market !== "" ? 1 : 0) +
      (owner !== "" ? 1 : 0) +
      (project_name !== "" ? 1 : 0) +
      (propertyTypes.length > 0 ? 1 : 0) +
      (stages.length > 0 ? 1 : 0) +
      (shared_by_users.length > 0 ? 1 : 0) +
      (tags.length > 0 ? 1 : 0)
    this.state.numberFilters = count;
    return {
      country_in: country.map((e) => e["value"]),
      owner_contains: owner,
      project_name_contains: project_name,
      market_contains: market,
      property_type_in: propertyTypes.map((e) => e["value"]),
      stage_in: stages.map((e) => e["value"]),
      tag_in: tags.map((e) => e["value"]),
      creator_id_in: shared_by_users.map((e) => String(e["value"])),
    }
  }

  getSorts() {
    let sort = { ...this.state.sort };

    return sort;
  }
  render() {
    const variables = {
      offset: 0,
      limit: 9,
      filter: this.getFilters(),
      sort: this.getSorts(),
      ownedType: this.state.ownedType,
    }
    return (
      <div className="ListPropertyContainer">
        <PropertyListingTab ownedType={this.state.ownedType} onSelectTab={tab => this.setState({ownedType: tab})} />
        <Query query={GET_LIST_PROPERTY_GQL} variables={variables} fetchPolicy="cache-and-network">
          {({ loading, error, data, fetchMore, query }) => {
            if (error) return `Error! ${error.message}`
            const { properties = [], propertyFilters = {} } = data
            return (
              <div className="ListProperty">
                <div className="options">
                  {this.state.showPopup && this.renderFilterPopup()}
                  <Button
                    className="btn btn-dark-blue"
                    onClick={() => {
                      this.setState({ showPopup: true }, () => {
                        this.openFilterPopup(propertyFilters)
                      })
                    }}
                  >
                    Filter By
                    {this.state.numberFilters > 0 && (
                      <span className="has-filters-indicator">{this.state.numberFilters}</span>
                    )}
                  </Button>
                  <SortProperty onSelected={(sort) => this.setState({ sort: sort, noMoreData: false })} />
                </div>
                <List
                  className="list-property"
                  data={properties}
                  renderChildComponent={(e) =>
                    this.renderItem(e, {
                      query: GET_LIST_PROPERTY_GQL,
                      variables,
                    })
                  }
                  loading={loading}
                  reachEndCallback={() => {
                    if (this.state.noMoreData || loading) return
                    fetchMore({
                      variables: {
                        offset: properties.length,
                        filter: this.getFilters(),
                        sort: this.getSorts(),
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev
                        if (fetchMoreResult.properties.length == 0) {
                          this.setState({ noMoreData: true })
                          return prev
                        }
                        return Object.assign({}, prev, {
                          properties: [...prev.properties, ...fetchMoreResult.properties],
                        })
                      },
                    })
                  }}
                  loadingComponent={() => (
                    <div className="loading">
                      <i className="fa fa-spinner fa-spin fa-2x" />
                    </div>
                  )}
                />
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}


export function PropertyListingTab({ownedType, onSelectTab = () => {}}) {
  return (
    <div className="PropertyPageHeader">
      <div className="breadcrumb">
        <div className={`menu-item ${ownedType === "all" ? "active" : ""}`} onClick={() => onSelectTab("all")}>
          <span className="connector"> </span>
          All
        </div>

        <div className={`menu-item ${ownedType === "owned" ? "active" : ""}`} onClick={() => onSelectTab("owned")}>
          <span className="connector">{" | "}</span>
          My Listings
        </div>

        <div className={`menu-item ${ownedType === "shared" ? "active" : ""}`} onClick={() => onSelectTab("shared")}>
          <span className="connector">{" | "}</span>
          Shared to me
        </div>
      </div>
    </div>
  )
}

export default withRouter(ListProperty);