import React, { Component } from "react";
import SignInBox from "components/SignInBox";
import queryString from "query-string"

class SignInPage extends Component {

  render() {
    const query = queryString.parse(this.props.location.search)
    const { token } = query || {}

    return (
      <div className="SignInPage">
        <SignInBox token={token} />
      </div>
    )
  }
}

export default SignInPage;
