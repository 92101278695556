import gql from "graphql-tag";

export const GET_CURRENT_SUBSCRIPTION_GQL = gql`
  query GetCurrentSubscription {
    current_subscription {
      id
      name
      property_capability
      invite_user_capability
      price
      recurring_interval
      free_trial_days
      currency
      active
      status
      allowed_create_property
      property_count
      shared_user_count
      trial_days_left
      cancelled_at
      billing_cycle_end_at
      promo_code
    }
  }
`


export const GET_USER_SUBSCRIPTION_GQL = gql`
  query GetUserSubscription {
    current_subscription {
      id
      subscription_plan_id
      name
      property_capability
      invite_user_capability
      price
      recurring_interval
      free_trial_days
      currency
      active
      status
      allowed_create_property
      property_count
      shared_user_count
      trial_days_left
      cancelled_at
      billing_cycle_end_at
      promo_code
    }
    all_subscription_plans {
      id
      name
      property_capability
      recurring_interval
      invite_user_capability
      price
      free_trial_days
      currency
    }
  }
`



export const CHANGE_SUBSCRIPTION_PLAN_GQL = gql`
  mutation ChangeSubscriptionPlan($input: ChangeSubscriptionPlanInput!) {
    change_subscription_plan(input: $input) {
      success
      message
      errors
    }
  }
`

export const UPGRADE_SUBSCRIPTION_GQL = gql`
  mutation UpgradeSubscription($input: UpgradeSubscriptionInput!) {
    upgrade_subscription(input: $input) {
      success
      message
      type
      client_secret
      return_url
    }
  }
`

export const PREVIEW_CHANGE_SUBSCRIPTION_GQL = gql`
  mutation PreviewChangeSubscription($input: PreviewChangeSubscriptionInput!) {
    preview_change_subscription(input: $input) {
      success
      message
      changes
    }
  }
`


export const CANCEL_SUBSCRIPTION_GQL = gql`
  mutation CancelSubscription($input: CancelSubscriptionInput!) {
    cancel_subscription(input: $input) {
      success
      message
    }
  }
`
