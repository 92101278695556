import React, { Component } from 'react';
import './PdfFlipBook.scss';
import './magazine.scss';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter, Link } from 'react-router-dom';
import turnOgg from '../../assets/audio/turn.ogg';
import turnMp3 from '../../assets/audio/turn.mp3';
import turnWav from '../../assets/audio/turn.wav';
import { isMobile } from 'react-device-detect';

const $ = window.$;

const GET_PDF_GQL = gql`
  query($id: ID!) {
    pdf_book(id: $id) {
      id
      page_urls
      download_url
      property_id
    }
  }
`;

class PdfFlipBook extends Component {
  state = {
    pages: [],
    numPages: 0,
    loading: false,
    leftValue: 0,
    mute: false,
    fullscreen: false,
    currentPageIndex: 0,
    canFullscreen: false,
    display: 'double'
  };

  componentDidMount() {
    this.setState({
      canFullscreen: this.canFullscreen()
    });
  }

  adjustThumbnailOutline() {
    let center = $('.thumb-carousel-container').width() / 2;
    let currentThumb = $(`#page-thumb-${this.state.currentPageIndex}`);
    let pos = currentThumb.position();

    if (pos) {
      let leftValue = -pos.left + center;
      leftValue = leftValue > 0 ? 0 : leftValue;
      let minLeft =
        -1 *
        ($('.thumb-carousel').width() - $('.thumb-carousel-container').width());
      leftValue = leftValue < minLeft ? minLeft : leftValue;
      this.setState({
        leftValue
      });
    }
  }

  playSound() {
    if (!this.state.mute) {
      if (!this.audioPlayer) {
        this.audioPlayer = new window.Plyr('#audio');
        this.audioPlayer.volume = 1;
      }
      this.audioPlayer.currentTime = 0;
      this.audioPlayer.play();
    }
  }

  isFullScreenCurrently() {
    let full_screen_element =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement ||
      null;

    // If no element is in full-screen
    if (full_screen_element === null) return false;
    else return true;
  }

  fullscreenEvents() {
    $(document.body).on(
      'fullscreenchange webkitfullscreenchange mozfullscreenchange MSFullscreenChange',
      () => {
        if (this.isFullScreenCurrently()) {
          this.setState({ fullscreen: true });
        } else {
          this.setState({ fullscreen: false });
        }
      }
    );
  }

  renderAudio() {
    return (
      <audio ref={ref => (this.audio = ref)} id="audio">
        <source src={turnOgg} type="audio/ogg" />
        <source src={turnMp3} type="audio/mpeg" />
        <source src={turnWav} type="audio/wav" />
      </audio>
    );
  }

  zoomIn() {
    this.setState({ zoomIn: true }, () =>
      $('.magazine-viewport').zoom('zoomIn')
    );
  }

  zoomOut() {
    this.setState({ zoomIn: false }, () =>
      $('.magazine-viewport').zoom('zoomOut')
    );
  }

  normalZoom() {
    this.zoomOut();
  }

  toggleZoom() {
    this.setState({ zoomIn: !this.state.zoomIn }, () => {
      if (this.state.zoomIn) $('.magazine-viewport').zoom('zoomIn');
      else $('.magazine-viewport').zoom('zoomOut');
    });
  }

  renderOutline() {
    let leftValue = this.state.leftValue;
    const { pages = [] } = this.state;
    return (
      <div className={`outline-container`}>
        <div
          className="nav-button left"
          onClick={() => {
            let leftValue =
              this.state.leftValue +
              ($('.thumbnail-container').width() + 24) * 4;
            leftValue = leftValue > 0 ? 0 : leftValue;
            this.setState({ leftValue });
          }}>
          <span className="ctm-icon ctm-menu-left" />
        </div>
        <div className="thumb-carousel-container">
          <div
            className="thumb-carousel"
            style={{ transform: `translateX(${leftValue}px)` }}>
            {pages.map((thumb, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    this.setState({ currentPageIndex: index }, () =>
                      $('#flipbook').turn('page', index + 1)
                    );
                  }}
                  className={`thumbnail-container${
                    index == this.state.currentPageIndex ? ' active' : ''
                  }`}>
                  <img
                    id={`page-thumb-${index}`}
                    className="thumbnail"
                    height="60"
                    style={{ height: 60 }}
                    src={thumb}
                    alt={index}
                  />
                  <div className="page-number">{index + 1}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="nav-button right"
          onClick={() => {
            let leftValue =
              this.state.leftValue -
              ($('.thumbnail-container').width() + 24) * 4;
            let minLeft =
              -1 *
              ($('.thumb-carousel').width() -
                $('.thumb-carousel-container').width());
            leftValue = leftValue < minLeft ? minLeft : leftValue;
            this.setState({ leftValue });
          }}>
          <span className="ctm-icon ctm-menu-right" />
        </div>
      </div>
    );
  }

  toggleFullscreen() {
    if (this.state.fullscreen) this.exitFullscreeen();
    else this.makeFullscreen();
  }

  renderHeadNavigationBar(download_url, property_id) {
    return (
      <div className="head-nav-bar-container">
        <div className="head-nav-bar">
          <div className="options flex jc-start">
            <Link
              className="button"
              onClick={() => {
                this.exitFullscreeen();
              }}
              replace={true}
              to={`/property/${property_id}?scroll_to_tour_book=true`}>
              <span className="ctm-icon ctm-arrow-left" />
            </Link>
            <div className="zoom-section">
              <div
                className="group-btn zoom-out-btn"
                onClick={() => this.zoomOut()}>
                <i className="mdi mdi-minus" />
              </div>
              <div
                className="group-btn search-icon"
                onClick={() => this.normalZoom()}>
                <span className="mdi mdi-magnify-plus-outline" />
              </div>
              <div
                className="group-btn zoom-in-btn"
                onClick={() => this.zoomIn()}>
                <i className="mdi mdi-plus" />
              </div>
              <div
                className="group-btn toggle-zoom-btn"
                onClick={() => this.toggleZoom()}>
                <span
                  className={`mdi ${
                    this.state.zoomIn
                      ? 'mdi-magnify-minus-outline'
                      : 'mdi-magnify-plus-outline'
                  }`}
                />
              </div>
            </div>
          </div>
          <div className="navigations">
            <div
              className="button"
              onClick={() => $('#flipbook').turn('page', 1)}>
              <span className="ctm-icon ctm-double-chevron-left" />
            </div>
            <div
              className="button"
              onClick={() => $('#flipbook').turn('previous')}>
              <span className="ctm-icon ctm-chevron-left" />
            </div>
            <div className="button" onClick={() => $('#flipbook').turn('next')}>
              <span className="ctm-icon ctm-chevron-right" />
            </div>
            <div
              className="button"
              onClick={() => $('#flipbook').turn('page', this.state.numPages)}>
              <span className="ctm-icon ctm-double-chevron-right" />
            </div>
          </div>
          <div className="options flex jc-end">
            {this.state.canFullscreen && (
              <div
                className={`button fullscreen`}
                onClick={() => this.toggleFullscreen()}>
                <span
                  className={`ctm-icon ctm-fullscreen-circle${
                    !this.state.fullscreen ? ' expand' : ' collapse'
                  }`}
                />
              </div>
            )}
            <div
              className={`button sound`}
              onClick={() => this.setState({ mute: !this.state.mute })}>
              <span
                className={`ctm-icon ctm-speaker-circle${
                  this.state.mute ? ' mute' : ''
                }`}
              />
            </div>
            <div
              className="button"
              onClick={() => window.open(download_url, '_self')}>
              <span className="ctm-icon ctm-cloud" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="page-loader">
        <i className="fa fa-spin fa-spinner" style={{ fontSize: '50px' }} />
      </div>
    );
  }

  shouldNotHasGradient(index) {
    return (
      index == 0 || (index == this.state.pages.length - 1 && index % 2 == 0)
    );
  }

  addPage(page, book) {
    let id,
      pages = book.turn('pages');

    // Create a new element for this page
    let element = $('<div />', {});

    // Add the page to the flipbook
    if (book.turn('addPage', element, page)) {
      // Add the initial HTML
      // It will contain a loader indicator and a gradient
      element.html(
        '<div class="gradient"></div><div class="loader"><i class="fa fa-spin fa-spinner" /></div>'
      );

      // Load the page
      this.loadPage(page, element);
    }
  }

  loadPage(page, pageElement) {
    // Create an image element

    let img = $('<img />');

    img.mousedown(function(e) {
      e.preventDefault();
    });

    let component = this;
    img.on('load', function() {
      // Set the size
      $(this).css({ width: '100%', height: '100%', objectFit: 'contain' });

      // Add the image to the page after loaded

      $(this).appendTo(pageElement);

      // Remove the loader indicator

      pageElement.find('.loader').remove();

      component.resizeViewport();
    });

    // Load the page

    img.attr('src', this.state.pages[page - 1]);
  }

  resizeFlipbook() {
    let displayPage = $('#flipbook').turn('display') == 'single' ? 1 : 2;
    if ($('#flipbook').turn('zoom') == 1) {
      let image = document.querySelector('img');
      let height = $(window).height() - 170;
      let width = $(window).width() / displayPage - 40;
      if (image) {
        width = (image.naturalWidth / image.naturalHeight) * height;

        width = Math.min($(window).width() / displayPage - 40, width);
        height = (image.naturalHeight / image.naturalWidth) * width;
      }
      $('#flipbook').turn('size', width * displayPage, height);
    } else {
      let image = document.querySelector('img');
      if (image) {
        let height = image.naturalHeight;
        let width = image.naturalWidth;
        $('#flipbook').turn('size', width * displayPage, height);
      }
    }
  }

  // Zoom in / Zoom out

  zoomTo(event) {
    setTimeout(function() {
      if ($('.magazine-viewport').zoom('value') == 1) {
        $('.magazine-viewport').zoom('zoomIn', event);
      } else {
        $('.magazine-viewport').zoom('zoomOut');
      }
    }, 10);
  }

  initTurnjs() {
    $('#flipbook').turn({
      width: 1440,
      height: 620,
      duration: 1000,
      acceleration: true,
      gradients: true,
      autoCenter: true,
      elevation: 50,
      pages: this.state.pages.length,
      when: {
        turning: (event, page, view) => {
          let book = $('#flipbook'),
            currentPage = book.turn('page'),
            pages = book.turn('pages');

          this.playSound();
          this.setState({ currentPageIndex: page - 1 }, () =>
            this.adjustThumbnailOutline()
          );
        },

        turned: function(event, page, view) {
          $(this).turn('center');
          if (page == 1) {
            $(this).turn('peel', 'br');
          }
        },

        missing: (event, pages) => {
          for (let i = 0; i < pages.length; i++)
            this.addPage(pages[i], $('#flipbook'));
        }
      }
    });
  }

  initZoom() {
    $('.magazine-viewport').zoom({
      flipbook: $('#flipbook'),
      max: function() {
        let maxWidth = 2880;
        const multiple = isMobile ? 1 : 2;
        let image = document.querySelector('img');
        if (image) {
          maxWidth = image.naturalWidth * multiple;
        }
        return maxWidth / $('#flipbook').width();
      },

      when: {
        swipeLeft: function(event) {
          console.log('swipeLeft', event);
          $('#flipbook').turn('next');
        },

        swipeRight: function() {
          $('#flipbook').turn('previous');
        },

        resize: function(event, scale, page, pageElement) {},

        zoomIn: function() {
          $('.magazine')
            .removeClass('animated')
            .addClass('zoom-in');
          $('.zoom-icon')
            .removeClass('zoom-icon-in')
            .addClass('zoom-icon-out');

          if (!window.escTip && !$.isTouch) {
            window.escTip = true;

            $('<div />', { class: 'exit-message' })
              .html('<div>Press ESC to exit</div>')
              .appendTo($('body'))
              .delay(2000)
              .animate({ opacity: 0 }, 500, function() {
                $(this).remove();
              });
          }
        },

        zoomOut: () => {
          $('.exit-message').hide();
          $('.zoom-icon')
            .removeClass('zoom-icon-out')
            .addClass('zoom-icon-in');

          setTimeout(() => {
            $('.magazine')
              .addClass('animated')
              .removeClass('zoom-in');
            this.resizeViewport();
          }, 100);
        }
      }
    });

    if ($.isTouch) $('.magazine-viewport').bind('zoom.doubleTap', this.zoomTo);
    else $('.magazine-viewport').bind('zoom.tap', this.zoomTo);
  }

  makeFullscreen() {
    if (this.state.fullscreen) return;
    this.goInFullscreen(document.getElementById('pdfbook_wrapper'));
  }

  exitFullscreeen() {
    if (!this.state.fullscreen) return;
    this.goOutFullscreen();
  }

  goInFullscreen(element) {
    if (element.requestFullscreen) element.requestFullscreen();
    else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
    else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
    else if (element.msRequestFullscreen) element.msRequestFullscreen();
  }

  canFullscreen() {
    const element = document.getElementById('pdfbook_wrapper');
    return (
      element.requestFullscreen ||
      element.mozRequestFullScreen ||
      element.webkitRequestFullscreen ||
      element.msRequestFullscreen
    );
  }

  goOutFullscreen() {
    if (document.exitFullscreen) document.exitFullscreen();
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    else if (document.msExitFullscreen) document.msExitFullscreen();
  }

  resizeViewport() {
    if (!$('#flipbook') || !$('.magazine-viewport')) return;
    this.resizeFlipbook();
    let width = $(window).width(),
      height =
        $(window).height() -
        $('.outline-container').height() -
        $('.head-nav-bar-container').height(),
      options = $('.magazine').turn('options'),
      size = $('.magazine').turn('size');

    $('.magazine').removeClass('animated');

    $('.magazine-viewport')
      .css({
        width: width,
        height: height
      })
      .zoom('resize');

    if ($('.magazine').turn('zoom') == 1) {
      let bound = this.calculateBound({
        width: size.width,
        height: size.height,
        boundWidth: Math.min(size.width, width),
        boundHeight: Math.min(size.height, height)
      });

      if (bound.width % 2 !== 0) bound.width -= 1;

      if (
        bound.width != $('.magazine').width() ||
        bound.height != $('.magazine').height()
      ) {
        $('.magazine').turn('size', bound.width, bound.height);

        //if ($('.magazine').turn('page') == 1) $('.magazine').turn('peel', 'br');
      }

      $('.magazine').css({ top: -bound.height / 2, left: -bound.width / 2 });
    }

    $('.magazine').addClass('animated');
  }

  calculateBound(d) {
    let bound = { width: d.width, height: d.height };

    if (bound.width > d.boundWidth || bound.height > d.boundHeight) {
      let rel = bound.width / bound.height;

      if (
        d.boundWidth / rel > d.boundHeight &&
        d.boundHeight * rel <= d.boundWidth
      ) {
        bound.width = Math.round(d.boundHeight * rel);
        bound.height = d.boundHeight;
      } else {
        bound.width = d.boundWidth;
        bound.height = Math.round(d.boundWidth / rel);
      }
    }

    return bound;
  }

  initPdfBook() {
    if (this.state.numPages == 0) return;
    this.initTurnjs();
    this.initZoom();

    $('#flipbook').turn('page', 1);

    $(document).keydown(function(e) {
      let previous = 37,
        next = 39,
        esc = 27;

      switch (e.keyCode) {
        case previous:
          // left arrow
          $('.magazine').turn('previous');
          e.preventDefault();

          break;
        case next:
          //right arrow
          $('.magazine').turn('next');
          e.preventDefault();

          break;
        case esc:
          $('.magazine-viewport').zoom('zoomOut');
          e.preventDefault();

          break;
      }
    });

    $(window)
      .resize(() => {
        if ($('#flipbook') && $('.magazine-viewport')) this.resizeViewport();
      })
      .bind('orientationchange', () => {
        if ($('#flipbook') && $(window).width() > $(window).height()) {
          this.setState({ display: 'single' });
          $('#flipbook').turn('display', 'single');
        } else {
          this.setState({ display: 'double' });
          $('#flipbook').turn('display', 'double');
        }
        if ($('#flipbook') && $('.magazine-viewport')) this.resizeViewport();
      });

    if ($('#flipbook') && $(window).width() < $(window).height()) {
      this.setState({ display: 'single' });
      $('#flipbook').turn('display', 'single');
      this.resizeViewport();
    }

    $('.magazine-viewport').mousewheel(function(event) {
      if ($('.magazine-viewport').zoom('value') != 1) event.preventDefault();
    });
    this.navigateButtonEvents();
    this.resizeViewport();
    console.log('initing...');
  }

  navigateButtonEvents() {
    $('.next-button')
      .bind($.mouseEvents.over, function() {
        $(this).addClass('next-button-hover');
      })
      .bind($.mouseEvents.out, function() {
        $(this).removeClass('next-button-hover');
      })
      .bind($.mouseEvents.down, function() {
        $(this).addClass('next-button-down');
      })
      .bind($.mouseEvents.up, function() {
        $(this).removeClass('next-button-down');
      })
      .click(function() {
        $('.magazine').turn('next');
      });

    // Events for the previous button

    $('.previous-button')
      .bind($.mouseEvents.over, function() {
        $(this).addClass('previous-button-hover');
      })
      .bind($.mouseEvents.out, function() {
        $(this).removeClass('previous-button-hover');
      })
      .bind($.mouseEvents.down, function() {
        $(this).addClass('previous-button-down');
      })
      .bind($.mouseEvents.up, function() {
        $(this).removeClass('previous-button-down');
      })
      .click(function() {
        $('.magazine').turn('previous');
      });

    this.fullscreenEvents();
  }

  loadQuery() {
    return (
      <Query query={GET_PDF_GQL} variables={{ id: this.props.id }}>
        {({ data, error, loading }) => {
          if (loading) return this.renderLoading();
          if (loading || error) return null;
          const {
            pdf_book: { page_urls, download_url, property_id }
          } = data;

          this.setState(
            {
              pages: page_urls,
              numPages: page_urls.length,
              download_url,
              property_id
            },
            () => this.initPdfBook()
          );

          return null;
        }}
      </Query>
    );
  }

  componentWillUnmount() {
    $(window).off('resize');
    $(window).off('scroll');
    $(window).off('orientationchange');
  }

  render() {
    if (!this.props.id) this.props.history.push('/no-match');
    return (
      <div className="PdfFlipbook" id="pdfbook_wrapper">
        {this.renderHeadNavigationBar(
          this.state.download_url,
          this.state.property_id
        )}
        <div className="pdf-flipbook">
          <div className={`magazine-viewport ${this.state.display}`}>
            {this.state.numPages == 0 && this.loadQuery()}
            <div className="container">
              <div id="flipbook" className="magazine">
                <div ignore="1" className="next-button" />
                <div ignore="1" className="previous-button" />
              </div>
            </div>
          </div>
          {this.renderOutline()}
        </div>
        {this.renderAudio()}
      </div>
    );
  }
}

export default withRouter(PdfFlipBook);
