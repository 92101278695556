import React from 'react';
import CheckBox from 'components/commons/CheckBox';

export default class PermissionOption extends React.Component {
  state = {
    permission: this.props.permission || 'can_view'
  };

  static defaultProps = {
    onChange: () => {},
    canEdit: false
  };

  render() {
    return (
      <div className="PermissionOption">
        <div
          className={`res-can-edit${
            this.props.canEdit ? ' visible' : ' invisible'
          }`}>
          Edit & View
        </div>
        <div
          className={`can-edit flex al-center${
            this.props.canEdit ? ' visible' : ' invisible'
          }`}>
          <CheckBox
            radio={true}
            label=""
            disabled={!this.props.canEdit}
            onChange={checked => {
              if (!checked) {
                this.setState({ permission: 'can_edit' }, () =>
                  this.props.onChange(this.state.permission)
                );
              }
            }}
            checked={this.state.permission == 'can_edit'}
          />
        </div>
        <div className="res-can-view">View Only</div>
        <div className="can-view flex al-center">
          <CheckBox
            radio={true}
            label=""
            onChange={checked => {
              if (!checked) {
                this.setState({ permission: 'can_view' }, () =>
                  this.props.onChange(this.state.permission)
                );
              }
            }}
            checked={this.state.permission == 'can_view'}
          />
        </div>
      </div>
    );
  }
}
