import React, { Component } from 'react';
import './ForgotPassword.scss';
import { withRouter } from 'react-router-dom';

import ContentBox from 'components/commons/ContentBox';
import Button from 'components/commons/Button';
import Input from 'components/commons/Input';
import { Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import SweetAlert from 'sweetalert2-react';
import config from 'config';

export const SUBMIT_FORGOT_PASSWORD_GQL = gql`
  mutation($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      success
      errors
    }
  }
`;

class ForgotPassword extends Component {
  state = {
    showSuccess: false,
    email: '',
    errors: []
  };

  submit(mutate) {
    this.setState({ errors: [] });
    const { email } = this.state;
    const reset_link = `${config.frontend_host}/reset-password`;
    mutate({
      variables: {
        input: {
          email,
          reset_link
        }
      },
      update: (cache, { data: { forgotPassword } }) => {
        if (!forgotPassword.success) {
          this.setState({
            errors: forgotPassword.errors
          });
        } else {
          this.setState({ showSuccess: true });
        }
      }
    });
  }

  renderErrors(errors = []) {
    return errors.map((err, index) => (
      <div key={index} className="err-message">
        {err}
      </div>
    ));
  }

  renderSuccessBox() {
    return (
      <SweetAlert
        show={this.state.showSuccess}
        title="Success"
        type="success"
        text="An email has been sent to you with instructions on how to reset your password."
        onConfirm={() =>
          this.setState({ showSuccess: false }, () => {
            this.props.history.push('/sign-in');
          })
        }
      />
    );
  }

  render() {
    return (
      <div className="ForgotPassword">
        <Mutation mutation={SUBMIT_FORGOT_PASSWORD_GQL}>
          {(mutate, { loading }) => (
            <ContentBox title="Forgot Password">
              {this.renderErrors(this.state.errors)}
              <div className="box-inputs">
                <Input
                  className="input-email"
                  onChange={(value) => this.setState({ email: value })}
                  value={this.state.email}
                  inputHtml={{
                    name: "email",
                    type: "email",
                    placeholder: "Email",
                    autoFocus: true,
                    onKeyUp: (event) => event.key === "Enter" && this.submit(mutate),
                  }}
                />
              </div>
              <Link className="sign-in-link" to="/sign-in">
                Back to Sign In
              </Link>

              <button type="submit" className="ThemeButton" disabled={loading} onClick={() => this.submit(mutate)}>
                Submit
              </button>
            </ContentBox>
          )}
        </Mutation>
        {this.renderSuccessBox()}
      </div>
    );
  }
}

export default withRouter(ForgotPassword);
