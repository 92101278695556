import gql from 'graphql-tag';

export const GET_PROPERTY_GQL = gql`
  query ($id: ID!) {
    property(id: $id) {
      id
      total_attachments_byte_size
      thumbnail
      name
      country
      market
      owner
      loan
      deal_name
      property_type
      description
      year_built
      manager
      stage
      longitude
      latitude
      tags: tag_names
      is_new
      permissions
      exterior_tour {
        url
        assets {
          id
          vimeo_url
          vimeo_id
          thumbnail
          complete
          photo_type
          title
          asset_type
          source_url
          download_url
          file_type
          asset_id
        }
      }
      interior_tour {
        url
        assets {
          id
          vimeo_url
          vimeo_id
          thumbnail
          complete
          photo_type
          title
          asset_type
          source_url
          download_url
          file_type
          asset_id
        }
      }
      property_tour_book {
        pdf_books {
          id
          thumbnail
          orientation
          title
          processing
        }
      }
      note {
        id
        content
        attachment_json
        created_at
      }
    }
  }
`

export const SUBMIT_CREATE_NOTE_GQL = gql`
  mutation($input: CreateNoteInput!) {
    createNote(input: $input) {
      success
      errors
      note {
        id
        content
        attachment_json
        created_at
      }
    }
  }
`;

export const SUBMIT_UPDATE_NOTE_GQL = gql`
  mutation($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      success
      errors
      note {
        id
        content
        attachment_json
        created_at
      }
    }
  }
`;
export const REMOVE_NOTE_GQL = gql`
  mutation($input: RemoveNoteInput!) {
    removeNote(input: $input) {
      success
      errors
    }
  }
`;