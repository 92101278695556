import React, { Component, useEffect, useState } from "react"
import "./SharedCustomers.scss"
import { withRouter } from "react-router-dom"
import { Mutation, Query, withApollo } from "react-apollo"
import { GET_SHARED_CUSTOMERS_GQL, UPDATE_SHARED_PROPERTY_GQL } from "queries/customer"
import defaultAvatar from "../../assets/images/avatar.svg"
import { PopupboxManager, PopupboxContainer } from "react-popupbox"
import SweetAlert from "sweetalert2-react"

class SharedCustomers extends Component {
  state = {
    selected: null,
    openModal: false,
    showLoading: false,
  }
  renderLoading() {
    return <div>Loading...</div>
  }

  renderError() {
    return <div>Unexpected error happen!</div>
  }

  renderEmpty() {
    return <div>There is no content</div>
  }

  handleSave(customer, ids, refetch) {
    PopupboxManager.close()
    this.setState({ showLoading: true })
    this.props.client.mutate({
      mutation: UPDATE_SHARED_PROPERTY_GQL,
      variables: {
        input: {
          email: customer.email,
          property_ids: ids,
          invitation_url: `${window.location.origin}/invitations`,
        },
      },
      update: (cache, { data: { update_shared_property } }) => {
        this.setState({ showLoading: false }, () => {
          if (update_shared_property.success) {
            refetch()
          } else {
            this.setState({ message: update_shared_property.message })
          }
        })
      },
    })
  }

  handleOpenPropertyShared(customer, properties, shared_properties, refetch) {
    this.setState(
      {
        openModal: true,
        selected: customer,
      },
      () => {
        const content = (
          <CustomerPropertiesBox
            customer={customer}
            properties={properties}
            shared_properties={shared_properties}
            uniqId={Date.now()}
            onSave={(ids) => this.handleSave(customer, ids, refetch)}
          />
        )
        PopupboxManager.open({
          content: content,
          config: {
            titleBar: {
              enable: true,
              closeButton: true,
            },
            fadeIn: true,
            fadeInSpeed: 200,
            onClosed: () =>
              this.setState({ openModal: false, selected: null }, () => {
                console.log("Closed")
              }),
          },
        })
      },
    )
  }

  render() {
    return (
      <>
        <SweetAlert show={this.state.showLoading} title="Loading" type="warning" text="Updating your changes!" />
        <div className="SharedCustomers">
          <div className="header-section">
            <div className="title">SHARED USERS</div>
          </div>
          <div className="customers-list">
            <Query query={GET_SHARED_CUSTOMERS_GQL} fetchPolicy="no-cache">
              {({ loading: loadingQuery, error: errorQuery, data = {}, refetch }) => {
                if (loadingQuery) return this.renderLoading()
                if (errorQuery) return this.renderError()
                const { shared_customers, properties = [] } = data
                if (!shared_customers || !shared_customers.length) return this.renderEmpty()

                return shared_customers.map((customer, index) => {
                  const pending_invite_properties = properties.filter((p) =>
                    (p.pending_invitations || []).find((i) => i.email === customer.email),
                  )
                  let shared_properties = [
                    ...customer.shared_properties,
                    ...pending_invite_properties.map((p) => ({ id: p.id, name: p.name, is_pending: true })),
                  ]

                  shared_properties = Object.values(
                    shared_properties.reduce((prev, property) => {
                      return {
                        ...prev,
                        [property.id]: property,
                      }
                    }, {}),
                  )

                  return (
                    <React.Fragment key={index}>
                      <div className="customer-row">
                        <div className="customer-avatar">
                          <img src={customer.avatar_url || defaultAvatar} />
                        </div>
                        <div className="customer-info">
                          <div className="customer-name">
                            {customer.name ? `${customer.name} (${customer.email})` : customer.email}
                          </div>
                          {shared_properties.length > 0 && (
                            <div className="customer-shard-properties">
                              Assigned to {shared_properties.length} properties -{" "}
                              {shared_properties
                                .map((property) => [property.name, property.is_pending ? " (Pending)" : ""].join(""))
                                .join(", ")}
                            </div>
                          )}
                        </div>
                        <div
                          className="customer-action"
                          onClick={() =>
                            this.handleOpenPropertyShared(customer, properties, shared_properties, refetch)
                          }
                        >
                          <button className="properties-shared-btn">
                            <span className="mdi mdi-hospital-building" />
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })
              }}
            </Query>
          </div>
        </div>
        <PopupboxContainer />
      </>
    )
  }
}

export default withRouter(withApollo(SharedCustomers))

function CustomerPropertiesBox({ customer, shared_properties, properties, uniqId, onSave }) {
  const shared_property_ids = shared_properties.map((property) => property.id)
  const [selectedIds, setSelectedIds] = useState(shared_property_ids)
  useEffect(() => {
    setSelectedIds(shared_property_ids)
  }, [uniqId])

  function handleSave() {
    onSave(selectedIds)
  }

  return (
    <div className="customer-properties">
      <div className="customer-header">
        <div className="customer-avatar">
          <img src={customer.avatar_url|| defaultAvatar} />
        </div>
        <div className="customer-info">
          <div className="customer-name">
            {customer.email}
          </div>
        </div>
      </div>
      <p>This user has access to following properties:</p>
      <div className="customer-properties-list">
        <ul>
          {properties.map((property) => (
            <li key={property.id}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedIds.includes(property.id)}
                  onChange={(e) =>
                    setSelectedIds(
                      e.target.checked ? [...selectedIds, property.id] : selectedIds.filter((id) => id !== property.id),
                    )
                  }
                />{" "}
                <span>{[property.name, selectedIds.includes(property.id) && (shared_properties.find(p => p.id === property.id))?.is_pending ? " (Pending)" : ""].join("")}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
      <div className="customer-footer">
        <button className="ThemeButton" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  )
}
