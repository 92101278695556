import Cookies from 'js-cookie'
import jwt from "jsonwebtoken"

const userAuth = {
  getAuth: () => {
    let user_auth_token = Cookies.get('user-auth-token')
    let user_profile = Cookies.getJSON('user-profile')
    let expired = false
    let impersonated_by
    if (user_auth_token) {
      const decoded_data = jwt.decode(user_auth_token)
      impersonated_by = decoded_data.impersonated_by
      const exp = decoded_data.exp * 1000
      if (exp < Date.now()) {
        expired = true
      }
    }

    return {
      is_authenticated: !!user_auth_token && !expired,
      impersonated_by,
      expired: expired,
      user_auth_token,
      user_profile,
    }
  },

  setAuth: args => {
    Cookies.set('user-auth-token', args['auth_token'])
    Cookies.set('user-profile', args['user_profile'])
  },

  setProfile: profile => {
    Cookies.set('user-profile', profile)
  },

  getProfile: () => {
    return Cookies.getJSON('user-profile')
  },

  setNewToken: newToken => {
    Cookies.set('user-auth-token', newToken)
  },

  clearAuth: () => {
    Cookies.remove('user-auth-token')
    Cookies.remove('user-profile')
  },

  missInfo: () => {
    let user_profile = Cookies.getJSON('user-profile')
    const { first_name, last_name, email } = user_profile
    return !first_name || !last_name || !email
  },

  needToAcceptTnc: () => {
    let user_profile = Cookies.getJSON('user-profile')
    return !user_profile.accepted_tnc
  },

  getEmail: () => {
    let user_auth_token = Cookies.get('user-auth-token')
    let user_profile = Cookies.getJSON('user-profile') || {}
    if (!user_auth_token) return 'Anonymous'
    const { email = '' } = user_profile
    return email
  },

  getUserDisplayName: () => {
    let user_auth_token = Cookies.get('user-auth-token')
    let user_profile = Cookies.getJSON('user-profile') || {}
    if (!user_auth_token) return 'Anonymous'
    const { first_name = '', last_name = '', email = '' } = user_profile
    let displayName = [(first_name || '').trim(), (last_name || '').trim()]
      .join(' ')
      .trim()
    displayName = displayName || email || 'Anonymous'
    return displayName
  }
}

export default userAuth
