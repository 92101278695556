import Cookies from 'js-cookie'

const myCookies = {
  setFilters: filter => {
    Cookies.set('filter', filter)
  },
  getFilters: () => {
    return Cookies.getJSON('filter')
  },
  clearFilters: () => {
    Cookies.remove('filter')
  }
}

export default myCookies
