import moment from 'moment';

export const scrollTop = (y = 0) => {
  window.$('#root').scrollTop(y);
};

export const toMb = bytes => parseFloat(bytes / (1024 * 1024)).toFixed(2);

export const removeExtension = fileName => {
  if (!fileName) return fileName;
  let lastIndexOfDot = fileName.lastIndexOf('.');
  if (lastIndexOfDot < 0) {
    lastIndexOfDot = fileName.length;
  }
  return fileName.substring(0, lastIndexOfDot);
};

export const getSmartTime = createdAt => {
  let options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  let currentDate = new Date();
  let date = new Date(createdAt.replace(/-/g, '/'));
  if (currentDate.toLocaleDateString() === date.toLocaleDateString()) {
    return moment(createdAt.replace(/-/g, '/')).fromNow();
  } else {
    return date.toLocaleDateString('en-GB', options);
  }
};

export const getLocalDate = (date, format) => {
  if (format == 'full') return new Date(date).toLocaleString();
  if (format == 'date') return new Date(date).toLocaleDateString();
  let _date = new Date(date).toLocaleDateString();
  let _mm = _date.split('/')[0];
  let _dd = _date.split('/')[1];
  let _yyyy = _date.split('/')[2];
  let months = [
    'Jannary',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'Septemper',
    'October',
    'November',
    'December'
  ];
  _mm = months[_mm - 1];
  return `${_mm} ${_dd} ${_yyyy}`;
};

export const resolveExternalUrl = url => {
  if (url.startsWith('http')) {
    return url;
  } else {
    return '//' + url;
  }
};

export const zoomOutMobile = () => {
  const viewport = document.querySelector('meta[name="viewport"]');

  if (viewport) {
    viewport.content =
      "width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0, user-scalable=no, shrink-to-fit=no";
  }
};

export const zoomNormal = () => {
  const viewport = document.querySelector('meta[name="viewport"]');

  if (viewport) {
    viewport.content = 'initial-scale=1';
    viewport.content = 'width=device-width, user-scalable=no';
  }
};

export const getSubDomains = (domain) => {
  let hostname = document.location.hostname
  const subdomains = hostname.replace(domain, '')
    .split('.')
    .filter(e => !!e && e !== 'www')

  return subdomains
}

