import React, { Component } from 'react';
import { toMb, removeExtension } from 'utils';
import TitleInput from './TitleInput';
import { Mutation } from 'react-apollo';
import withReactContent from 'sweetalert2-react-content';
import swal from 'sweetalert2';
import folderIcon from "assets/images/folder-icon.svg"
export default class UploadItem extends Component {
  modifyItemClass(file, index) {
    return this.props.modifyItemClass(file, index) || '';
  }

  renderItemTitle(file) {
    let title = file.title;
    if (file.file_type !== 'directory') {
      title = title || removeExtension(file.name);
    }

    if (!title) {
      title = 'No Title'
    }

    return (
      <Mutation mutation={this.props.UPDATE_TITLE_GQL}>
        {(updateTitleMutate, { loading }) => {
          return (
            <TitleInput
              value={title}
              loading={loading}
              onEndEdit={newTitle => {
                if (newTitle != title) {
                  updateTitleMutate({
                    variables: { input: { id: file.id, title: newTitle } },
                    update: (cache) => {
                      if (typeof this.props.refetch === "function") this.props.refetch()
                    }
                  });
                }
              }}
            />
          );
        }}
      </Mutation>
    );
  }

  handleClickAsset(event) {
    if (this.props.file.file_type === "directory") {
      event.preventDefault();
      event.stopPropagation();
      this.props.onSelectFolder(this.props.file);
    } else {
      event.preventDefault();
    }
  }

  render() {
    const { file, index } = this.props;
    const style = {};
    if (file.thumbnail) style['backgroundImage'] = `url('${file.thumbnail}')`;
    if (file.file_type == 'directory') {
      style['backgroundImage'] = `url('${folderIcon}')`;
      style['backgroundRepeat'] = "no-repeat";
      style['backgroundPosition'] = "center";
      style['backgroundSize'] = "50%";
    }
    return (
      <div className="upload-item">
        <div className="upload-item-container" style={style} onClick={this.handleClickAsset.bind(this)}>
          <div className={`overlay ${file.id ? "" : "uploading"} ${this.modifyItemClass(file, index)}`}>
            <div className="file-name" />
            <i className="uploading fa fa-spin fa-spinner fa-3x" />
            {file.file_type !== "directory" && <div className="size">{toMb(file.size)} Mb</div>}

            {file["id"] && (
              <div
                className="remove-item"
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  withReactContent(swal)
                    .fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete it!",
                    })
                    .then((result) => {
                      if (result.value) {
                        this.props.handleRemoveFile(file)
                      }
                    })
                }}
              >
                <i className="fa fa-times" />
              </div>
            )}
            {file["id"] && (
              <div
                className="move-item"
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  if (typeof this.props.onMovingFile === "function") {
                    this.props.onMovingFile(file)
                  }
                }}
              >
                <i className="fa fa-share" />
              </div>
            )}
          </div>
        </div>
        {this.renderItemTitle(file)}
      </div>
    )
  }
}
