import React from 'react';
import './Button.scss';

const Button = ({
  loading = false,
  disabled = false,
  className = '',
  iconClass = '',
  iconPosition = 'left',
  loadingIconClass = 'fa fa-spin fa-spinner',
  preIconClass,
  ...props
}) => {
  const getLoadingClass = () => {
    return loading ? ' loading' : '';
  };

  const getDisabledClass = () => {
    return disabled ? ' disabled' : '';
  };

  const getSelfClass = () => {
    return [getLoadingClass(), getDisabledClass()].join(' ');
  };

  const renderPreIcon = () => {
    if (!preIconClass) return null;
    return <i className={preIconClass} />;
  };

  return (
    <button {...props} className={`Button${getSelfClass()} ${className}`} >
      {renderPreIcon()}
      <div className="btn-container">
        {iconPosition === 'left' &&
          (loading ? (
            <i className={loadingIconClass} />
          ) : (
              iconClass && <i className={iconClass} />
            ))}
        {props.children}
        {iconPosition === 'right' &&
          (loading ? (
            <i className={loadingIconClass} />
          ) : (
              iconClass && <i className={iconClass} />
            ))}
      </div>
    </button>
  );
};

export default Button;
