import React, { Component } from 'react';

export default class TitleInput extends Component {
  state = {
    value: this.props.value,
    loading: this.props.loading,
    editMode: this.props.editMode
  };

  static defaultProps = {
    onEndEdit: () => {}
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps
    })
  }

  stateClass() {
    if (this.state.loading) return ' loading';
    return this.state.editMode ? ' editing' : '';
  }

  onEndEdit() {
    console.log(this.state);
    this.setState({ editMode: false }, () =>
      this.props.onEndEdit(this.state.value)
    );
  }

  handleEditClick(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.loading) return;
    if (this.state.editMode) {
      this.onEndEdit();
    } else {
      this.setState({ editMode: true });
    }
  }

  render() {
    const inputProps = {};
    if (!this.state.editMode) inputProps['readOnly'] = true;
    return (
      <div
        className={`TitleInput${this.stateClass()}`}
        onClick={event => {
          event.stopPropagation();
          this.setState({ editMode: true });
        }}>
        <input
          type="text"
          name="title"
          value={this.state.value}
          onChange={({ target: { value } }) => this.setState({ value: value })}
          onKeyUp={event => event.key === 'Enter' && this.onEndEdit()}
          onBlur={() => this.onEndEdit()}
          {...inputProps}
        />
        <div className="edit-button">
          {this.state.loading ? <i className="fa fa-spin fa-spinner" /> : null}
        </div>
      </div>
    );
  }
}
