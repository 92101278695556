import React from 'react';
import './DirectUploadSingleImage.scss';
import config from 'config';
import ImageCropper from './ImageCropper';
import * as ActiveStorage from 'activestorage';
const EXIF = require('exif-js');
ActiveStorage.start();
const URL = window.URL || window.webkitURL;

export default class DirectUploadSingleImage extends React.Component {
  state = {
    previewUrl: this.props.previewUrl,
    originalUrl: this.props.originalUrl || this.props.previewUrl,
    width: this.props.width || 200,
    height: this.props.height || 200,
    process: null,
    uploading: false,
    rotateAngle: 0,
    firstTimeUpload: false,
    showCropperLightbox: false
  };

  static defaultProps = {
    allowCrop: true
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.previewUrl != nextProps.previewUrl) {
      this.setState({
        previewUrl: nextProps.previewUrl,
        originalUrl: nextProps.originalUrl || nextProps.previewUrl
      });
    }
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener('progress', event =>
      this.uploadRequestDidProgress(event)
    );
  }

  preprocessImage(file, callback) {
    if (!window.FileReader) return callback(file);
    const { width, height } = this.props;
    const component = this;
    let reader = new FileReader();
    reader.onload = (theFile => {
      return e => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = (() => {
          return e => {
            let image = e.target;
            EXIF.getData(image, function() {
              let img = this;
              let exif = EXIF.getAllTags(img);
              let angle = [0, 0, 0, 180, 0, 0, 90, 0, 270][exif.Orientation];
              let newWidth = width;
              let newHeight = height;
              if (angle == 90 || angle == 270) {
                newWidth = height;
                newHeight = width;
              }
              if (component.state.originalUrl) {
                URL.revokeObjectURL(component.state.getOriginalUrl);
              }

              component.setState({
                previewUrl: img.src,
                originalUrl: URL.createObjectURL(file),
                rotateAngle: angle,
                width: newWidth,
                height: newHeight
              });
              callback(file);
            });
          };
        })(image);
      };
    })(file);
    reader.readAsDataURL(file);
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100;
    this.setState({
      progress: progress
    });
  }

  handleFileChange(event) {
    if (this.props.disabled) {
      event.preventDefault();
      return false;
    }
    const {
      target: {
        files: [file]
      }
    } = event;
    if (!file) return;

    this.preprocessImage(file, processFile => {
      let upload = new ActiveStorage.DirectUpload(
        file,
        config.direct_upload_url,
        this
      );
      this.setState({ uploading: true });
      upload.create((err, blob) => {
        if (err) {
          this.setState({ error: err, uploading: false }, () => {
            if (typeof this.props.onUploadFailed === 'function') {
              this.props.onUploadFailed(err);
            }
          });
        } else {
          this.setState(
            {
              blob: blob,
              showCropperLightbox: this.props.allowCrop,
              cropData: {},
              firstTimeUpload: true,
              uploading: false
            },
            () => {
              if (typeof this.props.onUploadSuccess === 'function') {
                this.props.onUploadSuccess({
                  ...blob,
                  cropData: this.state.cropData,
                  previewUrl: this.state.previewUrl
                });
              }
            }
          );
        }
      });
    });
  }

  getUploadingDom() {
    return (
      <div
        className="uploading"
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
        }}>
        <i className="fa fa-spinner fa-spin fa-4x" />
      </div>
    );
  }

  handleCropImage(data, canvas) {
    this.state.showCropperLightbox = false;
    this.state.cropData = data;
    this.setState(
      {
        previewUrl: canvas.toDataURL(),
        rotateAngle: 0,
        width: this.props.width,
        height: this.props.height
      },
      () => {
        if (typeof this.props.onUploadSuccess === 'function') {
          this.props.onUploadSuccess({
            ...this.state.blob,
            cropData: this.state.cropData,
            previewUrl: this.state.previewUrl
          });
        }
      }
    );
  }

  renderCropButton() {
    return (
      <div
        className="crop-button"
        title="Crop Image"
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          this.setState({
            showCropperLightbox: true
          });
        }}>
        <span className="mdi mdi-crop" />
      </div>
    );
  }

  render() {
    const {
      name = 'blob',
      accept = '*.*',
      disabled = false,
      allowCrop,
      text
    } = this.props;
    const { width, height, rotateAngle, uploading, previewUrl } = this.state;

    let styles = {};
    if (previewUrl) {
      styles['backgroundImage'] = `url('${previewUrl}')`;
      styles['backgroundSize'] = 'cover';
      let transformCss = {
        MozTransform: `rotate(${rotateAngle}deg)`,
        msTransform: `rotate(${rotateAngle}deg)`,
        WebkitTransform: `rotate(${rotateAngle}deg)`,
        OTransform: `rotate(${rotateAngle}deg)`,
        transform: `rotate(${rotateAngle}deg)`,
        display: 'inline-block',
        backgroundPosition: 'center center',
        width: '200%',
        height: '200%',
        maxWidth: width,
        maxHeight: height
      };

      styles = { ...transformCss, ...styles };
    }

    return (
      <div
        className={`DirectUploadSingleImage ${
          previewUrl ? 'preview-edit-image' : 'placeholder'
        }`}
        style={{
          width: '100%',
          height: '100%',
          maxWidth: this.props.width + 'px',
          maxHeight: this.props.height + 'px'
        }}>
        <label
          className={`preview${uploading ? ' blur' : ''}`}
          id="preview_thumbnail"
          style={{
            opacity: 1,
            cursor: disabled ? 'default' : 'pointer'
          }}>
          <span style={styles} />
          {text && !uploading && <span className="center-text">{text}</span>}

          <input
            type="file"
            name={name}
            accept={accept}
            style={{ display: 'none' }}
            disabled={disabled}
            onChange={event => this.handleFileChange(event)}
          />

          {!this.state.firstTimeUpload &&
            !uploading && (
              <div
                className="text-placeholder"
                style={{ display: disabled ? 'none' : 'flex' }}>
                <div
                  style={{
                    fontWeight: 900,
                    fontSize: 18
                  }}>
                  Click here to upload photo
                </div>
                <div
                  style={{
                    fontWeight: 500,
                    marginTop: 5,
                    fontSize: 18,
                    display: this.props.recommended ? 'flex' : 'none'
                  }}>
                  {this.props.recommended}
                </div>
              </div>
            )}
        </label>
        {uploading && this.getUploadingDom()}
        {previewUrl && !uploading && allowCrop && this.renderCropButton()}
        <ImageCropper
          showPopup={this.state.showCropperLightbox}
          onCancel={() =>
            this.setState({ showCropperLightbox: false }, () => {
              if (typeof this.props.onUploadSuccess === 'function') {
                this.props.onUploadSuccess({
                  ...this.state.blob,
                  cropData: this.state.cropData,
                  previewUrl: this.state.previewUrl
                });
              }
            })
          }
          previewId="preview_thumbnail"
          imgSrc={this.state.originalUrl}
          onCrop={(data, canvas) => this.handleCropImage(data, canvas)}
        />
      </div>
    );
  }
}
