import React, { Component } from 'react';
import './Comment.scss';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import Button from 'components/commons/Button';
import { getSmartTime } from 'utils';
import { toast } from 'react-toastify';
import ImageLoader from 'components/commons/ImageLoader';

const URL = window.URL || window.webkitURL;
const avatar = require('../../assets/images/avatar.svg');
const LIMIT = 5;

const getFileIcon = filename => {
  const extension = filename.split('.').pop();
  switch (extension.toLowerCase()) {
    case 'pdf':
      return 'file-pdf-o';
    case 'zip':
    case 'rar':
      return 'file-archive-o';
    case 'xls':
    case 'xlsx':
      return 'file-excel-o';
    case 'doc':
    case 'docx':
      return 'file-word-o';
    case 'ppt':
    case 'pptx':
      return 'file-powerpoint-o';
    default:
      return 'file-o';
  }
};

const SUBMIT_COMMENT_GQL = gql`
  mutation($input: CreateCommentInput!) {
    createComment(input: $input) {
      success
      errors
      comment {
        content
        attachment_json
        created_at
        creator {
          email
          customer_profile {
            first_name
            avatar
          }
        }
      }
    }
  }
`;

const GET_COMMENT_GQL = gql`
  query($property_id: ID!, $offset: Int, $limit: Int) {
    comments(property_id: $property_id, offset: $offset, limit: $limit) {
      content
      attachment_json
      created_at
      creator {
        email
        customer_profile {
          first_name
          avatar
        }
      }
    }
    customerProfile {
      avatar
      first_name
    }
    property(id: $property_id) {
      comment_amount
    }
  }
`;
export default class Comment extends Component {
  state = {
    noMoreData: false,
    content: '',
    files: [],
    loadedDataLength: 0,
    dataLength: 0,
    errors: {},
    wasFetchedMore: false
  };

  submit(mutate, refetch) {
    const { content, files } = this.state;
    const { propertyId } = this.props;

    mutate({
      variables: {
        input: {
          content: content,
          uploads: files,
          property_id: propertyId
        }
      },
      update: (cache, { data: { createComment } }) => {
        if (createComment.success) {
          this.setState(
            {
              errors: [],
              content: '',
              files: [],
              noMoreData: false
            },
            () => refetch()
          );
        } else {
          this.setState({
            errors: createComment.errors,
            files: files
          });
        }
      }
    });
  }

  renderItems(data) {
    return data.map((item, index) => {
      return (
        <div className="item" key={index}>
          <div className="profile">
            <div
              className="avatar"
              style={
                item.creator.customer_profile.avatar === null
                  ? {
                    backgroundImage: `url(${avatar})`
                  }
                  : {
                    backgroundImage: `url(${
                      item.creator.customer_profile.avatar
                      })`
                  }
              }
            />
            <div className="user-name">
              {item.creator.customer_profile.first_name}
            </div>
          </div>

          <div className="content">
            <div className="comment-content">{item.content}</div>
            <div className="date">{getSmartTime(item.created_at)}</div>
            {item.attachment_json.length > 0 && (
              <div className="attachment-file" style={{ top: 0 }}>
                {item.attachment_json.map((item, index) => (
                  <div
                    key={index}
                    className={`upload-wrapper ${item.type.replace('/', ' ')}`}>
                    <a
                      title={item.name}
                      href={item.download_url}
                      target="_blank">
                      {this.getThumbnailIcon(item)}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      );
    });
  }

  handleChange(content) {
    this.setState({ content: content.target.value });
  }

  handleFileChange(event) {
    const {
      target: { files }
    } = event;
    if (files.length > 0) {
      if (files.length > 10) {
        event.preventDefault();
        toast.error('Can not upload more than 10 files');
      } else {
        this.setState({ files: [...files] });
      }
    }
  }

  getThumbnailIcon(file) {
    const icon = getFileIcon(file.name);
    if (file.signed_id) {
      if (file.thumbnail_url) {
        return (
          <img
            className="upload-file-thumbnail"
            src={file.thumbnail_url}
            alt={file.name}
          />
        );
      } else if (file.type.includes('image')) {
        return (
          <img
            className="upload-file-thumbnail"
            src={file.original_url}
            alt={file.name}
          />
        );
      } else {
        return (
          <div className="other-file-name">
            <span className={`fa fa-${icon} fa-3x`} />
            <span className="file-name">{file.name}</span>
          </div>
        );
      }
    } else if (file.type.includes('image')) {
      return (
        <ImageLoader
          src={URL.createObjectURL(file)}
          className="upload-file-thumbnail"
          alt={file.name}
        />
      );
    } else {
      return (
        <div className="other-file-name">
          <span className={`fa fa-${icon} fa-3x`} />
          <span className="file-name">{file.name}</span>
        </div>
      );
    }
  }

  render() {
    const { propertyId } = this.props;
    return (
      <Query
        query={GET_COMMENT_GQL}
        variables={{
          property_id: propertyId,
          offset: 0,
          limit: LIMIT
        }}
        fetchPolicy="network-only">
        {({ data, error, loading, refetch, fetchMore }) => {
          if (error) return `Error! ${error.message}`;
          if (loading) return <div className="loading">Loading...</div>;
          const { comments = [], customerProfile = {}, property = {} } = data;
          this.state.dataLength = property.comment_amount;
          if (this.state.dataLength <= LIMIT) this.state.noMoreData = true;
          if (this.state.loadedDataLength == this.state.dataLength)
            this.state.noMoreData = true;
          return (
            <div className="CommentView">
              <input
                type="file"
                multiple={true}
                style={{ display: 'none' }}
                id="comment-attachment-input"
                name="comment[uploads]"
                onChange={e => this.handleFileChange(e)}
              />
              <div className="section">
                <Mutation mutation={SUBMIT_COMMENT_GQL}>
                  {(mutate, { loading: submitting, data }) => {
                    return (
                      <div style={{ width: '574px' }}>
                        <div className="item">
                          <div className="profile">
                            <div
                              className="avatar"
                              style={
                                customerProfile.avatar === null
                                  ? {
                                    backgroundImage: `url(${avatar})`
                                  }
                                  : {
                                    backgroundImage: `url(${
                                      customerProfile.avatar
                                      })`
                                  }
                              }
                            />
                            <div className="user-name">
                              {customerProfile.first_name}
                            </div>
                          </div>
                          <div className="comment-input">
                            <textarea
                              disabled={submitting}
                              className="content"
                              placeholder="Your comments here..."
                              value={this.state.content}
                              onChange={content => this.handleChange(content)}
                              rows="4"
                            />
                            <label
                              disabled={submitting}
                              className="attachment-button"
                              htmlFor="comment-attachment-input">
                              <span className="mdi mdi-attachment" />
                            </label>
                            <div className="attachment-file">
                              {this.state.files.length > 0 &&
                                this.state.files
                                  .filter(f => !!f)
                                  .map((file, index) => (
                                    <div
                                      key={index}
                                      className={`upload-wrapper ${file.type.replace(
                                        '/',
                                        ' '
                                      )} upload-section`}>
                                      <span title={file.name}>
                                        {this.getThumbnailIcon(file)}
                                      </span>
                                    </div>
                                  ))}
                            </div>
                          </div>
                        </div>
                        <Button
                          loading={submitting}
                          disabled={submitting}
                          onClick={() => {
                            if (this.state.content !== '') {
                              this.submit(mutate, refetch);
                            }
                          }}
                          className="btn btn-dark-blue submit-button">
                          Submit
                        </Button>
                      </div>
                    );
                  }}
                </Mutation>
              </div>

              {comments.length > 0 && (
                <div className="section list">
                  <div className="item-list">{this.renderItems(comments)}</div>

                  {loading && (
                    <div className="loading">
                      <i className="fa fa-spinner fa-spin fa-2x" />
                    </div>
                  )}
                  {!this.state.noMoreData &&
                    !loading && (
                      <div
                        className="view-more"
                        onClick={() => {
                          fetchMore({
                            variables: {
                              offset: comments.length
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                              if (!fetchMoreResult) return prev;
                              this.state.loadedDataLength =
                                fetchMoreResult.comments.length +
                                prev.comments.length;
                              if (
                                this.state.loadedDataLength ==
                                this.state.dataLength
                              ) {
                                this.setState({ noMoreData: true });
                              }
                              return Object.assign({}, prev, {
                                comments: [
                                  ...prev.comments,
                                  ...fetchMoreResult.comments
                                ]
                              });
                            }
                          });
                        }}>
                        View more comments
                      </div>
                    )}
                </div>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}
