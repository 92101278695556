import React, { useEffect } from "react";
import useLocalStorage from "hooks/useLocalStorage";

export default Component => {
  return props => {
    const [settings, setSettings] = useLocalStorage("settings", {
      dontShowExternalLinkAlert: false
    });
    return (
      <Component
        {...props}
        userSettings={settings}
        setUserSettings={settings => setSettings(settings)}
      />
    );
  };
};
