import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './ReportMainPage.scss';

class ReportMainPage extends Component {
  render() {
    return (
      <div className="ReportMainPage ">
        <p>
          <Link to="/reports/property-reports">PROPERTY REPORT</Link>
        </p>
      </div>
    );
  }
}

export default ReportMainPage;
