import gql from "graphql-tag";

export const SIGN_UP_GQL = gql`
  mutation ($input: SignUpInput!) {
    sign_up(input: $input) {
      success
      message
      registration {
        code
        email
        first_name
        last_name
        stage
        subscription_plan_id
        created_at
        updated_at
        promo_code
      }
      errors
    }
  }
`

export const GET_ALL_SUBSCRIPTION_PLANS_GQL = gql`
  query {
    all_subscription_plans {
      id
      name
      property_capability
      recurring_interval
      invite_user_capability
      price
      free_trial_days
      currency
    }
  }
`

export const GET_REGISTRATION_GQL = gql`
  query ($code: String!) {
    registration(code: $code) {
      code
      email
      first_name
      last_name
      stage
      subscription_plan_id
      created_at
      updated_at
      subscription_plan {
        id
        name
        price
        free_trial_days
        currency
      }
    }
  }
`

export const CREATE_PAYMENT_INTENT_GQL = gql`
  mutation ($input: CreatePaymentIntentInput!) {
    create_payment_intent(input: $input) {
      success
      message
      type
      client_secret
      return_url
    }
  }
`

export const VERIFY_EMAIL_GQL = gql`
  mutation ($input: VerifyEmailInput!) {
    verify_email(input: $input) {
      success
      message
    }
  }
`

export const APPLY_PROMO_CODE_GQL = gql`
  mutation ($input: ApplyPromoCodeInput!) {
    apply_promo_code(input: $input) {
      success
      message
    }
  }
`