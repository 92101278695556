import React from 'react';
import './PropertyTourBook.css';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import FooterSection from '../FooterSection';
import DropzoneUploader from '../DropzoneUploader';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import config from "config"

const UPLOAD_PDF_BOOK_GQL = gql`
  mutation($input: UploadPdfBookInput!) {
    uploadPdfBook(input: $input) {
      success
      errors
    }
  }
`;

const GET_PDF_BOOK_GQL = gql`
  query ($id: ID!) {
    property(id: $id) {
      id
      total_attachments_byte_size
      property_tour_book {
        pdf_books {
          id
          signed_id
          size
          name
          thumbnail
          title
          processing
        }
      }
    }
  }
`

const UPDATE_TITLE_GQL = gql`
  mutation($input: UpdatePdfBookTitleInput!) {
    updatePdfBookTitle(input: $input) {
      success
      errors
    }
  }
`;

const REMOVE_PDF_BOOK_GQL = gql`
  mutation($input: RemovePdfBookInput!) {
    removePdfBook(input: $input) {
      success
      errors
    }
  }
`;

class PropertyTourBook extends React.Component {
  static refetch = () => {};

  state = {
    propertyId: this.props.propertyId
  };

  handleUploadFinish(mutate, fileBlobs, propertyId) {
    const signed_ids = fileBlobs.map(e => e['signed_id']);
    mutate({
      variables: {
        input: {
          property_id: propertyId,
          signed_ids
        }
      },
      update: (cache, { data: { uploadPdfBook } }) => {
        if (uploadPdfBook.errors.length > 0) {
          uploadPdfBook.errors.forEach(message => toast.error(message));
        }
        this.refetch();
      }
    });
  }

  handleRemovePdfBook(removeMutate, pdf_book) {
    if (!pdf_book['id']) return;
    removeMutate({
      variables: {
        input: { id: pdf_book.id }
      },
      update: () => {
        this.refetch();
      }
    });
  }

  resolveFileData(files) {
    return files.map(f => f);
  }

  render() {
    const { propertyId } = this.state;

  const queryProps = {
      query: GET_PDF_BOOK_GQL,
      variables: { id: propertyId}
    };

    return !propertyId ? (
      <div className="err-message">Create Property first</div>
    ) : (
      <Query
        {...queryProps }
        >
        {({ loading: queryLoading, error, data, refetch }) => {
          if (queryLoading || error) return null;
          this.refetch = refetch;
          const {
            property: {
              property_tour_book: { pdf_books },
              total_attachments_byte_size,
            },
          } = data
           const remaining_bytes = config.MAXIMUM_BYTES_PER_PROPERTY - total_attachments_byte_size;

          return (
            <Mutation mutation={UPLOAD_PDF_BOOK_GQL}>
              {(
                uploadPdfBookMutate,
                { loading: uploadLoading, error, data }
              ) => {
                return (
                  <Mutation mutation={REMOVE_PDF_BOOK_GQL}>
                    {(removeMutate, { loading: removeLoading }) => (
                      <div className="ExteriorTour PropertyTourBook">
                        <div className="header-section">
                          <div className="title">Presentations & Brochures</div>
                        </div>

                        <DropzoneUploader
                          refetch={this.refetch}
                          UPDATE_TITLE_GQL={UPDATE_TITLE_GQL}
                          listFileQueryProps={queryProps}
                          propertyAssetKey={"property_tour_book"}
                          instructionText="Upload or Drag and drop your PDFs here"
                          disabled={queryLoading || uploadLoading || removeLoading}
                          remaining_bytes={remaining_bytes}
                          maximumSize={config.maxFileSizeUpload}
                          initialFiles={this.resolveFileData(pdf_books)}
                          accept="application/pdf"
                          modifyItemClass={(file) => (file["processing"] ? "processing" : "")}
                          onUploadFinish={(fileBlobs) =>
                            this.handleUploadFinish(uploadPdfBookMutate, fileBlobs, propertyId)
                          }
                          handleRemoveFile={(file) => this.handleRemovePdfBook(removeMutate, file)}
                        />
                        <FooterSection
                          disabled={uploadLoading || queryLoading || removeLoading}
                          doFinish={() => this.props.history.push(`/property/${this.props.propertyId}`)}
                          doNext={() => this.props.proceedToNextSection()}
                          doBack={() => this.props.backToPreviousSection()}
                        />
                      </div>
                    )}
                  </Mutation>
                )
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(PropertyTourBook);
