import React, { Component } from "react";
import Privacy from "components/Privacy";

class PrivacyPage extends Component {
  render() {
    return (
      <div className="PrivacyPage">
        <Privacy />
      </div>
    )
  }
}

export default PrivacyPage;
