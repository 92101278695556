import React, { Component } from 'react';
import ListProperty from 'components/ListProperty';
import { Link } from 'react-router-dom';
import PageBreadcrumb from 'components/PageBreadcrumb';
import './DashboardPage.scss';

class DashboardPage extends Component {
  render() {
    return (
      <div className="DashboardPage">
        <div className="title">PORTFOLIO</div>
        <ListProperty />
      </div>
    );
  }
}

export default DashboardPage;
