import React, { Component } from 'react';
import CollapseList from 'components/commons/CollapseList';
import InteriorTour from './InteriorTour';
import Comment from 'components/Comment';
import Note from 'components/Note';
import ExteriorTour from './ExteriorTour';
import PropertyTourBook from './PropertyTourBook';
import Document from './DocumentView';
import { resolveExternalUrl, scrollTop } from 'utils';
import GoogleMapView from './GoogleMapView'
import ExternalLink from 'components/ExternalLink'

export default class SectionListView extends Component {
  buildData() {
    return [
      {
        title: "MAPS",
        content:
          this.props.longitude && this.props.latitude ? (
            <GoogleMapView {...this.props} />
          ) : (
            <div className="flex grow-1 jc-center al-center" style={{ height: "40px", marginTop: "10px" }}>
              No content
            </div>
          ),
      },
      {
        title: this.props.exterior_tour.url ? (
          <div>
            <span>EXTERIOR </span>
            <span>
              (view virtual tour{" "}
              <ExternalLink
                className="external-link"
                href={resolveExternalUrl(this.props.exterior_tour.url)}
                target="_blank"
              >
                here
              </ExternalLink>
              )
            </span>
          </div>
        ) : (
          "EXTERIOR "
        ),
        content:
          this.props.exterior_tour.assets.length > 0 ? (
            <div className="list-container" style={{ width: "100%" }}>
              <ExteriorTour assets={this.props.exterior_tour.assets} />
            </div>
          ) : (
            !this.props.exterior_tour.url && (
              <div className="flex grow-1 jc-center al-center" style={{ height: "40px", marginTop: "10px" }}>
                No content
              </div>
            )
          ),
      },
      {
        title: this.props.interior_tour.url ? (
          <div>
            <span>INTERIOR </span>
            <span>
              (view virtual tour{" "}
              <ExternalLink
                className="external-link"
                href={resolveExternalUrl(this.props.interior_tour.url)}
                target="_blank"
              >
                here
              </ExternalLink>
              )
            </span>
          </div>
        ) : (
          "INTERIOR"
        ),
        content:
          this.props.interior_tour.assets.length > 0 ? (
            <div className="list-container" style={{ width: "100%" }}>
              <InteriorTour assets={this.props.interior_tour.assets} />
            </div>
          ) : (
            !this.props.interior_tour.url && (
              <div className="flex grow-1 jc-center al-center" style={{ height: "40px", marginTop: "10px" }}>
                No content
              </div>
            )
          ),
      },
      {
        title: "PRESENTATIONS & BROCHURES",
        active: this.props.scrollToTourBook,
        id: "tour_book",
        content:
          this.props.property_tour_book.pdf_books.length > 0 ? (
            <div className="list-container" style={{ width: "100%" }}>
              <PropertyTourBook pdf_books={this.props.property_tour_book.pdf_books} />
            </div>
          ) : (
            <div className="flex grow-1 jc-center al-center" style={{ height: "40px", marginTop: "10px" }}>
              No content
            </div>
          ),
      },
      {
        title: "DOCUMENTS & DATA",
        content: <Document propertyId={this.props.id} />,
      },
      {
        title: "Q&A",
        content: <Comment propertyId={this.props.id} />,
      },
      {
        title: "PRIVATE NOTE",
        content: <Note {...this.props} />,
      },
    ]
  }

  componentDidMount() {
    if (this.props.scrollToTourBook) {
      setTimeout(() => {
        scrollTop(
          document.getElementById('tour_book').getBoundingClientRect().top
        );
      }, 500);
    }
  }

  render() {
    return (
      <div className="SectionListView">
        <CollapseList data={this.buildData()} />
      </div>
    );
  }
}
