import React, { Component } from "react"
import "./SubscriptionPlan.scss"
import { withRouter } from "react-router-dom"
import { GET_ALL_SUBSCRIPTION_PLANS_GQL } from "queries/sign_up"
import { Query } from "react-apollo"
import _ from "lodash"

const planColors = [
  '#fafafa',
  '#eeeeee',
  '#e2e2e2',
  '#d5d5d5',
]
class SubscriptionPlan extends Component {
  state = {}

  renderLoading() {
    return (
      <div className="plans-list">
        <div className="plans-item" style={{ backgroundColor: planColors[0] }}></div>
        <div className="plans-item" style={{ backgroundColor: planColors[1] }}></div>
        <div className="plans-item" style={{ backgroundColor: planColors[2] }}></div>
        <div className="plans-item" style={{ backgroundColor: planColors[3] }}></div>
      </div>
    )
  }

  renderError() {
    return <p>Error!!! Can't load subscription plans. Please contact our admin for more details.</p>
  }

  render() {
    return (
      <div className="SubscriptionPlan">
        <div className="title">Subscription Plans</div>
        <div className="subscription-plans">
          <Query query={GET_ALL_SUBSCRIPTION_PLANS_GQL} fetchPolicy="cache-and-network">
            {({ loading, error, data }) => {
              if (loading) return this.renderLoading()
              if (error) return this.renderError()
              const { all_subscription_plans } = data || {}

              return (
                <div className="plans-list">
                  {all_subscription_plans.map((plan, index) => (
                    <div key={index} className="plans-item" style={{ backgroundColor: planColors[index] }}>
                      <div className="plan-name">{plan.name}</div>
                      <div className="plan-desc">Up to {plan.property_capability} Property Listing</div>
                      <div className="plan-desc">Invite Up to {plan.invite_user_capability} Users</div>
                      {plan.free_trial_days ? (
                        <div className="plan-desc">Free for first {plan.free_trial_days} Days</div>
                      ) : (
                        ""
                      )}
                      <div className="plan-desc">${plan.price} USD / {_.startCase(plan.recurring_interval)}</div>
                      <div className="plan-sign-up">
                        <a href={`/sign-up?plan_id=${plan.id}`} className="btn-sign-up-now">
                          Sign Up Now
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              )
            }}
          </Query>
        </div>
      </div>
    )
  }
}

export default withRouter(SubscriptionPlan)
