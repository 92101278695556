import React from 'react';
import config from 'config';
import Dropzone from 'react-dropzone';
import './DropzoneUploader.scss';
import * as ActiveStorage from 'activestorage';
import { toast } from 'react-toastify';
import { toMb, removeExtension } from 'utils';
import UploadItem from './UploadItem';

ActiveStorage.start();

export default class DropzoneUploader extends React.Component {
  state = {
    uploading: false,
    fileBlobs: {},
    progresses: {},
    dropzoneActive: false,
    tmpFiles: []
  };

  static defaultProps = {
    instructionText: 'Upload or Drag and drop your files here',
    accept: '*',
    name: 'blob',
    handleRemoveFile: () => {},
    onUploadFinish: () => {},
    modifyItemClass: () => {}
  };

  modifyItemClass(file, index) {
    return this.props.modifyItemClass(file, index) || '';
  }

  renderItem(file, index) {
    return (
      <UploadItem
        onSelectFolder={(file) => this.props.onSelectFolder(file)}
        onMovingFile={(file) => this.props.onMovingFile(file)}
        refetch={this.props.refetch}
        file={file}
        key={index}
        index={index}
        UPDATE_TITLE_GQL={this.props.UPDATE_TITLE_GQL}
        listFileQueryProps={this.props.listFileQueryProps}
        propertyAssetKey={this.props.propertyAssetKey}
        modifyItemClass={(file, index) => this.modifyItemClass(file, index)}
        handleRemoveFile={(file) => this.props.handleRemoveFile(file)}
        handleOnUpdateTitle={(file, newTitle) => this.props.handleUpdateTitle(file, newTitle)}
      />
    )
  }

  uploadRequestDidProgress(event, index) {
    const progress = event.loaded / event.total * 100;
    let progresses = this.state.progresses;
    progresses[index] = progress;
    this.setState({
      progresses: { ...progresses }
    });
  }

  validateFile(file) {
    let valid = true;
    if (this.props.maximumSize) {
      valid = valid && this.props.maximumSize > file.size;
    }
    if (this.props.remaining_bytes) {
      valid = valid && this.props.remaining_bytes > file.size
    } else {
      valid = false
    }
    return valid;
  }

  toastInvalidFiles(files) {
    if (files.length > 0) {
      files.map(f => {
        if (f.size > config.maxFileSizeUpload) {
          toast.error(
            `Large file is not allowed
          ${f.name} - ${toMb(f.size)}Mb (maximum ${toMb(config.maxFileSizeUpload)}Mb)`,
          )
        } else if (f.size > this.props.remaining_bytes) {
          toast.error(
            `You have exceeded the data limit for this property. Please either delete files to free up space or create a new property to continue adding files.`,
          )
        }
      });
    }
  }

  showMessageRejectedFile(files) {
    if (files.length > 0) {
      files.map(f =>
        toast.error(
          `Format is not allowed
					${f.name}`
        )
      );
    }
  }

  onDrop(acceptedFiles) {
    const files = acceptedFiles.filter(f => this.validateFile(f));
    const invalidFiles = acceptedFiles.filter(f => !this.validateFile(f));
    this.toastInvalidFiles(invalidFiles);
    if (files.length == 0) return;
    this.setState(
      {
        tmpFiles: files,
        fileBlobs: {},
        uploading: true
      },
      () => {
        let countFinish = 0;
        files.map((file, index) => {
          const upload = new ActiveStorage.DirectUpload(
            file,
            config.direct_upload_url,
            {
              directUploadWillStoreFileWithXHR: xhr => {
                xhr.upload.addEventListener('progress', event =>
                  this.uploadRequestDidProgress(event, index)
                );
              }
            }
          );
          upload.create((err, blob) => {
            countFinish += 1;
            if (err) {
              console.log(err);
            } else {
              this.state.fileBlobs[blob.signed_id] = blob;

              if (countFinish == files.length) {
                this.setState(
                  {
                    uploading: false,
                    tmpFiles: []
                  },
                  () => this.onFinishUploadFiles({ ...this.state.fileBlobs })
                );
              }
            }
          });
        });
      }
    );
  }

  renderInstructionItem() {
    return (
      <div className="instruction-item">
        <div className="ins-item-container">
          <div className="ins-content">
            <i className="fa fa-plus" />
            <div className="text">{this.props.instructionText}</div>
          </div>
        </div>
      </div>
    );
  }

  totalProgress() {
    const size = Object.keys(this.state.progresses).length;
    if (size === 0) return 0;
    const progress = Object.keys(this.state.progresses).reduce((avg, k) => {
      return (avg += this.state.progresses[k] / size);
    }, 0);

    return Math.floor(progress);
  }

  onFinishUploadFiles(fileBlobs) {
    this.props.onUploadFinish(Object.keys(fileBlobs).map(k => fileBlobs[k]));
  }

  render() {
    const { accept, instructionText, initialFiles, name } = this.props;
    const { tmpFiles, dropzoneActive } = this.state;
    const disabled = this.state.uploading || this.props.disabled;
    const allFiles = [...initialFiles, ...tmpFiles];
    let totalProgress = this.totalProgress();
    return (
      <div className="DropzoneUploader">
        <Dropzone
          accept={accept}
          className={`uploader-container${dropzoneActive ? ' active' : ''}`}
          name={name}
          multiple={true}
          disabled={disabled}
          onDragEnter={() => this.setState({ dropzoneActive: true })}
          onDragLeave={() => this.setState({ dropzoneActive: false })}
          onDrop={(acceptedFiles, rejectedFiles) => {
            this.setState({ dropzoneActive: false }, () => {
              this.showMessageRejectedFile(rejectedFiles);
              this.onDrop(acceptedFiles);
            });
          }}>
          {this.state.uploading && (
            <div className="progress-line">
              <div className="background">
                <div className="meter" style={{ width: `${totalProgress}%` }} />
              </div>
            </div>
          )}
          {this.state.dropzoneActive && (
            <div className="drop-instruction">Drop your files here</div>
          )}
          {(allFiles.length == 0 && (
            <div className="instruction-text">
              <div>{instructionText}</div>
              <i className="fa fa-plus-circle fa-3x" />
            </div>
          )) || (
            <div className="upload-list">
              {allFiles.map((file, index) => this.renderItem(file, index))}
              {allFiles.length > 0 && this.renderInstructionItem()}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}


DropzoneUploader.defaultProps = {
  onSelectFolder: () => {},
  onMovingFile: () => {},
  onUploadFinish: () => {},
  listFileQueryProps: {},
  handleRemoveFile: () => {},
  handleUpdateTitle: () => {}
}