import React from "react"
import "./ContentBox.scss"
import assetBaseLogo from "assets/images/asset_base_logo_with_text.svg"

const ContentBox = ({ children, title = "", logo }) => {
  return (
    <div className={`ContentBox`}>
      <div className="box-container">
        {logo || (
          <div
            className="logo"
            style={{
              backgroundImage: `url(${assetBaseLogo})`,
            }}
          />
        )}
        <div className="box-title">{title}</div>
        {children}
      </div>
    </div>
  )
}

export default ContentBox
