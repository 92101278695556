import React, { Component } from "react";
import "./CustomerProfile.scss";
import { withRouter } from "react-router-dom";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import SweetAlert from "sweetalert2-react";
import Input from "components/commons/Input";
import Button from "components/commons/Button";
import DirectUploadSingleImage from "components/DirectUploadSingleImage";
import Select from "components/commons/Select";
import { scrollTop } from "utils";

const UPDATE_PROFILE_GQL = gql`
  mutation ($input: UpdateCustomerProfileInput!) {
    updateCustomerProfile(input: $input) {
      customerProfile {
        first_name
        last_name
        title
        company_name
        department
        work_email
        work_phone_number
        address_line_1
        address_line_2
        country
        city
        postal_code
        avatar
        avatar_signed_id
      }
      success
      errors
    }
  }
`

const GET_PROFILE_GQL = gql`
  query {
    customerProfile {
      first_name
      last_name
      title
      company_name
      department
      work_email
      work_phone_number
      address_line_1
      address_line_2
      country
      city
      postal_code
      avatar
      avatar_signed_id
    }
    propertyOptions {
      countries
    }
  }
`

const GET_PROFILE_IN_HEADER_GQL = gql`
  query {
    customerProfile {
      first_name
      last_name
      avatar
    }
  }
`

class CustomerProfile extends Component {
  state = {
    model: {
      first_name: "",
      last_name: "",
      title: "",
      company_name: "",
      department: "",
      work_email: "",
      work_phone_number: "",
      address_line_1: "",
      address_line_2: "",
      country: "",
      city: "",
      postal_code: "",
      avatar: "",
      avatar_signed_id: ""
    },
    errors: {},
    showSuccess: false
  };

  static refetch = () => {};

  submit(mutate) {
    this.setState({ errors: {}, model: {} });
    const {
      avatar_signed_id,
      company_name,
      work_email,
      ...input
    } = this.state.model;
    mutate({
      variables: {
        input: {
          ...input,
          avatar: avatar_signed_id
        }
      },
      update: (cache, { data: { updateCustomerProfile } }) => {
        if (!updateCustomerProfile.success) {
          this.setState(
            {
              errors: updateCustomerProfile.errors
            },
            () => {
              scrollTop();
              this.state.model = input;
            }
          );
        } else {
          cache.writeQuery({
            query: GET_PROFILE_IN_HEADER_GQL,
            data: {
              customerProfile: {
                avatar: updateCustomerProfile.customerProfile.avatar,
                first_name: updateCustomerProfile.customerProfile.first_name,
                last_name: updateCustomerProfile.customerProfile.last_name,
              }
            }
          });

          this.setState({ showSuccess: true }, () => this.refetch());
        }
      }
    });
  }

  renderSuccessBox() {
    return (
      <SweetAlert
        show={this.state.showSuccess}
        title="Changes Saved"
        type="success"
        text="Updated Profile Successfully"
        confirmButtonText="OK"
        confirmButtonColor="#375fac"
        onConfirm={() =>
          this.setState({ showSuccess: false }, () => {
            this.props.history.push("/dashboard");
          })}
      />
    );
  }

  render() {
    return (
      <div className="gql-form">
        <Query query={GET_PROFILE_GQL} fetchPolicy="network-only">
          {({ loading, error, data = {}, refetch }) => {
            if (loading || error) return null;
            const { customerProfile = {}, propertyOptions = {} } = data;
            this.refetch = refetch;
            this.state.model = {
              ...this.state.model,
              ...customerProfile
            };

            return (
              <div className="CustomerProfile">
                <div className="header-section">
                  <div className="title">USER PROFILE</div>
                </div>
                <Mutation mutation={UPDATE_PROFILE_GQL}>
                  {(mutate, { loading, error, data = {} }) => {
                    return (
                      <form className="customer-profile-form">
                        <div className="profile-info">
                          <div className="flex row">
                            <div
                              className="avatar"
                              style={{
                                minWidth: "250px",
                                height: "250px",
                                flexBasis: "250px",
                                marginTop: "10px",
                              }}
                            >
                              <DirectUploadSingleImage
                                width={250}
                                height={250}
                                previewUrl={this.state.model["avatar"]}
                                accept="image/x-png,image/jpeg,image/png"
                                allowCrop={false}
                                onUploadSuccess={(blob) => (this.state.model["avatar_signed_id"] = blob.signed_id)}
                              />
                            </div>
                            <div className="flex row grow-1 wrap space-between user-info">
                              <Input
                                className="txt-first-name"
                                value={this.state.model["first_name"]}
                                label="First Name"
                                required="yes"
                                error={this.state.errors["first_name"]}
                                onChange={(value) => {
                                  this.state.model["first_name"] = value
                                }}
                              />
                              <Input
                                value={this.state.model["last_name"]}
                                className="txt-first-name"
                                label="Last Name"
                                required="yes"
                                error={this.state.errors["last_name"]}
                                onChange={(value) => (this.state.model["last_name"] = value)}
                              />
                              <Input
                                wrapperHtml={{ style: { width: "100%" } }}
                                label="Title"
                                value={this.state.model["title"]}
                                error={this.state.errors["title"]}
                                onChange={(value) => (this.state.model["title"] = value)}
                              />
                              <Input
                                wrapperHtml={{ style: { width: "100%" } }}
                                label="Company Name"
                                value={this.state.model["company_name"]}
                                error={this.state.errors["company_name"]}
                                onChange={(value) => (this.state.model["company_name"] = value)}
                              />
                            </div>
                          </div>
                          <div className="flex column">
                            <Input
                              required="yes"
                              label="Email Address"
                              inputHtml={{ disabled: true }}
                              value={this.state.model["work_email"]}
                              error={this.state.errors["work_email"]}
                            />
                            <Input
                              label="Phone Number"
                              value={this.state.model["work_phone_number"]}
                              error={this.state.errors["work_phone_number"]}
                              onChange={(value) => (this.state.model["work_phone_number"] = value)}
                            />
                          </div>
                          <div className="flex row wrap space-between">
                            <label
                              style={{
                                width: "100%",
                                fontSize: "1.2em",
                                marginTop: "12px",
                              }}
                            >
                              Address
                            </label>
                            <Input
                              inputHtml={{ placeholder: "Address Line 1" }}
                              wrapperHtml={{ style: { width: "100%" } }}
                              value={this.state.model["address_line_1"]}
                              error={this.state.errors["address_line_1"]}
                              onChange={(value) => (this.state.model["address_line_1"] = value)}
                            />
                            <Input
                              inputHtml={{ placeholder: "Address Line 2" }}
                              wrapperHtml={{ style: { width: "100%" } }}
                              value={this.state.model["address_line_2"]}
                              error={this.state.errors["address_line_2"]}
                              onChange={(value) => (this.state.model["address_line_2"] = value)}
                            />
                            <div className="w-32-percent">
                              <Select
                                name="country"
                                className="basic-single profile-country"
                                classNamePrefix="select"
                                defaultValue={
                                  this.state.model["country"]
                                    ? {
                                        label: this.state.model["country"],
                                        value: this.state.model["country"],
                                      }
                                    : null
                                }
                                styles={{
                                  option: (base, state) => {
                                    return {
                                      ...base,
                                      borderWidth: 1,
                                      backgroundColor: state.isSelected
                                        ? "#16254c"
                                        : state.isFocused
                                        ? "#16254c57"
                                        : null,
                                    }
                                  },
                                }}
                                onChange={({ value }) => {
                                  this.state.model["country"] = value
                                }}
                                placeholder="Country"
                                menuPlacement="top"
                                options={(propertyOptions.countries || []).map((e) => ({
                                  value: e,
                                  label: e,
                                }))}
                              />
                            </div>
                            <Input
                              className="w-32-percent"
                              inputHtml={{ placeholder: "City" }}
                              value={this.state.model["city"]}
                              error={this.state.errors["city"]}
                              onChange={(value) => (this.state.model["city"] = value)}
                            />
                            <Input
                              className="w-32-percent"
                              inputHtml={{ placeholder: "Postal Code" }}
                              onChange={(value) => (this.state.model["postal_code"] = value)}
                              value={this.state.model["postal_code"]}
                              error={this.state.errors["postal_code"]}
                            />
                          </div>
                        </div>
                        <div className="flex row jc-center grow-1">
                          <Button className="btn btn-dark-blue" onClick={() => this.submit(mutate)}>
                            Save
                          </Button>
                        </div>
                      </form>
                    )
                  }}
                </Mutation>
              </div>
            );
          }}
        </Query>
        {this.state.showSuccess && this.renderSuccessBox()}
      </div>
    );
  }
}

export default withRouter(CustomerProfile);
