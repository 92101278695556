import React from "react"
import logo from "assets/images/asset_base_logo_with_text.svg"

const SignUpFooter = (props) => {
  return (
    <div className="SignUpPageFooter">
      <div className="FooterContent">
        <div className="FooterLeft">
          <a href="https://assetbase.com.sg">
            <img alt="AssetBase" className="footer-logo" src={logo} />
          </a>
          <p className="text-left left-middle-content">
            * Free trial applicable to Single Asset Plan and Basic Plan. After your free trial, monthly charges will
            apply.
          </p>
          <div className="footer-text">© {new Date().getFullYear()} AssetBase Services Pte. Ltd.</div>
        </div>
        <div className="FooterRight">
          <ul className="FooterLinks">
            <li className="static">Quick Links</li>
            <li>
              <a href="https://assetbase.com.sg">Home</a>
            </li>
            <li>
              <a href="https://assetbase.com.sg/features">Features</a>
            </li>
            <li>
              <a href="https://assetbase.com.sg/user-groups">User Groups</a>
            </li>
            <li>
              <a href="https://app.assetbase.com.sg/sign-up">Try ASSETBASE For Free*</a>
            </li>
          </ul>
          <ul className="FooterLinks">
            <li className="static">Company</li>
            <li>
              <a href="/privacy">Privacy</a>
            </li>
            <li>
              <a href="/cookies">Cookies</a>
            </li>
            <li>
              <a href="/legal">Legal</a>
            </li>
            <li>
              <a href="mailto:support@assetbase.com.sg">Contact: support@assetbase.com.sg</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SignUpFooter
