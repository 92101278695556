import React, { Component } from "react"

import { Elements, PaymentElement, ElementsConsumer } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { STRIPE_PUBLISHABLE_KEY } from "utils/constants"
import { Mutation } from "react-apollo"
import Input from "components/commons/Input"
import { toast } from "react-toastify"

const appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#ac2430",
  },
  rules: {
    ".Label": {
      opacity: 0,
    },
  },
}
export default class PaymentBox extends Component {
  state = {
    stripePromise: null,
    options: null,
    promoCode: "",
    errors: {},
    loading: false,
  }

  componentDidMount() {
    const { subscription_plan } = this.props
    this.setState({
      stripePromise: loadStripe(STRIPE_PUBLISHABLE_KEY),
      options: {
        mode: "payment",
        currency: String(subscription_plan.currency).toLowerCase(),
        amount: Math.round(subscription_plan.price * 100),
        setupFutureUsage: "off_session",
        mode: "subscription",
        captureMethod: "automatic",
        paymentMethodTypes: ["card"],
        appearance: appearance,
      },
      promoCode: "",
      errors: {},
      loading: false,
    })
  }

  handleSubmit(event, mutate, { stripe, elements }) {
    event.preventDefault()
    event.stopPropagation()

    if (this.state.loading) return false
    this.setState({ errors: {}, loading: true }, () => {
      this.props.onSubmit(this.props.subscription_plan, mutate, { stripe, elements, promoCode: this.state.promoCode })
    }) 
  }

  render() {
    const { options, stripePromise } = this.state
    return (
      <div className="upgrade-subscription-payment">
        <p>
          You have selected the <b className="inline-block">{this.props.subscription_plan.name} Plan.</b>
        </p>
        <Mutation mutation={this.props.mutation}>
          {(mutate, { loading }) => {
            if (!stripePromise || !options) return null
            return (
              <Elements stripe={stripePromise} options={options}>
                <ElementsConsumer>
                  {({ elements, stripe }) => (
                    <form onSubmit={(event) => !loading && this.handleSubmit(event, mutate, { elements, stripe })}>
                      <div className="box-inputs">
                        <label>Card Details</label>
                        <PaymentElement />
                      </div>
                      <div className="box-inputs">
                        <label>Promotion Code</label>
                        <Input
                          className="input"
                          onChange={(value) => this.setState({ promoCode: value })}
                          value={this.state.promoCode}
                          inputHtml={{
                            name: "promoCode",
                            type: "text",
                            placeholder: "Enter promotion code",
                            style: { fontSize: "16px" },
                          }}
                          error={this.state.errors["promoCode"]}
                        />
                      </div>
                      <div style={{ marginTop: 20, textAlign: "right" }}>
                        <button type="submit" className="ThemeButton" disabled={this.state.loading}>
                          Continue
                        </button>
                      </div>
                    </form>
                  )}
                </ElementsConsumer>
              </Elements>
            );
          }}
        </Mutation>
      </div>
    )
  }
}
