import React, { Component } from "react";
import VerifyEmail from "components/VerifyEmail";
import userAuth from "utils/userAuth";

class VerifyEmailPage extends Component {
  componentWillMount() {
    userAuth.clearAuth();
  }

  render() {
    const { code } = this.props.match.params
    return (
      <div className="VerifyEmailPage">
        {code ? <VerifyEmail code={code} /> : null}
      </div>
    )
  }
}

export default VerifyEmailPage;
