import React, { Component } from 'react'
import UserSetting from 'components/UserSetting'

class UserSettingPage extends Component {
  render() {
    return (
      <div className="UserSettingPage">
        <div className="title">USER SETTINGS</div>
        <UserSetting />
      </div>
    );
  }
}

export default UserSettingPage
