import React, { Component } from 'react'
import UserSubscription from 'components/UserSubscription'

class UserSubscriptionPage extends Component {
  render() {
    return (
      <div className="UserSubscriptionPage">
        <div className="title">SUBSCRIPTION PLANS</div>
        <UserSubscription />
      </div>
    );
  }
}

export default UserSubscriptionPage
