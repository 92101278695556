import React, { Component } from 'react';
import PropertyView from 'components/PropertyView';
import queryString from 'query-string';

class PropertyViewPage extends Component {
  render() {
    const { id } = this.props.match.params;
    const params = queryString.parse(this.props.location.search);
    const scrollToTourBook = params['scroll_to_tour_book'] === 'true';
    return (
      <div className="PropertyViewPage flex grow-1">
        <PropertyView propertyId={id} scrollToTourBook={scrollToTourBook} />
      </div>
    );
  }
}

export default PropertyViewPage;
