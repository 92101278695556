import React, { Component } from 'react';
import ImageLoader from 'components/commons/ImageLoader';

export const getFileIcon = filename => {
  const extension = filename.split('.').pop();
  switch (extension.toLowerCase()) {
    case 'pdf':
      return 'file-pdf-o';
    case 'zip':
    case 'rar':
      return 'file-archive-o';
    case 'xls':
    case 'xlsx':
      return 'file-excel-o';
    case 'doc':
    case 'docx':
      return 'file-word-o';
    case 'ppt':
    case 'pptx':
      return 'file-powerpoint-o';
    default:
      return 'file-o';
  }
};

const getThumbnailIcon = (file) => {
  const fileSize = Math.ceil(file.size / 1024 / 1024 * 100) / 100 + " MB"
  const icon = getFileIcon(file.name);
  if (file.signed_id) {
    if (file.thumbnail_url) {
      return (
        <>
          <img
            className="upload-file-thumbnail"
            src={file.thumbnail_url}
            alt={file.name}
          />
          <span>{file.name}</span>
          <span><i>{fileSize}</i></span>
        </>
      );
    } else if (file.type.includes('image')) {
      return (
        <>
          <img
            className="upload-file-thumbnail"
            src={file.original_url}
            alt={file.name}
          />
          <span>{file.name}</span>
          <span><i>{fileSize}</i></span>
        </>
      );
    } else {
      return (
        <>
          <div className="other-file-name">
            <i className={`fa fa-${icon} fa-3x`} />
          </div>
          <span>{file.name}</span>
          <span><i>{fileSize}</i></span>
        </>
      );
    }
  } else if (file.type.includes('image')) {
    return (
      <>
        <ImageLoader
          src={URL.createObjectURL(file)}
          className="upload-file-thumbnail"
          alt={file.name}
        />
        <span>{file.name}</span>
        <span><i>{fileSize}</i></span>
      </>
    );
  } else {
    return (
      <>
        <div className="other-file-name">
          <i className={`fa fa-${icon} fa-3x`} />
        </div>
        <span>{file.name}</span>
        <span><i>{fileSize}</i></span>
      </>
    );
  }
}
export default getThumbnailIcon;