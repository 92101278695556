import React, { Component } from 'react';
import Select, { createFilter, components } from 'react-select';
import PropTypes from 'prop-types';

Select.propTypes = {
  label: PropTypes.string
};

const SelectContainer = ({ children, ...props }) => {
  const {selectProps} = props
  return (
    <div data-tip={selectProps.dataTip}>
      <components.SelectContainer {...props}>
        {children}
      </components.SelectContainer>
    </div>
  );
};

export default class Select2 extends Component {
  state = {
    defaultValue: this.props.defaultValue,
    options: this.props.options
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue != this.state.defaultValue) {
      this.setState({ defaultValue: nextProps.defaultValue });
    }
  }

  render() {
    const { defaultValue, onChange, styles, readOnly, ...rest } = this.props;
    const filterConfig = {
      ignoreCase: true,
      ignoreAccents: true,
      matchFrom: this.props.matchFromStart ? 'start' : 'any'
    };

    return (
      <Select
        isDisabled={readOnly}
        styles={{
          ...styles,
          control: base => {
            return {
              ...base,
              backgroundColor: '#fff'
            };
          }
        }}
        {...rest}
        defaultValue={this.state.defaultValue}
        components={{SelectContainer}}
        onChange={value => {
          this.setState(
            {
              defaultValue: value
            },
            () => onChange(value)
          );
        }}
        filterOption={createFilter(filterConfig)}
      />
    );
  }
}
