import React, { Component, useEffect, useState } from "react"
import "./PaymentHistory.scss"
import { withRouter } from "react-router-dom"
import { Query, withApollo } from "react-apollo"
import gql from "graphql-tag"
import moment from "moment"

export const GET_LIST_INVOICE_GQL = gql`
  query ($page: Int, $per_page: Int, $filter: JSON, $sort: JSON) {
    invoices(filter: $filter, sort: $sort, page: $page, per_page: $per_page) {
      invoices {
        id
        email
        customer_name
        paid_at
        billing_reason
        billing_type
        total
        subscription_plan {
          id
          name
        }
      }
      pagination {
        pages
        pageSize
        page
      }
    }
  }
`

class PaymentHistory extends Component {
  state = {}
  renderLoading() {
    return <div>Loading...</div>
  }

  renderError() {
    return <div>Unexpected error happen!</div>
  }

  renderEmpty() {
    return <div>There is no content</div>
  }

  render() {
    return (
      <>
        <div className="PaymentHistory">
          <div className="header-section">
            <div className="title">Payment History</div>
          </div>
          <div className="invoices-list">
            <Query
              query={GET_LIST_INVOICE_GQL}
              variables={{
                page: 0,
                per_page: 99999,
                filter: {
                  billing_reason: ["subscription_cycle", "subscription_create", "subscription_update"]
                },
                sort: {
                  paid_at: "desc",
                },
              }}
              fetchPolicy="no-cache"
            >
              {({ loading: loadingQuery, error: errorQuery, data = {}, refetch }) => {
                if (loadingQuery) return this.renderLoading()
                if (errorQuery) return this.renderError()
                const {
                  invoices: { invoices = [], pagination },
                } = data
                if (!invoices || !invoices.length) return this.renderEmpty()

                return invoices.map((invoice, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="invoice-card">
                        <div className="invoice-header">
                          <div className="invoice-date">{moment(invoice.paid_at).format("DD/MM/YYYY")}</div>
                        </div>
                        <div className="invoice-content">
                          <div className="invoice-name">
                            <p>Transaction</p>
                            <div className="invoice-type">
                              {["monthly_subscription"].includes(invoice.billing_type)
                                ? "Monthly Subscription"
                                : ["upgrade"].includes(invoice.billing_type) ? "Upgrade Plan" : "Downgrade Plan"}{" "}
                              ({invoice.subscription_plan ? invoice.subscription_plan.name : "Unknown"})
                            </div>
                          </div>
                          <div className="invoice-amount">
                            <p>Amount</p>
                            <div className="amount">${invoice.total}</div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })
              }}
            </Query>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(withApollo(PaymentHistory))
