import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { withApollo } from "react-apollo"
import Property from "components/Property"
import { GET_CURRENT_SUBSCRIPTION_GQL } from "queries/subscription"
import SweetAlert from "sweetalert2-react"

class CreatePropertyPage extends Component {
  state = {
    showForm: false,
    showPopup: false,
    popupMessage: '',
  }

  componentDidMount() {
    this.fetchCurrentSubscription();
  }
  fetchCurrentSubscription() {
    this.props.client
      .query({
        query: GET_CURRENT_SUBSCRIPTION_GQL,
        fetchPolicy: "no-cache",
      })
      .then(({ data: { current_subscription } }) => {
        if (!current_subscription) {
          this.setState({
            showPopup: true,
            popupMessage: "In order to add a property, please sign up for a subscription plan under your account menu.",
          })
        } else if (!current_subscription.allowed_create_property) {
          this.setState({
            showPopup: true,
            popupMessage:
              "You have reached the maximum number of properties under your current subscription plan. Would you like to update your plan?",
          })
        } else {
          this.setState({ showForm: true })
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  render() {
    return (
      <div className="CreatePropertyPage">
        <div className="title">ADD PROPERTY</div>

        {this.state.showForm ? (
          <Property />
        ) : (
          <div>
            <br />
            Loading...
          </div>
        )}
        <SweetAlert
          show={this.state.showPopup}
          title="Warning"
          type="warning"
          text={this.state.popupMessage}
          confirmButtonText="OK"
          showCancelButton
          cancelButtonText="Cancel"
          onConfirm={() => {
            this.setState({ showPopup: false }, () => {
              this.props.history.push("/subscriptions");
            });
          }}
          onCancel={() => this.setState({ showPopup: false }, () => this.props.history.go(-1))}
        />
      </div>
    );
  }
}

export default withRouter(withApollo(CreatePropertyPage))
