import React, { useEffect, useState } from 'react'
import {
  DashboardPage,
  SignInPage,
  NoMatch,
  TermsAndConditionsPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  UpdatePasswordPage,
  UserProfilePage,
  CreatePropertyPage,
  PropertyViewPage,
  EditPropertyPage,
  HelpPage,
  PdfViewPage,
  UserSettingPage,
  ReportMainPage,
  ReportPage,
  ContactUsPage,
  LandingPage,
  SignUpPage,
  SubscriptionPlanPage,
  VerifyEmailPage,
  InvitationPage,
  SharedCustomersPage,
  UserSubscriptionPage,
  PaymentHistoryPage,
  LegalPage,
  PrivacyPage,
  CookiesPage,
} from "pages";



import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'
import { DefaultLayout, LoginLayout, PdfViewLayout, SignUpLayout } from './layouts'
import userAuth from 'utils/userAuth'
import myCookies from 'utils/myCookies'
import { ApolloConsumer } from 'react-apollo'
import { scrollTop } from 'utils'
import config from 'config'
import useTitle from "hooks/useTitle"

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <DefaultLayout
      {...rest}
      component={(props) => {
        scrollTop()
        let redirectUrl = "/sign-in"
        return userAuth.getAuth().is_authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectUrl,
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

const UserSignOutRoute = (
  <Route
    path="/sign-out"
    component={props => {
      userAuth.clearAuth()
      myCookies.clearFilters()
      return (
        <ApolloConsumer>
          {client => {
            client.resetStore()
            return (
              <Redirect
                to={{
                  pathname: '/sign-in',
                  state: { from: props.location }
                }}
              />
            )
          }}
        </ApolloConsumer>
      )
    }}
  />
)

const AppRoutes = ({}) => {
  useTitle(['AssetBase'].filter(e => !!e).join('.'))
  return (
    <BrowserRouter onUpdate={() => console.log("update route")}>
      <Switch>
        <DefaultLayout exact path="/" component={(props) => <Redirect to="/dashboard" />} />
        <PrivateRoute path="/dashboard" component={DashboardPage} />
        <LoginLayout path="/landing" component={LandingPage} />
        <SignUpLayout path="/sign-in" component={SignInPage} />
        <SignUpLayout path="/forgot-password" component={ForgotPasswordPage} />
        <SignUpLayout path="/contact-us" component={ContactUsPage} />
        <SignUpLayout path="/reset-password" component={ResetPasswordPage} />
        <PrivateRoute path="/update-password" component={UpdatePasswordPage} />
        <PrivateRoute path="/user-profile" component={UserProfilePage} />
        <PrivateRoute path="/user-settings" component={UserSettingPage} />
        <PrivateRoute path="/create-property" component={CreatePropertyPage} />
        <PrivateRoute path="/help" component={HelpPage} />
        <PrivateRoute exact path="/reports" component={ReportPage} />

        <PrivateRoute path="/property/:id/edit" component={EditPropertyPage} />

        <PrivateRoute path="/property/:id" component={PropertyViewPage} />

        <PrivateRoute path="/shared-users" component={SharedCustomersPage} />
        <PrivateRoute path="/subscriptions" component={UserSubscriptionPage} />
        <PrivateRoute path="/payment-history" component={PaymentHistoryPage} />

        <SignUpLayout path="/terms-and-conditions" component={TermsAndConditionsPage} />

        <PdfViewLayout path="/pdf/:id" component={PdfViewPage} />
        <SignUpLayout path="/sign-up/:code?" component={SignUpPage} />
        <SignUpLayout path="/subscription-plans" component={SubscriptionPlanPage} />
        <SignUpLayout path="/verify-email/:code" component={VerifyEmailPage} />
        <SignUpLayout path="/invitations/:code" component={InvitationPage} />
        <SignUpLayout path="/legal" component={LegalPage} />
        <SignUpLayout path="/privacy" component={PrivacyPage} />
        <SignUpLayout path="/cookies" component={CookiesPage} />
        <PrivateRoute path="/a/legal" component={LegalPage} />
        <PrivateRoute path="/a/privacy" component={PrivacyPage} />
        <PrivateRoute path="/a/cookies" component={CookiesPage} />

        {UserSignOutRoute}
        <LoginLayout component={NoMatch} />
      </Switch>
    </BrowserRouter>
  );

}
export default AppRoutes
