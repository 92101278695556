import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";
import "@fancyapps/fancybox";
import App from "./App";
import { ApolloProvider } from "react-apollo";
import createClient from "./createClient";

// Import all stylesheet
import "assets/styles.scss";

// jQuery alias for fancybox
window.jQuery.fn.addBack = window.jQuery.fn.andSelf;

export const client = createClient();

ReactDOM.render(
  <ApolloProvider client={client}>
      <App />
  </ApolloProvider>,
  document.getElementById("root")
);
