import gql from 'graphql-tag';

export const CREATE_PROPERTY_GQL = gql`
  mutation ($input: CreatePropertyInput!) {
    createProperty(input: $input) {
      success
      errors
      property {
        id
        thumbnail
        thumbnail_signed_id
        name
        country
        market
        owner
        project_name
        loan
        deal_name
        property_type
        description
        year_built
        manager
        longitude
        latitude
        stage
        tag_names
        permissions
        crop_data
        total_attachments_byte_size
      }
    }
  }
`

export const GET_PROPERTY_GQL = gql`
  query ($id: ID!) {
    property(id: $id) {
      id
      thumbnail
      thumbnail_original_url
      thumbnail_signed_id
      crop_data
      name
      country
      market
      owner
      project_name
      loan
      deal_name
      property_type
      description
      year_built
      manager
      longitude
      latitude
      stage
      tag_names
      permissions
      total_attachments_byte_size
    }
    propertyOptions {
      countries
      tags
      propertyTypes
      stages
    }
  }
`

export const GET_PROPERTY_OPTIONS_GQL = gql`
  query {
    propertyOptions {
      countries
      tags
      propertyTypes
      stages
    }
  }
`;

export const REMOVE_PROPERTY_GQL = gql`
  mutation($input: RemovePropertyInput!) {
    removeProperty(input: $input) {
      success
      errors
    }
  }
`;
