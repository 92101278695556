import React, { Component } from "react"
import SignUpBox from "components/SignUpBox"
import userAuth from "utils/userAuth"
import queryString from "query-string"

class SignUpPage extends Component {
  componentWillMount() {
    userAuth.clearAuth()
  }

  render() {
    const { code } = this.props.match.params || {}
    const query = queryString.parse(this.props.location.search)
    const {plan_id, step, invitation_code} = query || {}

    return (
      <div className="SignUpPage">
        <SignUpBox code={code} plan_id={plan_id} step={step} invitation_code={invitation_code} />
      </div>
    )
  }
}

export default SignUpPage
